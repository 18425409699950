import { UserFormState } from '@shared/redux/state/userForm';
import { emptyCountry } from '@shared/util/phone';

export const initUserFormState: UserFormState = {
  firstName: '',
  lastName: '',
  roles: [],
  email: '',
  phone: {
    number: '',
    countryCode: emptyCountry.iso2code,
  },
  contactInformation: '',
  password: '',
  twoFactorEnabled: true,
  /**
   * Used to restore twoFactorEnabled on form reset action.
   */
  initialTwoFactorEnabled: true,
  isSubmitting: false,
  defaultCountryCode: emptyCountry.iso2code,
};
