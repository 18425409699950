import { call, put } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';

import * as TrustedBrowserActionTypes from '@shared/redux/actions/trustedBrowser-actions';
import { ActionWithPayload } from '@shared/redux/actions/typings';
import { TrustedBrowsersApi } from '@shared/api/auth-api';
import { handleSagaError } from '@shared/util/handleSagaError';

export function* fetchClearAllBrowsers(action: ActionWithPayload<any>) {
  try {
    yield call(TrustedBrowsersApi.clearBrowsers);
    yield put({
      type: TrustedBrowserActionTypes.CLEAR_USERS_BROWSERS_SUCCESS,
      successNotification: { resourceKey: 'Notification_UsersBrowsers_Cleaned' },
    });
  } catch (e) {
    yield handleSagaError({
      type: TrustedBrowserActionTypes.CLEAR_USERS_BROWSERS_FAILURE,
      error: e,
    });
  }
}

export function* fetchAllTrustedBrowsers() {
  try {
    const response: AxiosResponse = yield call(TrustedBrowsersApi.getTrustedBrowsers);
    const browsers = response.data;
    yield put({
      type: TrustedBrowserActionTypes.FETCH_TRUSTED_BROWSERS_SUCCESS,
      payload: browsers,
    });
  } catch (e) {
    yield handleSagaError({
      type: TrustedBrowserActionTypes.FETCH_TRUSTED_BROWSERS_FAILED,
      error: e,
    });
  }
}
