export enum PatientCategories {
  /**
   * Patient is not associated with any categories.
   * For example, if a patient is not under therapy anymore,
   * but you don´t want/are not allowed to delete him.
   */
  Unspecified = 0,
  /**
   * Tele-monitored patients with Sleep Disordered Breathing (SDB).
   */
  SleepTelemonitoring = 1,
  /**
   * Category for patients, whose data were uploaded via SD-Card upload service
   */
  OfflineDataUpload = 2,
  /**
   * Tele-monitored patients using Noninvasive Ventilation (VentilationTelemonitoring).
   */
  VentilationTelemonitoring = 4,
}
