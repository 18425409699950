import { put } from 'redux-saga/effects';

import * as LocalisationAction from '@shared/redux/actions/localisation-actions';
import { reportError } from '@shared/util/sentry';
import { getStoredOrDefaultCulture } from '@shared/util/user';
import { changeLanguage } from '@shared/util/localisation-adapter';
import { getCultures } from '@shared/util/cultures';

export function* fetchLocalisation(action: LocalisationAction.ISetLocalisationAction) {
  try {
    const storedOrDefaultCulture = getStoredOrDefaultCulture();

    let newCulture: string;
    let newDayJSLocale: string;
    let newCultureUserDefined: boolean;

    const supportedCultures = getCultures();

    if (
      (action.isCultureUserDefined || !storedOrDefaultCulture.isCultureUserDefined) &&
      supportedCultures.some((x) => x.languageCulture === action.culture)
    ) {
      // this is called in two cases: the user has selected the culture from dropdown or
      // the action contains company culture and the user did not select manually a language before.
      // Language exists in list of supported languages.
      newCulture = action.culture;
      newDayJSLocale = action.dayJSLocale;
      newCultureUserDefined = action.isCultureUserDefined;
    } else {
      // this is called if the action contains company culture but the user has selected a language before
      // (it is stored in localStore)
      // getStoredOrDefaultCulture always return supported language.
      newCulture = storedOrDefaultCulture.culture;
      newDayJSLocale = storedOrDefaultCulture.dayJSLocale;
      newCultureUserDefined = storedOrDefaultCulture.isCultureUserDefined;
    }

    changeLanguage(newCulture, newDayJSLocale, newCultureUserDefined);
    yield put(LocalisationAction.localizationLanguageChanged(newCulture));
  } catch (e) {
    console.error(e);
    reportError(e);
    yield put(LocalisationAction.receivedFailure());
  }
}
