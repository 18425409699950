import { match } from 'ts-pattern';

import {
  DeviceAssignmentFilters,
  PatientCategoryFilterKey,
  PatientNoteFilters,
  SharingStatusFilters,
  TeleSettingsFilters,
} from '@shared/constants/filters';
import { PatientFilterCategory } from '@shared/constants/PatientFilterCategory';
import { FiltersState } from '@shared/redux/state/patients';

export const setDefaultValueForFiltersByKey = (
  filterKey: keyof FiltersState,
  filtersState: FiltersState,
): FiltersState =>
  match(filterKey)
    .with('deviceAssignmentFilter', () => ({
      ...filtersState,
      deviceAssignmentFilter: DeviceAssignmentFilters.Empty,
    }))
    .with('patientNoteFilter', () => ({
      ...filtersState,
      patientNoteFilter: PatientNoteFilters.Empty,
    }))
    .with('sharedByMeToTenant', () => ({
      ...filtersState,
      sharedByMeToTenant: '',
    }))
    .with('sharedToMeFromTenant', () => ({
      ...filtersState,
      sharedToMeFromTenant: '',
    }))
    .with('sharingStatusFilter', () => ({
      ...filtersState,
      sharingStatusFilter: SharingStatusFilters.Empty,
    }))
    .with('teleSettingsFilter', () => ({
      ...filtersState,
      teleSettingsFilter: TeleSettingsFilters.Empty,
    }))
    .with('usersInCharge', () => ({
      ...filtersState,
      usersInCharge: [],
    }))
    .with('medicalConditionId', () => ({
      ...filtersState,
      medicalConditionId: null,
    }))
    .with(PatientCategoryFilterKey, () => ({
      ...filtersState,
      patientCategory: PatientFilterCategory.All,
    }))
    .with('prismaAppUsageFilter', () => ({
      ...filtersState,
      prismaAppUsageFilter: 'Empty' as const,
    }))
    .with('questionnaireType', () => ({
      ...filtersState,
      questionnaireType: 'All' as const,
    }))
    .with('questionnaireStatus', () => ({
      ...filtersState,
      questionnaireStatus: 'All' as const,
    }))
    .exhaustive();
