import * as url from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';

import { Configuration } from '@shared/api/base-api/configuration';
import { RequestArgs, postProcessAxiosError } from '@shared/api/base-api';
import { PATH_TO_AUTH_API } from '@shared/constants';

/**
 * TrustedBrowsersApi - axios parameter creator
 * @export
 */
export const TrustedBrowsersApiAxiosParamCreator = (configuration?: Configuration) => {
  return {
    /**
     *
     * @summary Clears the trusted browsers for the current user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clearBrowsers(options: any = {}): RequestArgs {
      const localVarPath = '/TrustedBrowsers';
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = {
        method: 'DELETE',
        ...baseOptions,
        ...options,
      };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };

      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete (localVarUrlObj as any).search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Gets the trusted browsers for the current user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTrustedBrowsers(options: any = {}): RequestArgs {
      const localVarPath = '/TrustedBrowsers';
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete (localVarUrlObj as any).search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * TrustedBrowsersApi - functional programming interface
 * @export
 */
export const TrustedBrowsersApiFp = (configuration?: Configuration) => {
  return {
    /**
     *
     * @summary Clears the trusted browsers for the current user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    clearBrowsers(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
      const localVarAxiosArgs = TrustedBrowsersApiAxiosParamCreator(configuration).clearBrowsers(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = PATH_TO_AUTH_API) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs).catch(postProcessAxiosError);
      };
    },
    /**
     *
     * @summary Gets the trusted browsers for the current user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTrustedBrowsers(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
      const localVarAxiosArgs = TrustedBrowsersApiAxiosParamCreator(configuration).getTrustedBrowsers(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = PATH_TO_AUTH_API) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs).catch(postProcessAxiosError);
      };
    },
  };
};

/**
 * TrustedBrowsersApi - factory interface
 * @export
 */
export const TrustedBrowsersApiFactory = (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) => {
  return {
    /**
     * Clears the trusted browsers for the current user.
     * @param {*} [options] override http request option.
     */
    clearBrowsers(options?: any) {
      return TrustedBrowsersApiFp(configuration).clearBrowsers(options)(axios, basePath);
    },

    /**
     * Gets the trusted browsers for the current user.
     * @param {*} [options] override http request option.
     * @returns {*} a list of trusted browsers.
     */
    getTrustedBrowsers(options?: any) {
      return TrustedBrowsersApiFp(configuration).getTrustedBrowsers(options)(axios, basePath);
    },
  };
};
