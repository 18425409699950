import { SagaIterator } from '@redux-saga/types';
import { SubmissionError } from 'redux-form';
import { call, put, select } from 'redux-saga/effects';

import { fetchAllTrustedBrowsers } from './trustedBrowser-saga';
import { PicApi, UsersApi } from '@shared/api/core-api';
import { ICreateUserDto, IUpdateExternalUserDto, IUpdateUserDto, IUserDto } from '@shared/api/core-api/users-api';
import { toApiSortDirection } from '@shared/api/core-types';
import { USERS_NUMBER_ON_PAGE } from '@shared/constants';
import { HttpStatusCodes } from '@shared/constants/httpStatusCodes';
import * as ReduxForms from '@shared/constants/reduxFormNames';
import * as AuthActionTypes from '@shared/redux/actions/auth-actions';
import {
  EditUserPayload,
  FetchUsersPagePayload,
  RequestByUserIdPayload,
  UserCreatePicPayload,
  UserDeleteConfirmPayload,
  UserDeletePicPayload,
} from '@shared/redux/actions/payloadTypes/userActionsPayloads';
import { REDUX_FORM_RESET, ReduxFormResetAction } from '@shared/redux/actions/redux-form-actions';
import { ActionWithPayload } from '@shared/redux/actions/typings';
import * as UserActionTypes from '@shared/redux/actions/users-actions';
import { accountDetailsRequestSuccess } from '@shared/redux/actions/users-actions';
import { AccountState } from '@shared/redux/state/account';
import { RootState } from '@shared/redux/state/root';
import {
  PicContent,
  UserInChargeEntity,
  UsersFilterState,
  UsersPageDto,
  UsersSortState,
} from '@shared/redux/state/users';
import { fileDownload } from '@shared/util/Downloader';
import { handleReactFormSagaError, handleSagaError } from '@shared/util/handleSagaError';
import { getFullPhoneNumberByIso2CountryCode } from '@shared/util/phone';
import { getPicFileName } from '@shared/util/picFileNameGetter';
import { handleErrorResponse, handlePhoneNumberServerError } from '@shared/util/server-error-response-helper';
import { combineUserRoles, reduceUserRoles } from '@shared/util/users-helper';

export function* fetchPageOfUsers(action: ActionWithPayload<FetchUsersPagePayload>) {
  try {
    const { page } = action.payload;
    const { searchQuery }: UsersFilterState = yield select((state: RootState) => state.users.filter);
    const { field, direction }: UsersSortState = yield select((state: RootState) => state.users.sort);

    const usersPage: UsersPageDto = yield call(
      UsersApi.getPaged,
      searchQuery,
      toApiSortDirection(direction),
      field,
      page,
      USERS_NUMBER_ON_PAGE,
    );

    yield put({
      type: UserActionTypes.FETCH_PAGE_OF_USERS_SUCCESS,
      payload: usersPage,
    });
  } catch (e) {
    yield handleSagaError({
      type: UserActionTypes.FETCH_PAGE_OF_USERS_FAILURE,
      error: e,
    });
  }
}

export function* fetchAllUsersInCharge() {
  try {
    const users: UserInChargeEntity[] = yield call(UsersApi.getUsersInCharge);

    yield put({
      type: UserActionTypes.FETCH_ALL_USER_IN_CHARGE_SUCCESS,
      payload: users,
    });
  } catch (e) {
    yield handleSagaError({
      type: UserActionTypes.FETCH_ALL_USER_IN_CHARGE_FAILURE,
      error: e,
    });
  }
}

export function* fetchCreatePic(action: ActionWithPayload<RequestByUserIdPayload>) {
  try {
    const { userId } = action.payload;
    const pic = (yield call(PicApi.createPickForUser, userId)) as string;

    const createPicAction: ActionWithPayload<UserCreatePicPayload> = {
      type: UserActionTypes.USER_CREATE_PIC_SUCCESS,
      payload: {
        userId,
        pic,
      },
    };
    yield put(createPicAction);
  } catch (e) {
    yield handleSagaError({
      type: UserActionTypes.USER_CREATE_PIC_FAILURE,
      error: e,
    });
  }
}

export function* fetchAccountInfo(): Generator {
  try {
    const accountDetails = (yield call(UsersApi.getMyself)) as IUserDto;

    yield put<UserActionTypes.AccountDetailsRequestSuccessAction>(accountDetailsRequestSuccess(accountDetails));
  } catch (e) {
    yield handleSagaError(UserActionTypes.accountDetailsRequestFailed(e));
  }
}

export function* fetchPic(action: ActionWithPayload<any>) {
  try {
    const { userId } = action.payload;
    const pic: string | null = yield call(PicApi.getPic, userId);

    if (pic) {
      yield put({
        type: UserActionTypes.USER_PIC_REQUEST_SUCCESS,
        payload: {
          userId,
          picExist: true,
          pic,
        },
      });
    } else {
      yield put({
        type: UserActionTypes.USER_PIC_REQUEST_SUCCESS,
        payload: {
          userId,
          picExist: false,
        },
      });
    }
  } catch (e) {
    yield handleSagaError({
      type: UserActionTypes.USER_PIC_REQUEST_FAILURE,
      error: e,
    });
  }
}

// is not called for first organisation admin
export function* fetchCreateUser(action: ReturnType<typeof UserActionTypes.createUser['request']>): SagaIterator {
  try {
    yield put({
      type: UserActionTypes.LOCK_USER_FORM,
    });

    const {
      firstName,
      lastName,
      roles,
      email,
      phone,
      contactInformation,
      userTag,
      twoFactorEnabled,
      isPrismaSmartEditAllowed,
      isPrismaLineSleepEditAllowed,
      isPrismaLineVentiEditAllowed,
      isPrismaVentEditAllowed,
      isTelemonitoringSwitchingAllowed,
    } = action.payload;

    const fullPhoneNumber = phone ? getFullPhoneNumberByIso2CountryCode(phone.number, phone.countryCode) : null;
    const userRoles = combineUserRoles(
      roles,
      isPrismaLineSleepEditAllowed,
      isPrismaLineVentiEditAllowed,
      isPrismaSmartEditAllowed,
      isPrismaVentEditAllowed,
      isTelemonitoringSwitchingAllowed,
    );

    // all values are validated at this point
    const newUser: ICreateUserDto = {
      firstName: firstName!,
      lastName: lastName!,
      email: email!,
      contactInformation: contactInformation!,
      userTag: userTag!,
      twoFactorEnabled,
      userRoles: reduceUserRoles(userRoles),
      phone: fullPhoneNumber,
    };

    const createdUser: IUserDto = yield call(UsersApi.createUser, newUser);
    const successAction = UserActionTypes.createUser.success(createdUser);

    yield put(successAction);
    yield put({
      type: UserActionTypes.CREATE_USER_MODAL_CLOSE,
    });

    const resetFormAction: ReduxFormResetAction = {
      type: REDUX_FORM_RESET,
      meta: { form: ReduxForms.USER_FORM },
    };
    yield put(resetFormAction);
  } catch (e) {
    const formError = new SubmissionError(handleErrorResponse(e));
    handlePhoneNumberServerError('phone', formError);
    const failedAction = UserActionTypes.createUser.failure(formError);
    yield handleReactFormSagaError({
      error: e,
      formError,
      failedAction,
    });
  } finally {
    yield put({
      type: UserActionTypes.UNLOCK_USER_FORM,
    });
  }
}

export function* fetchUserById(action: UserActionTypes.FetchUserByIdRequest): Generator {
  try {
    const id = action.payload;
    const user = (yield call(UsersApi.getUserById, id)) as IUserDto;

    yield put(UserActionTypes.fetchUserByIdSuccess(user));
  } catch (e: any) {
    // For user point of view if we get not found and bad request is the same - wrong url entered.
    if (e?.response?.status === HttpStatusCodes.NotFound || e?.response?.status === HttpStatusCodes.BadRequest) {
      yield put(UserActionTypes.fetchUserByIdNotFound(e));
    } else {
      yield handleSagaError(UserActionTypes.fetchUserByIdFailure(e));
    }
  }
}

export function* fetchEditMyAccount(action: ActionWithPayload<EditUserPayload>): Generator {
  try {
    yield put({
      type: UserActionTypes.LOCK_USER_ACCOUNT_FORM,
    });

    const {
      id,
      firstName,
      lastName,
      phone,
      email,
      roles,
      contactInformation,
      userTag,
      twoFactorEnabled,
      isPrismaSmartEditAllowed,
      isPrismaLineSleepEditAllowed,
      isPrismaLineVentiEditAllowed,
      isPrismaVentEditAllowed,
      isTelemonitoringSwitchingAllowed,
    } = action.payload;

    const userRoles = combineUserRoles(
      roles,
      isPrismaLineSleepEditAllowed,
      isPrismaLineVentiEditAllowed,
      isPrismaSmartEditAllowed,
      isPrismaVentEditAllowed,
      isTelemonitoringSwitchingAllowed,
    );

    const myAccount = (yield select((state: RootState) => state.account.details)) as AccountState['details'];

    const reducedUserRoles = reduceUserRoles(userRoles);

    const organisationUsesExternalLogin = yield select((state: RootState) => state.tenant.isExternalLoginUsed);
    if (organisationUsesExternalLogin) {
      const updateUserDto: IUpdateExternalUserDto = {
        userRoles: reducedUserRoles,
        userTag,
      };
      yield call(UsersApi.updateExternalUserDetails, id, updateUserDto);
    } else {
      const fullPhoneNumber = getFullPhoneNumberByIso2CountryCode(phone.number, phone.countryCode);

      const updateUserDto: IUpdateUserDto = {
        firstName,
        lastName,
        phone: fullPhoneNumber,
        email,
        userRoles: reducedUserRoles,
        contactInformation,
        userTag,
        twoFactorEnabled,
      };
      yield call(UsersApi.updateUserDetails, id, updateUserDto);
    }

    if (myAccount && myAccount.userRoles !== reducedUserRoles) {
      yield put(AuthActionTypes.logOutByUsersRequest());
    }

    const successAction = UserActionTypes.editMyAccount.success();
    yield put({
      type: UserActionTypes.DISABLE_USER_ACCOUNT_FORM_EDIT_BUTTONS,
    });
    yield put({
      type: UserActionTypes.ACCOUNT_DETAILS_REQUEST,
    });
    yield call(fetchAllTrustedBrowsers);
    yield put(successAction);
  } catch (e) {
    const formError = new SubmissionError(handleErrorResponse(e));
    handlePhoneNumberServerError('phone', formError);
    const failedAction = UserActionTypes.editMyAccount.failure(formError);
    yield handleReactFormSagaError({
      error: e,
      formError,
      failedAction,
    });

    yield put({
      type: UserActionTypes.UNLOCK_USER_ACCOUNT_FORM,
    });
  }
}

export function* fetchEditUser(action: ActionWithPayload<EditUserPayload>): Generator {
  try {
    yield put({
      type: UserActionTypes.LOCK_USER_ACCOUNT_FORM,
    });

    const {
      id,
      firstName,
      lastName,
      phone,
      roles,
      contactInformation,
      userTag,
      email,
      twoFactorEnabled,
      isPrismaSmartEditAllowed,
      isPrismaLineSleepEditAllowed,
      isPrismaLineVentiEditAllowed,
      isPrismaVentEditAllowed,
      isTelemonitoringSwitchingAllowed,
    } = action.payload;

    const userRoles = combineUserRoles(
      roles,
      isPrismaLineSleepEditAllowed,
      isPrismaLineVentiEditAllowed,
      isPrismaSmartEditAllowed,
      isPrismaVentEditAllowed,
      isTelemonitoringSwitchingAllowed,
    );

    const reducedUserRoles = reduceUserRoles(userRoles);

    const organisationUsesExternalLogin = yield select((state: RootState) => state.tenant.isExternalLoginUsed);
    if (organisationUsesExternalLogin) {
      const payload: IUpdateExternalUserDto = {
        userRoles: reducedUserRoles,
        userTag,
      };
      yield call(UsersApi.updateExternalUserDetails, id, payload);
    } else {
      const fullPhoneNumber = getFullPhoneNumberByIso2CountryCode(phone.number, phone.countryCode);

      const payload: IUpdateUserDto = {
        firstName,
        lastName,
        phone: fullPhoneNumber,
        userRoles: reducedUserRoles,
        contactInformation,
        userTag,
        email,
        twoFactorEnabled,
      };
      yield call(UsersApi.updateUserDetails, id, payload);
    }

    const successAction = UserActionTypes.editUser.success();
    yield put({
      type: UserActionTypes.FETCH_USER_BY_ID_REQUEST,
      payload: id,
    });
    yield put({
      type: UserActionTypes.DISABLE_USER_ACCOUNT_FORM_EDIT_BUTTONS,
    });
    yield put(successAction);
  } catch (e) {
    const formError = new SubmissionError(handleErrorResponse(e));
    handlePhoneNumberServerError('phone', formError);
    const failedAction = UserActionTypes.editUser.failure(formError);
    yield handleReactFormSagaError({
      error: e,
      formError,
      failedAction,
    });

    yield put({
      type: UserActionTypes.UNLOCK_USER_ACCOUNT_FORM,
    });
  }
}

export function* fetchDeletePic(action: ActionWithPayload<RequestByUserIdPayload>) {
  try {
    const { userId } = action.payload;
    yield call(PicApi.delete, userId);

    const deletePicAction: ActionWithPayload<UserDeletePicPayload> = {
      type: UserActionTypes.USER_DELETE_PIC_SUCCESS,
      payload: { userId },
    };
    yield put(deletePicAction);
  } catch (e) {
    yield handleSagaError({
      type: UserActionTypes.USER_DELETE_PIC_FAILURE,
      error: e,
    });
  }
}

export function* downloadPic(action: ActionWithPayload<PicContent>) {
  try {
    const { pic, firstName, lastName, email } = action.payload;

    const fileName = getPicFileName(firstName, lastName, email);

    yield call(fileDownload, pic, fileName, 'application/octet-stream', true);
    yield put({
      type: UserActionTypes.USER_DOWNLOAD_PIC_SUCCESS,
    });
  } catch (e) {
    yield handleSagaError({
      type: UserActionTypes.USER_DOWNLOAD_PIC_FAILED,
      error: e,
    });
  }
}

export function* fetchDeleteUser(action: ActionWithPayload<UserDeleteConfirmPayload>) {
  try {
    const page: number = yield select((state: RootState) => state.users.pagination.page);
    const { userId } = action.payload;
    yield call(UsersApi.delete, userId);
    yield* fetchPageOfUsers({
      type: UserActionTypes.FETCH_PAGE_OF_USERS_REQUEST,
      payload: { page },
    });
    yield put({
      type: UserActionTypes.USER_DELETE_SUCCESS,
      payload: { userId },
    });
  } catch (e) {
    yield handleSagaError({
      type: UserActionTypes.USER_DELETE_FAILED,
      error: e,
    });
  }
}
