import { EditableSettingsDto } from '@shared/api/core-api/settings-api';

export const initSettings: EditableSettingsDto = {
  isTwoFactorAuthenticationEnabled: false,
  isDeviceSettingsConfirmationRequiresPassword: false,
  isPicGenerationForbidden: false,
  reportBestComplianceEnabled: false,
  bestComplianceUsageThreshold_Hours: 0,
  numberOfBestComplianceDays: 0,
  isTelemonitoringEnabled: true,
  logoExists: false,
};
