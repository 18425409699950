export const CREATE_PATIENT_FORM = 'createPatientForm';
export const EDIT_PATIENT_FORM = 'editPatientForm';
export const USER_FORM = 'userForm';
export const ORG_MANAGER_FORM = 'orgManagerForm';
export const DEVICE_FILTERS_FORM = 'deviceFiltersForm';

export const USER_ACCOUNT_FORM = 'userAccountForm';
export const VERIFY_CODE_FORM = 'phoneVerificationForm';
export const SEND_VERIFICATION_CODE_FORM = 'sendVerificationCodeForm';

export const CREATE_ORGANISATION_FORM = 'createOrganisationForm';
export const EDIT_ORGANISATION_FORM = 'editOrganisationForm';

export const SET_PASSWORD_FORM = 'SetPasswordForm';
export const PASSWORD_FORGOTTEN_FORM = 'passwordForgottenForm';

export const LOGIN_FORM = 'loginForm';
export const SECOND_FACTOR_LOGIN_FORM = 'secondFactorLoginForm';

export type userFormType = typeof USER_FORM | typeof ORG_MANAGER_FORM;