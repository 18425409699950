import { getInitRootState } from './initState/rootState';
import * as PreregisteredDeviceUploadActionTypes from '@shared/redux/actions/preregisteredDevicesUpload-actions';
import { AllActions } from '@shared/redux/actions';
import { RequestState } from '@shared/constants/request';
import { PreregisteredDevicesUploadData } from '@shared/types/devicePreRegistrationTypes';

export interface PreregisteredDeviceUploadState {
  devicesToUpload: PreregisteredDevicesUploadData[] | null;
  requestState: RequestState;
}

export function preregisteredDevicesUpload(
  state: PreregisteredDeviceUploadState = getInitRootState().preregisteredDevicesUpload,
  action: AllActions,
): PreregisteredDeviceUploadState {
  switch (action.type) {
    case PreregisteredDeviceUploadActionTypes.UPLOAD_DEVICES_FILE_REQUEST: {
      return {
        ...state,
        requestState: RequestState.PENDING,
      };
    }
    case PreregisteredDeviceUploadActionTypes.UPLOAD_DEVICES_FILE_SUCCESS: {
      const { uploadInfo } = action.payload;
      return {
        ...state,
        devicesToUpload: uploadInfo,
        requestState: RequestState.SUCCESS,
      };
    }
    case PreregisteredDeviceUploadActionTypes.UPLOAD_DEVICES_FILE_FAILURE: {
      return {
        ...state,
        requestState: RequestState.FAILED,
      };
    }
  }
  return state;
}

export default preregisteredDevicesUpload;
