import { takeLatest, all } from 'redux-saga/effects';

import * as PreregisteredDevicesUploadActionTypes from '@shared/redux/actions/preregisteredDevicesUpload-actions';
import * as PreregisteredDevicesUpload from '@shared/redux/sagas/preregisteredDevicesUpload-saga';

function* watchSendFile() {
  yield takeLatest(
    PreregisteredDevicesUploadActionTypes.UPLOAD_DEVICES_FILE_REQUEST,
    PreregisteredDevicesUpload.uploadDevices,
  );
}

export default function* watchPreregisteredDevicesUploadActions() {
  yield all([watchSendFile()]);
}
