import { takeLatest, all } from 'redux-saga/effects';

import * as SettingActionTypes from '@shared/redux/actions/settings-actions';
import * as SftpActionTypes from '@shared/redux/actions/sftp-actions';
import * as SettingsSaga from '@shared/redux/sagas/setting-saga';

function* watchDaysLeftRequest() {
  yield takeLatest(SettingActionTypes.TOKEN_DAYS_LEFT_REQUEST, SettingsSaga.fetchTokenExpirationState);
}

function* watchSettings() {
  yield takeLatest(SettingActionTypes.SETTINGS_REQUEST, SettingsSaga.fetchSettings);
}

function* watchTokenRequest() {
  yield takeLatest(SettingActionTypes.CREATE_TOKEN_REQUEST, SettingsSaga.fetchCreateToken);
}

function* watchRevokeTokenRequest() {
  yield takeLatest(SettingActionTypes.REVOKE_TOKEN_REQUEST, SettingsSaga.fetchRevokeToken);
}

function* watchsftpPasswordExpirationStateRequest() {
  yield takeLatest(SftpActionTypes.SFTP_PASS_DAYS_LEFT_REQUEST, SettingsSaga.fetchSftpPasswordExpirationState);
}

function* watchDeactivateSftpRequest() {
  yield takeLatest(SftpActionTypes.SFTP_DEACTIVATION_REQUEST, SettingsSaga.fetchDeactivateSftp);
}

function* watchSFtpPasswordRequest() {
  yield takeLatest(SftpActionTypes.SFTP_GENERATION_PASSWORD_REQUEST, SettingsSaga.fetchGenerateSftpPassword);
}

function* watchSftpConnectionSettings() {
  yield takeLatest(SftpActionTypes.SFTP_CONNECTION_SETTINGS_REQUEST, SettingsSaga.fetchSftpConnectionSettings);
}

function* watchActivateTwoFactorAuthentication() {
  yield takeLatest(
    SettingActionTypes.TWO_FACTOR_AUTHENTICATION_ACTIVATE,
    SettingsSaga.fetchActivateTwoFactorAuthentication,
  );
}

function* watchDeactivateTwoFactorAuthentication() {
  yield takeLatest(
    SettingActionTypes.TWO_FACTOR_AUTHENTICATION_DEACTIVATE,
    SettingsSaga.fetchDeactivateTwoFactorAuthentication,
  );
}

function* watchActivateConfirmSettingsRequired() {
  yield takeLatest(
    SettingActionTypes.ACTIVATE_CONFIRM_SETTINGS_REQUIRED,
    SettingsSaga.fetchActivateConfirmSettingsRequired,
  );
}

function* watchDeactivateConfirmSettingsRequired() {
  yield takeLatest(
    SettingActionTypes.DEACTIVATE_CONFIRM_SETTINGS_REQUIRED,
    SettingsSaga.fetchDeactivateConfirmSettingsRequired,
  );
}

export default function* watchSettingActions() {
  yield all([
    watchDaysLeftRequest(),
    watchSettings(),
    watchTokenRequest(),
    watchRevokeTokenRequest(),
    watchsftpPasswordExpirationStateRequest(),
    watchDeactivateSftpRequest(),
    watchSFtpPasswordRequest(),
    watchSftpConnectionSettings(),
    watchActivateTwoFactorAuthentication(),
    watchDeactivateTwoFactorAuthentication(),
    watchActivateConfirmSettingsRequired(),
    watchDeactivateConfirmSettingsRequired(),
  ]);
}
