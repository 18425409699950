const hasFlag = (generalEnum: any, specificEnum: any): boolean => {
  return (generalEnum & specificEnum) === specificEnum;
};

const hasOneOfFlags = (value: any, flags: any): boolean => {
  for (const flag of flags) {
    if (hasFlag(value, flag)) {
      return true;
    }
  }
  return false;
};

const getAllPresentedFlags = (generalEnum: any) => {
  const flags = [];
  for (let i = 1; i <= generalEnum; i = i * 2) {
    if (hasFlag(generalEnum, i)) {
      flags.push(i);
    }
  }
  return flags;
};

export default {
  hasFlag,
  hasOneOfFlags,
  getAllPresentedFlags,
};
