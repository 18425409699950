import React from 'react';
import { useLocation } from 'react-router-dom';

export const getBaseUrl = () => {
  const { host } = window.location;
  const baseUrl = host.slice(host.indexOf('.') + 1);

  return baseUrl;
};

export const useUrlSearchQuery = () => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
};

export const queryBuilder = () => {
  const searchParams = new URLSearchParams();
  const result = {
    append: <T>(name: string, value: T) => {
      if (value != null) {
        if (Array.isArray(value)) {
          value.forEach((x) => searchParams.append(name, x));
        } else {
          searchParams.append(name, String(value));
        }
      }
      return result;
    },
    build: () => {
      return `?${searchParams.toString()}`;
    },
  };

  return result;
};
