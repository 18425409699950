import axios from 'axios';

import { BaseAPI } from '@shared/api/base-api';
import { PATH_TO_AUTH_API } from '@shared/constants';

export type TokenLifeTime = 0 | 90 | 180 | 365 | 1095;

/**
 * Request to generate a new api token.
 * @export
 * @interface TokenGenerationRequest
 */
export interface TokenGenerationRequest {
  /**
   * Lifetime in days for the new token. 0 means infinite.
   * @type {number}
   * @memberof TokenGenerationRequest
   */
  lifetime: TokenLifeTime;
}

export interface TokenLifetimeDto {
  /**
   * Tenant for which the token is generated.
   */
  tenant: string;
  /**
   * Amount of days after which token would be invalid.
   * `null` in case if the token is expired or if no token was created for the tenant.
   */
  expiredInDays: number | null;

  /**
   * A flag indicating that the token does not expire.
   * If `true`, the `expiredInDays` property should not be analyzed.
   */
  isUnlimited: boolean;
}

export const ExternalApiTokenApi = (baseApi: BaseAPI) => {
  return {
    /**
     * Returns the information of the current token of the current tenant.
     * @returns {TokenLifetimeDto} information about life time of the token.
     */
    async getTokenInfo() {
      const { data } = await axios.get<TokenLifetimeDto>(`${PATH_TO_AUTH_API}/externalApiToken`);
      return data;
    },

    /**
     * Acquires a new token.
     * @param {TokenLifeTime} lifetime the lifetime of the new token.
     * @returns {string} the new token.
     */
    async createToken(lifetime: TokenLifeTime) {
      const form = new FormData();
      form.set('lifetime', lifetime.toString());
      const { data } = await axios.post<string>(`${PATH_TO_AUTH_API}/externalApiToken`, form, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      return data;
    },

    /**
     * Deletes the external api token for the current organisation.
     */
    async revokeToken() {
      const { data } = await axios.delete(`${PATH_TO_AUTH_API}/externalApiToken`);
      return data;
    },
  };
};

export const ReferenceTokenApiFactory = (baseApi: BaseAPI) => ExternalApiTokenApi(baseApi);
