import { OrganisationApiFactory } from './organisation-api';
import { OrganisationLinkApiFactory } from './organisation-link-api';
import { PicApiFactory } from './pic-api';
import { SettingsApiFactory } from './settings-api';
import { OrganisationManagerApiFactory, UsersApiFactory } from './users-api';
import { BaseAPI } from '@shared/api/base-api';
import { PATH_TO_CORE_API } from '@shared/constants';

export * from './patients-api';
export * from './devices-api';
export * from './device-bindings-api';

const api = new BaseAPI(PATH_TO_CORE_API);

export const OrganisationLinkApi = OrganisationLinkApiFactory(api);
export const UsersApi = UsersApiFactory(api);
export const OrganisationManagerApi = OrganisationManagerApiFactory(api);
export const PicApi = PicApiFactory(api);
export const OrganisationApi = OrganisationApiFactory(api);
export const SettingsApi = SettingsApiFactory(api);
