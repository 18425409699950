import * as Actions from '@shared/redux/actions/notification-actions';
import { NotificationRootState } from '@shared/redux/state/notification';

export function notification(
  state: NotificationRootState = {},
  action: Actions.NotificationActions,
): NotificationRootState {
  switch (action.type) {
    case Actions.SHOW_REVOKE_NOTIFICATION: {
      const { id, resourceKey, kind, onRevokeButtonClick } = action.payload;
      return {
        ...state,
        [id]: { resourceKey, kind, onRevokeButtonClick },
      };
    }
    case Actions.SHOW_NOTIFICATION: {
      const { id, resourceKey, kind, localizationParams } = action.payload;
      return {
        ...state,
        [id]: { resourceKey, kind, localizationParams },
      };
    }
    case Actions.HIDE_NOTIFICATION: {
      const newState = { ...state };
      const { id } = action.payload;

      if (newState[id] !== undefined) {
        delete newState[id];
      }

      return newState;
    }
  }
  return state;
}
