import { AxiosRequestConfig } from 'axios';
import url from 'url';

import { BaseAPI, prepareRequestAndFillFormData } from '@shared/api/base-api';

/**
 * Dto describing all available settings for an organisation
 * @export
 * @interface Settings
 */
export interface EditableSettingsDto {
  /**
   *
   * @type {boolean}
   * @memberof Settings
   */
  isTwoFactorAuthenticationEnabled: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Settings
   */
  isDeviceSettingsConfirmationRequiresPassword: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Settings
   */
  isPicGenerationForbidden: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Settings
   */
  reportBestComplianceEnabled: boolean;
  /**
   *
   * @type {number}
   * @memberof Settings
   */
  numberOfBestComplianceDays: number;
  /**
   *
   * @type {number}
   * @memberof Settings
   */
  bestComplianceUsageThreshold_Hours: number;
  /**
   *
   * @type {boolean}
   * @memberof Settings
   */
  isTelemonitoringEnabled: boolean;

  logoExists: boolean;
}

export const SettingsApi = (baseApi: BaseAPI) => {
  return {
    /**
     * Returns settings which can be edited via WebApp.
     * @returns DTO with settings.
     */
    async getSettings(): Promise<EditableSettingsDto> {
      const { data } = await baseApi.get<EditableSettingsDto>('/api/organisations/settings');
      return data;
    },

    /**
     * Enables the requirement to confirm device configuration changes.
     */
    async activateConfirmSettingsRequired() {
      await baseApi.post('/api/organisations/settings/configurationConfirmationRequirement');
    },

    /**
     * Disables the requirement to confirm device configuration changes.
     */
    async deactivateConfirmSettingsRequired() {
      await baseApi.delete('/api/organisations/settings/configurationConfirmationRequirement');
    },

    /**
     * Enables two factor authentication.
     */
    async activateTwoFactorAuthentication() {
      await baseApi.post('/api/organisations/settings/2fa/activation');
    },

    /**
     * Disables two factor authentication
     */
    async deactivateTwoFactorAuthentication() {
      await baseApi.delete('/api/organisations/settings/2fa/activation');
    },

    /**
     * Enables best compliance report.
     */
    async allowReportBestCompliance() {
      await baseApi.post('/api/organisations/settings/bestCompliance');
    },

    /**
     * Disables best compliance report.
     */
    async disableReportBestCompliance() {
      await baseApi.delete('/api/organisations/settings/bestCompliance');
    },

    /**
     * Updates number of compliance days.
     * @param days
     * @param options
     */
    async updateNumberOfBestComplianceDays(days: number, options?: AxiosRequestConfig) {
      const params = url.format({ query: { days } });
      await baseApi.post(
        `/api/organisations/settings/numberOfBestComplianceDays${params}`,
        undefined,
        undefined,
        options,
      );
    },

    /**
     * Updates best compliance usage threshold.
     * @param threshold
     * @param options
     */
    async updateBestComplianceUsageThreshold(threshold: number, options?: AxiosRequestConfig) {
      const params = url.format({ query: { threshold } });
      await baseApi.post(`/api/organisations/settings/bestComplianceUsageThreshold${params}`);
    },

    /**
     * Enable telemonitoring for all newly registered P2.8W devices.
     */
    async activateTelemonitoringForOrganisation() {
      await baseApi.post('/api/organisations/settings/telemonitoringEnabled');
    },

    /**
     * Disables telemonitoring for all newly registered P2.8W devices.
     */
    async deactivateTelemonitoringForOrganisation() {
      await baseApi.delete('/api/organisations/settings/telemonitoringEnabled');
    },

    /**
     * Uploads an organisation logo.
     * @param logo Logo file to be uploaded.
     */
    async uploadLogo(logo: File): Promise<void> {
      const localVarAxiosArgs = prepareRequestAndFillFormData(logo, undefined, '/api/organisations/settings/logo');
      const axiosRequestArgs = {
        ...localVarAxiosArgs.options,
        url: localVarAxiosArgs.url,
      };
      await baseApi.request(axiosRequestArgs);
    },

    /**
     * Removes an organisation logo.
     */
    async removeLogo(): Promise<void> {
      await baseApi.delete('/api/organisations/settings/logo');
    },
  };
};

export const SettingsApiFactory = (baseApi: BaseAPI) => SettingsApi(baseApi);
