import { AxiosRequestConfig } from 'axios';

import { BaseAPI } from '@shared/api/base-api';

/**
 * Permissions that an organisation can grant to another organisation.
 */
export enum OrganisationLinkingPermissions {
  /**
   * The consuming organisation can view the statistics
   * from the providing organisation via External API (SFTP).
   */
  ExternalApiStatistics = 'ExternalApiStatistics',
  /**
   * The providing organisation can move its devices to the consuming organisation.
   */
  DevicePushing = 'DevicePushing',
  /**
   * The consuming organisation can move devices of the providing organisation to itself.
   */
  DevicePulling = 'DevicePulling',
  /**
   * The providing organisation can share its patients with the consuming organisation.
   */
  PatientSharing = 'PatientSharing',
  /**
   * The providing organisation gives access to read access to patient api
   * for the consuming organisation.
   */
  ReadPatientsApi = 'ReadPatientsApi',
  /**
   * The providing organisation gives create/update access to patient api
   * for the consuming organisation.
   */
  WritePatientsApi = 'WritePatientsApi',
  /**
   * The consuming organisation will be able to see all patients
   * from the providing organisation via UI
   */
  ShareAllPatients = 'ShareAllPatients',
  /**
   * The consuming organisation can manipulate the device settings via SPA
   */
  AllowTelesettings = 'AllowTelesettings',
}

/**
 * DTO to create a link between organisations.
 */
export interface CreateOrganisationLinkDto {
  /**
   * The tenant which should consume the data.
   */
  consumerTenant: string;
  /**
   * List of permissions of the link.
   */
  permissions: OrganisationLinkingPermissions[];
}

/**
 * DTO containing information about a user.
 */
export interface UserNameInfoDto {
  /**
   * The user id.
   */
  id: number;
  /**
   * First name of the user.
   */
  firstName: string;
  /**
   * Last name of the user.
   */
  lastName: string;
  /**
   * Email of the user.
   * */
  email: string;
}

/**
 * DTO containing consumer information of an organisation link.
 */
export interface OrganisationConsumerDto {
  /**
   * The tenant which consumes the data of the calling tenant.
   */
  tenant: string;
  /**
   * The name of the consumer's organisation.
   */
  organisationName: string;
  /**
   * The time when the link was created.
   */
  createdAt: string;
  /**
   * The user which created the link.
   */
  createdBy: UserNameInfoDto;
  /**
   * The last time when the link was changed.
   */
  changedAt: string;
  /**
   * The user which changed the link the last time.
   */
  changedBy: UserNameInfoDto;
  /**
   * List of permissions.
   */
  permissions: OrganisationLinkingPermissions[];
}

/**
 * DTO containing provider information of an organisation link.
 */
export interface OrganisationProviderDto {
  /**
   * The tenant which providers the data of the calling tenant.
   */
  tenant: string;
  /**
   * The name of the provider's organisation.
   */
  organisationName: string;
  /**
   * The time when the link was created.
   */
  createdAt: string;
  /**
   * The last time when the link was changed.
   */
  changedAt: string;
  /**
   * List of permissions.
   */
  permissions: OrganisationLinkingPermissions[];
}

/**
 * DTO which provides information of links between organisations.
 */
export interface OrganisationLinkDto {
  /**
   * List of organisations to which the current organisation provides data.
   */
  consumerList: OrganisationConsumerDto[];
  /**
   * List of organisations from which the current organisation consumes data.
   */
  providerList: OrganisationProviderDto[];
}

/**
 * DTO to edit a link for current organisation.
 */
export interface EditOrganisationLinkDto {
  /**
   * The tenant which consumes the data of the calling tenant.
   */
  consumerTenant: string;

  /**
   * List of permissions to apply.
   */
  permissions: OrganisationLinkingPermissions[];
}

/**
 * Contains information about organisation name and tenant.
 */
export interface OrganisationNameDto {
  /**
   * The organisation tenant.
   */
  tenant: string;

  /**
   * The organisation name.
   */
  name: string;
}

/**
 * Contains information about sharing consumers and providers.
 */
export interface AllSharingOrganisationsDto {
  /**
   * Organisations which received 'PatientSharing' permissions.
   */
  patientSharingConsumers: OrganisationNameDto[];

  /**
   * Organisations which granted 'PatientSharing' permissions.
   */
  patientSharingProviders: OrganisationNameDto[];

  /**
   * Organisations which received 'ShareAllPatients' permissions.
   */
  shareAllConsumers: OrganisationNameDto[];
}

export const OrganisationLinkApi = (baseApi: BaseAPI) => {
  return {
    /**
     * Creates a link of the current organisation with the given organisation.
     * @param {CreateOrganisationLinkDto} [dto] DTO containing information
     * to create a link between organisations.
     * @param {AxiosRequestConfig} [options] Axios options
     * @returns created organisation link.
     */
    async shareDataWithPartner(
      dto: CreateOrganisationLinkDto,
      options?: AxiosRequestConfig,
    ): Promise<OrganisationConsumerDto> {
      const { data } = await baseApi.post<OrganisationConsumerDto>('/api/organisationLink', undefined, dto, options);
      return data;
    },

    /**
     * Gets links of the current organisation to other organisations.
     * @returns Existing organisation links information.
     */
    async getLinks(options?: AxiosRequestConfig): Promise<OrganisationLinkDto> {
      const { data } = await baseApi.get<OrganisationLinkDto>('/api/organisationLink', undefined, options);
      return data;
    },

    /**
     * Revokes the link to specified consumer tenant.
     * @param {string} [consumerTenant] Consumer tenant to which the link has to be revoked.
     * @param {AxiosRequestConfig} [options] Axios options.
     */
    async revokeLink(consumerTenant: string, options?: AxiosRequestConfig): Promise<void> {
      await baseApi.delete(`/api/organisationLink/revoke/${consumerTenant}`, undefined, options);
    },

    /**
     * Edits a link of the current organisation with the given organisation.
     * @param {EditOrganisationLinkDto} [dto] DTO containing information
     * to edit a link between organisations.
     * @param {AxiosRequestConfig} [options] Axios options
     * @returns created organisation link.
     */
    async editLink(dto: EditOrganisationLinkDto, options?: AxiosRequestConfig): Promise<OrganisationConsumerDto> {
      const { data } = await baseApi.put<OrganisationConsumerDto>('/api/organisationLink', undefined, dto, options);
      return data;
    },

    /**
     * Rejects an organisation link for consumer organisation with given provider's tenant.
     * @param {string} [providerTenant] The tenant which provides the data.
     * @param {AxiosRequestConfig} [options] Axios options.
     */
    async rejectLink(providerTenant: string, options?: AxiosRequestConfig): Promise<void> {
      await baseApi.delete(`/api/organisationLink/reject/${providerTenant}`, undefined, options);
    },

    /**
     * Gets a list of consumer ogranisations to which the current one is able
     * to move devices.
     * @param {AxiosRequestConfig} [options] Axios options
     */
    async getDevicePushingConsumers(options?: AxiosRequestConfig): Promise<OrganisationNameDto[]> {
      const { data } = await baseApi.get<OrganisationNameDto[]>(
        '/api/organisationLink/devicePushingConsumers',
        undefined,
        options,
      );
      return data;
    },
    /**
     * Gets a list of consumer ogranisations to which the current one is able
     * to share patients.
     * @param {AxiosRequestConfig} [options] Axios options
     */
    async getAllSharingOrganisations(options?: AxiosRequestConfig): Promise<AllSharingOrganisationsDto> {
      const { data } = await baseApi.get<AllSharingOrganisationsDto>(
        '/api/organisationLink/allSharingOrganisations',
        undefined,
        options,
      );
      return data;
    },
  };
};

export const OrganisationLinkApiFactory = (baseApi: BaseAPI) => OrganisationLinkApi(baseApi);
