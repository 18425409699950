import clsx from 'clsx';
import React from 'react';

import {
  buttonBaseClasses,
  checkboxClasses,
  CheckboxProps,
  Components,
  Theme,
  touchRippleClasses,
} from '@mui/material';

import { CheckboxIcon } from './CheckboxIcon';

type MuiCheckboxTheme = Components<Theme>['MuiCheckbox'];

const checkboxIconClasses = {
  border: 'checkbox-icon__border',
  checkMark: 'checkbox-icon__check-mark',
  checkMarkChecked: 'checkbox-icon__check-mark_checked',
};

const getPalette = (theme: Theme, ownerState: CheckboxProps) =>
  theme.palette[ownerState.color === 'default' ? 'primary' : ownerState.color ?? 'primary'];

export const muiCheckboxTheme: MuiCheckboxTheme = {
  styleOverrides: {
    root: ({ ownerState, theme }) => ({
      alignItems: 'top',
      padding: 1,
      [`.${checkboxIconClasses.border}`]: {
        fill: 'white',
        stroke: theme.palette.grey[400],
        transition: theme.transitions.create(['fill', 'stroke'], {
          easing: theme.transitions.easing.easeIn,
          duration: theme.transitions.duration.shortest,
        }),
      },
      [`.${checkboxIconClasses.checkMark}`]: {
        stroke: getPalette(theme, ownerState).dark,
        transition: theme.transitions.create('opacity', {
          easing: theme.transitions.easing.easeIn,
          duration: theme.transitions.duration.shortest,
        }),
        opacity: 0,
      },
      [`.${checkboxIconClasses.checkMarkChecked}`]: {
        opacity: 1,
      },
      [`&.${checkboxClasses.disabled}`]: {
        [`.${checkboxIconClasses.border}`]: {
          fill: 'white',
          stroke: theme.palette.grey[200],
        },
        [`.${checkboxIconClasses.checkMark}`]: {
          stroke: theme.palette.grey[200],
        },
      },
      [`.${touchRippleClasses.root}`]: {
        left: 3,
        right: 3,
        top: 3,
        bottom: 3,
      },
      [`&.${buttonBaseClasses.focusVisible}:not(${checkboxClasses.disabled})`]: {
        [`.${checkboxIconClasses.border}`]: {
          fill: getPalette(theme, ownerState).light,
          stroke: getPalette(theme, ownerState).main,
        },
      },
      [`&:hover:not(${checkboxClasses.disabled})`]: {
        [`.${checkboxIconClasses.border}`]: {
          fill: getPalette(theme, ownerState).light,
          stroke: getPalette(theme, ownerState).main,
        },
      },
    }),
  },
  defaultProps: {
    disableFocusRipple: true,
    checkedIcon: (
      <CheckboxIcon
        borderClassName={checkboxIconClasses.border}
        checkClassName={clsx(checkboxIconClasses.checkMark, checkboxIconClasses.checkMarkChecked)}
      />
    ),
    icon: <CheckboxIcon borderClassName={checkboxIconClasses.border} checkClassName={checkboxIconClasses.checkMark} />,
  },
};
