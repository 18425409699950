import { RequestState } from '@shared/constants/request';
import * as TrustedBrowsersActions from '@shared/redux/actions/trustedBrowser-actions';
import { initialTrustedBrowsersState } from './initState/initTrustedBrowsersState';
import { TrustedBrowsersState } from '@shared/redux/state/trustedBrowsersState';
import { ActionWithPayload } from '@shared/redux/actions/typings';

export default function trustedBrowsers(
  state: TrustedBrowsersState = initialTrustedBrowsersState,
  action: ActionWithPayload<any>,
): TrustedBrowsersState {
  switch (action.type) {
    case TrustedBrowsersActions.FETCH_TRUSTED_BROWSERS_REQUEST:
      return {
        ...state,
        gettingRequestState: RequestState.PENDING,
      };

    case TrustedBrowsersActions.FETCH_TRUSTED_BROWSERS_SUCCESS:
      return {
        ...state,
        entities: action.payload,
        gettingRequestState: RequestState.SUCCESS,
      };

    case TrustedBrowsersActions.FETCH_TRUSTED_BROWSERS_FAILED:
      return {
        ...state,
        gettingRequestState: RequestState.FAILED,
      };
    case TrustedBrowsersActions.CLEAR_USERS_BROWSERS_REQUEST:
      return {
        ...state,
        deletionRequestState: RequestState.PENDING,
      };
    case TrustedBrowsersActions.CLEAR_USERS_BROWSERS_SUCCESS:
      return {
        ...state,
        entities: [],
        deletionRequestState: RequestState.SUCCESS,
      };
    case TrustedBrowsersActions.CLEAR_USERS_BROWSERS_FAILURE:
      return {
        ...state,
        deletionRequestState: RequestState.FAILED,
      };

    default:
      return state;
  }
}
