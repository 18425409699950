import { call, put } from 'redux-saga/effects';
import { SubmissionError } from 'redux-form';

import * as PhoneVerificationActions from '@shared/redux/actions/phoneVerification-actions';
import { ActionWithPayload } from '@shared/redux/actions/typings';
import { handleErrorResponse } from '@shared/util/server-error-response-helper';
import { PhoneVerificationApi } from '@shared/api/auth-api';
import { handleReactFormSagaError, handleSagaError } from '@shared/util/handleSagaError';
import { VerifyPhoneCommand } from '@shared/api/auth-api/phone-verification-api';

function isInvalidCode(e: any) {
  const error = handleErrorResponse(e);
  return error && error.hasOwnProperty('verificationCode');
}

export function* fetchSendVerificationSms() {
  try {
    yield call(PhoneVerificationApi.post);
    const successAction = PhoneVerificationActions.sendVerificationSms.success();
    yield put(successAction);
  } catch (e) {
    const failedAction = PhoneVerificationActions.sendVerificationSms.failure(e);
    yield handleSagaError({
      ...failedAction,
      error: e,
    });
  }
}

export function* fetchVerifyCode(action: ActionWithPayload<VerifyPhoneCommand>) {
  try {
    yield call(PhoneVerificationApi.verifyOtp, action.payload);
    const successAction = PhoneVerificationActions.verifyCode.success();
    yield put(successAction);
  } catch (e) {
    const formError = new SubmissionError(handleErrorResponse(e));
    const failedAction = PhoneVerificationActions.verifyCode.failure(formError);
    if (isInvalidCode(e)) {
      yield put(failedAction);
    } else {
      yield handleReactFormSagaError({
        error: e,
        formError,
        failedAction,
      });
    }
  }
}
