import latinize from 'latinize';
import sanitize from 'sanitize-filename';

export function latinizeExtended(stringToBeLatinized: string): string {
  latinize.characters['Ä'] = 'Ae';
  latinize.characters['Ö'] = 'Oe';
  latinize.characters['Ü'] = 'Ue';
  latinize.characters['ä'] = 'ae';
  latinize.characters['ö'] = 'oe';
  latinize.characters['ü'] = 'ue';

  return latinize(stringToBeLatinized);
}

export function getLatinizedFileName(desiredFilename: string, maxFileNameLength: number): string {
  const latinizedFileName = latinizeExtended(desiredFilename);
  const sanitizedFileName = sanitize(latinizedFileName);
  let latinCharFileName = sanitizedFileName.replace(/[^a-zA-Z0-9_\-.]/g, '');

  if (latinCharFileName.length > maxFileNameLength) latinCharFileName = latinCharFileName.substr(0, maxFileNameLength);

  return latinCharFileName;
}
