import { ActionType, getType } from 'typesafe-actions';

import { initUnauthorizedState } from './initState/initAuthState';
import { LogoutReason, PERMISSIONS } from '@shared/constants';
import { UserRoleTypes } from '@shared/constants/UserRoleTypes';
import * as AuthActions from '@shared/redux/actions/auth-actions';
import { TwoFactorLoginActionTypes } from '@shared/redux/actions/auth-actions';

export interface AuthorizedState {
  isAuthenticated: true;
  loginWindowStep: 'login' | 'secondFactor';
  phone: string;
  userName: string;
  firstName: string;
  lastName: string;
  email: string;
  subject: string;
  user_self_permissions: UserRoleTypes;
  user_other_permissions: PERMISSIONS;
  user_other_orgmanager_permissions: PERMISSIONS;
  org_settings_permissions: PERMISSIONS;
  organization_permissions: PERMISSIONS;
  organization_other_permissions: PERMISSIONS;
  med_settings_permissions: PERMISSIONS;
  sd_card_upload_permissions: PERMISSIONS;
  patient_permissions: PERMISSIONS;
  device_settings: PERMISSIONS;
  download_firmware_permissions: PERMISSIONS;
  pic_permissions: PERMISSIONS;
  preregister_device: PERMISSIONS;
}

export interface UnauthorizedState {
  isAuthenticated: false;
  loginWindowStep: 'login' | 'secondFactor';
  errorMessage: string;
  lastLogoutReason: LogoutReason;
  phone: string;
}

export type AuthState = AuthorizedState | UnauthorizedState;

export default function auth(
  state: AuthState = initUnauthorizedState,
  action: ActionType<typeof AuthActions>,
): AuthState {
  switch (action.type) {
    case TwoFactorLoginActionTypes.REQUIRE: {
      return {
        ...state,
        loginWindowStep: 'secondFactor',
        phone: action.payload.phone,
      };
    }

    case getType(AuthActions.secondFactorRejected): {
      return {
        ...state,
        loginWindowStep: 'login',
        phone: initUnauthorizedState.phone,
      };
    }

    case AuthActions.UPDATE_USER_DATA: {
      if (action.payload.isAuthenticated) {
        return {
          ...state,
          ...action.payload,
          loginWindowStep: 'login',
        };
      } else {
        return {
          ...action.payload,
          phone: state.phone,
          loginWindowStep: state.loginWindowStep,
          lastLogoutReason: state.isAuthenticated ? LogoutReason.Unknown : state.lastLogoutReason,
        };
      }
    }

    case AuthActions.LOGOUT_SUCCESS:
      return {
        ...initUnauthorizedState,
        lastLogoutReason: action.payload.reason,
      };

    default:
      return state;
  }
}
