// tslint:disable:typedef no-submodule-imports no-default-export
import { all } from 'redux-saga/effects';

import * as OrganisationActionTypes from '@shared/redux/actions/organisation-actions';
import * as OrganisationSaga from '@shared/redux/sagas/organisation-saga';

function* watchGetCurrentOrganisationRequest() {
  yield OrganisationActionTypes.getCurrentOrganisation.watch(OrganisationSaga.fetchGetCurrentOrganisation);
}

export default function* watchOrganisationActions() {
  yield all([watchGetCurrentOrganisationRequest()]);
}
