import { BroadcastChannel } from 'broadcast-channel';
import i18next from 'i18next';
import jwt_decode, { JwtPayload } from 'jwt-decode';

import { Culture, getCultures } from './cultures';
import { getTimeSinceLastSeenAsString } from './localizationHelper';
import {
  clearUserState,
  getCulture,
  getLastActive,
  getName,
  getRefreshToken,
  getToken,
  setActive,
  setAuthTimeLocal,
  setUserState,
  subscribeAuthTimeLocalChanged,
} from './localStorageAccessor';
import { getTenant } from './tenant';
import { AuthData, TokenApi } from '@shared/api/auth-api';
import { IUserDto } from '@shared/api/core-api/users-api';
import { CHRONOMETER_TICK_INTERVAL, PermissionClaims, USER_IDLE_LIMIT_SECONDS } from '@shared/constants';
import { UserRoleTypes } from '@shared/constants/UserRoleTypes';
import { UserEntity } from '@shared/redux/state/users';
import enumExtensions from '@shared/util/enum';

export interface JwtToken extends JwtPayload, PermissionClaims {
  tenants: string[];
  given_name: string;
  middle_name: string;
  sub: string;
  id: string;
  email: string;
  user_self_permissions: UserRoleTypes;
}

export interface CultureInfo {
  culture: string;
  dayJSLocale: string;
  isCultureUserDefined: boolean;
}

export const validatePersonName = (
  firstName: string | null | undefined,
  lastName: string | null | undefined,
  email: string,
) => {
  firstName ??= '';
  lastName ??= '';
  firstName = firstName.trim();
  lastName = lastName.trim();
  const hasFirstName = firstName.length > 0 && firstName !== '_';
  const hasLastName = lastName.length > 0 && lastName !== '_';

  if (!hasFirstName && !hasLastName) {
    return { type: 'email', value: email } as const;
  }

  if (!hasFirstName) {
    return {
      type: 'lastName',
      value: lastName,
    } as const;
  }

  if (!hasLastName) {
    return { type: 'firstName', value: firstName } as const;
  }

  return { type: 'fullName', value: `${firstName} ${lastName}`, firstName, lastName } as const;
};

export type UserInfo = {
  firstName: string | null | undefined;
  lastName: string | null | undefined;
  email: string;
};

/**
 * Tries to fit person name, if it is too long it put only first letter from name and full last name.
 * @param firstName user first name.
 * @param lastName user last name.
 * @param email user email.
 */
export const fitPersonName = ({ firstName, lastName, email }: UserInfo) => {
  const { type, value, firstName: resFirstName } = validatePersonName(firstName, lastName, email);

  if (type === 'fullName' && value.length > 19) {
    return `${resFirstName[0]}. ${lastName}`;
  }

  return value;
};

/**
 * Use this function when you need to show user name (not a patient).
 * @returns Combined user name or Email if first and last name is missing.
 */
export const getUserName = ({ firstName, lastName, email }: UserInfo) => {
  const {
    type,
    value,
    firstName: resFirstName,
    lastName: resLastName,
  } = validatePersonName(firstName, lastName, email);

  if (type === 'fullName') {
    return getFullName(resFirstName, resLastName);
  }

  return value;
};

/**
 * Use this function when you need to show user name with ones email.
 * @returns User name and email, or any of mentioned if available.
 */
export const getUserNameWithEmail = ({ firstName, lastName, email }: UserInfo) => {
  const validationResult = validatePersonName(firstName, lastName, email);

  if (validationResult.type === 'fullName') {
    return `${getFullName(validationResult.firstName, validationResult.lastName)} (${email})`;
  }

  if (validationResult.type === 'firstName' || validationResult.type === 'lastName') {
    return `${validationResult.value} (${email})`;
  }

  return email;
};

const getFullName = (firstName: string, lastName: string) => {
  return `${lastName}, ${firstName}`;
};

export const setUser = (authData: AuthData, userName: string) => {
  setUserState(userName, authData.accessToken, authData.refreshToken);

  const decodedToken = getDecodedToken();
  if (decodedToken) {
    setAuthTimeLocal(new Date(Date.now()));
  }
};

/**
 * Gets user selected culture, browser preferred culture or en-gb if it is unable to automatically detect culture.
 * If you want to get it from react component use ```useStoredOrDefaultCulture``` function instead.
 * @returns
 */
export const getStoredOrDefaultCulture = (): CultureInfo => {
  const cultureObjectAsString: string | null = getCulture();
  const supportedCultures = getCultures();

  if (cultureObjectAsString) {
    const storedCulture: Partial<CultureInfo> = JSON.parse(cultureObjectAsString);

    if (
      storedCulture.culture !== undefined &&
      storedCulture.dayJSLocale !== undefined &&
      storedCulture.isCultureUserDefined !== undefined &&
      supportedCultures.some((x) => x.languageCulture === storedCulture.culture)
    ) {
      // typescript does not detect that we checked all properties and all of them are not undefined
      // this is type check workaround it will be a compilation error if new property is added.
      return {
        culture: storedCulture.culture,
        dayJSLocale: storedCulture.dayJSLocale,
        isCultureUserDefined: storedCulture.isCultureUserDefined,
      };
    }
  }

  const preferredBrowserCultures = window.navigator.languages
    ? window.navigator.languages
    : [window.navigator.language];

  const fallbackCulture = supportedCultures[0];
  const culture = getBestSuitableCulture(preferredBrowserCultures, supportedCultures, fallbackCulture);
  return { culture: culture.languageCulture, dayJSLocale: culture.dayJSLocale, isCultureUserDefined: false };
};

const getBestSuitableCulture = (
  preferredCultures: Readonly<string[]>,
  supportedCultures: Culture[],
  fallbackCulture: Culture,
) => {
  for (const preferredCulture of preferredCultures) {
    const supportedCulture = supportedCultures.find((x) => x.languageCulture === preferredCulture);
    if (supportedCulture) {
      return supportedCulture;
    }
  }

  return fallbackCulture;
};

export const dropUser = () => {
  clearUserState();
};

export const isTokenExpired = () => {
  const tokenString = getToken();
  const decodedToken = getDecodedToken();
  if (!tokenString) {
    return undefined;
  }

  return !decodedToken;
};

export const isAuthenticated = () => {
  const decoded = getDecodedToken();
  return !!decoded;
};

/**
 * Returns decoded JWT token in case it is actual and belongs to current tenant.
 */
export const getDecodedToken = () => {
  const token = getToken();
  const tenant = getTenant();
  if (!token || !tenant) {
    return null;
  }

  try {
    const decodedToken = jwt_decode<JwtToken>(token);

    if (!decodedToken.tenants.includes(tenant)) {
      return null;
    }

    if ((decodedToken.exp ?? 0) * 1000 < Date.now()) {
      return null;
    }

    return decodedToken;
  } catch (e) {
    console.error('Token:', token, new Error().stack);
    throw e;
  }
};

/**
 * Returns logged in user id or undefined if user is not logged in.
 */
export const getUserId = () => {
  const token = getDecodedToken();
  return token?.id;
};

export const getIsPortal = () => {
  const token = getDecodedToken();
  return token?.tenants.includes('portal');
};

export const initialize = (onAuthChanged: () => void) => {
  subscribeAuthTimeLocalChanged(onAuthChanged);
};

export const isUserIdle = (): boolean => {
  const lastActive = getLastActive();
  if (!lastActive) {
    return false;
  }

  const secondsInactive = (Date.now() - parseInt(lastActive)) / 1000;
  return secondsInactive >= USER_IDLE_LIMIT_SECONDS;
};

export const markAsActive = () => {
  setActive();
};

// Used to notify other tabs that this one initiated refresh of access token.
export const startRefreshMessage = 'START_REFRESH';
export const refreshChannelName = 'REFRESH_CHANNEL';
const refreshChannelBroadcast = new BroadcastChannel(refreshChannelName);
const refreshWaitTime = 60000;
let lastRefreshDate = 0;
refreshChannelBroadcast.addEventListener('message', (msg) => {
  if (msg === startRefreshMessage) {
    lastRefreshDate = Date.now();
  }
});

export const refreshIfNeeded = async () => {
  const tokenAlmostExpired = isTokenAboutToOutdate();
  const isIdle = isUserIdle();
  const isRefreshingInParallel = lastRefreshDate + refreshWaitTime > Date.now();

  if (tokenAlmostExpired && !isIdle && !isRefreshingInParallel) {
    await refreshChannelBroadcast.postMessage(startRefreshMessage);
    await refresh();
  }
};

export const getUserPermissions = (): PermissionClaims | null => {
  return getDecodedToken();
};

export const getRoleString = (userRoles: UserRoleTypes) => {
  return userRoles === 0 ? '' : getLocalizedRolesString(userRoles);
};

export const isRoleSet = (userRoles: UserRoleTypes, roleToCheck: UserRoleTypes) => {
  const flags = enumExtensions.getAllPresentedFlags(userRoles);
  return flags.includes(roleToCheck);
};

export const localizeUser = (user: UserEntity) => {
  return {
    ...user,
    roles: getRoleString(user.userRoles),
    lastSeen: getTimeSinceLastSeenAsString(user.secondsSinceLastSeen),
  };
};

const portalTenant = 'portal';
export const isPortalUser = (user: IUserDto) => {
  return user.tenant === portalTenant;
};

const isTokenAboutToOutdate = () => {
  const token = getDecodedToken();
  if (!token?.exp) {
    return false;
  }

  const expTime = token.exp * 1000;
  if (expTime < Date.now()) {
    return false;
  }

  const delta = expTime - Date.now();
  return delta < CHRONOMETER_TICK_INTERVAL * 6000;
};

const refresh = async () => {
  const name = getName();
  const refreshToken = getRefreshToken();
  if (refreshToken) {
    const authData = await TokenApi.refreshAccessToken(refreshToken);
    setUser(authData, name!);
  } else {
    throw new Error('Cannot refresh accessToken: refreshToken is null.');
  }
};

const getDeviceConfigEditRolesString = (userRoles: UserRoleTypes[]): string => {
  const wantedOrder = [
    UserRoleTypes.PrismaSmartConfigurationEditor,
    UserRoleTypes.PrismaLineSleepConfigurationEditor,
    UserRoleTypes.PrismaLineVentiConfigurationEditor,
    UserRoleTypes.PrismaVentConfigurationEditor,
  ];
  const configEditRoles = userRoles
    .filter(
      (r) =>
        r === UserRoleTypes.PrismaSmartConfigurationEditor ||
        r === UserRoleTypes.PrismaLineSleepConfigurationEditor ||
        r === UserRoleTypes.PrismaLineVentiConfigurationEditor ||
        r === UserRoleTypes.PrismaVentConfigurationEditor,
    )
    .sort((a: UserRoleTypes, b: UserRoleTypes) => {
      const idxA = wantedOrder.findIndex((x) => x === a);
      const idxB = wantedOrder.findIndex((x) => x === b);
      return idxA - idxB;
    });
  const localizedRoles = configEditRoles.map((x) => i18next.t('User_Role_' + x));
  return configEditRoles.length > 0
    ? ` (${i18next.t('User_AdditionalRoles.EditDeviceConfig.PermittedForLabel')} ${localizedRoles.join(', ')})`
    : '';
};

const getTelemonitoringSwitchRolesString = (userRoles: UserRoleTypes[]): string => {
  return userRoles.includes(UserRoleTypes.PrismaSmartTelemonitoringSwitcher)
    ? ` (${i18next.t('User_AdditionalRoles.TelemonitoringSwitching.PermittedForLabel')})`
    : '';
};

const getLocalizedRolesString = (userRoles: UserRoleTypes): string => {
  const flags = enumExtensions.getAllPresentedFlags(userRoles);

  const mainRoles = flags.filter(
    (r) =>
      r !== UserRoleTypes.PrismaSmartConfigurationEditor &&
      r !== UserRoleTypes.PrismaLineSleepConfigurationEditor &&
      r !== UserRoleTypes.PrismaLineVentiConfigurationEditor &&
      r !== UserRoleTypes.PrismaVentConfigurationEditor &&
      r !== UserRoleTypes.PrismaSmartTelemonitoringSwitcher,
  );

  const localizedRoles = mainRoles.map((x) => {
    if (x === UserRoleTypes.MedicalProfessional) {
      return i18next.t('User_Role_' + x) + `${getDeviceConfigEditRolesString(flags)}`;
    } else if (x === UserRoleTypes.Technician) {
      return i18next.t('User_Role_' + x) + `${getTelemonitoringSwitchRolesString(flags)}`;
    } else {
      return i18next.t('User_Role_' + x);
    }
  });

  return localizedRoles.join(', ');
};
