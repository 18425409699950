import { UploadAcceptedDto } from '@shared/api/sd-upload-api/sd-upload-api';
import { SdCardFile } from '@shared/redux/reducers/sdCardUpload-reducer';

export const UPLOAD_FILE_REQUEST = 'UPLOAD_FILE_REQUEST';
export const UPLOAD_FILE_STARTED = 'UPLOAD_FILE_STARTED';
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS';
export const UPLOAD_FILE_FAILURE = 'UPLOAD_FILE_FAILURE';
export const UPLOAD_FILE_PROGRESS = 'UPLOAD_FILE_PROGRESS';
export const CANCEL_FILE_UPLOAD_REQUEST = 'CANCEL_FILE_UPLOAD_REQUEST';
export const CANCEL_FILE_UPLOAD_SUCCESS = 'CANCEL_FILE_UPLOAD_SUCCESS';
export const FETCH_SUPPORTED_FILE_EXTENSIONS_REQUEST = 'FETCH_SUPPORTED_FILE_EXTENSIONS_REQUEST';
export const FETCH_SUPPORTED_FILE_EXTENSIONS_SUCCESS = 'FETCH_SUPPORTED_FILE_EXTENSIONS_SUCCESS';
export const FETCH_SUPPORTED_FILE_EXTENSIONS_FAILURE = 'FETCH_SUPPORTED_FILE_EXTENSIONS_FAILURE';

export interface UploadFileRequestAction {
  type: 'UPLOAD_FILE_REQUEST';
  payload: {
    file: SdCardFile;
    id: number;
    progressCallback: (e: any) => void;
  };
}
export const uploadFileRequestAction = (
  file: SdCardFile,
  id: number,
  progressCallback: (e: any) => void,
): UploadFileRequestAction => {
  return {
    type: UPLOAD_FILE_REQUEST,
    payload: {
      id,
      file,
      progressCallback,
    },
  };
};

export interface UploadFileStartedAction {
  type: 'UPLOAD_FILE_STARTED';
  payload: {
    id: number;
    cancellationCallback: () => void;
  };
}
export const uploadFileStartedAction = (id: number, cancellationCallback: () => void): UploadFileStartedAction => {
  return {
    type: UPLOAD_FILE_STARTED,
    payload: {
      id,
      cancellationCallback,
    },
  };
};

export interface UploadFileProgressAction {
  type: 'UPLOAD_FILE_PROGRESS';
  payload: {
    id: number;
    progress: number;
  };
}
export const uploadFileProgressAction = (id: number, progress: number): UploadFileProgressAction => {
  return {
    type: UPLOAD_FILE_PROGRESS,
    payload: {
      id,
      progress,
    },
  };
};

export interface CancelFileUploadRequestAction {
  type: 'CANCEL_FILE_UPLOAD_REQUEST';
}
export const cancelFileUploadRequestAction = (): CancelFileUploadRequestAction => {
  return {
    type: CANCEL_FILE_UPLOAD_REQUEST,
  };
};

export interface CancelFileUploadSuccessAction {
  type: 'CANCEL_FILE_UPLOAD_SUCCESS';
}
export const cancelFileUploadSuccessAction = (): CancelFileUploadSuccessAction => {
  return {
    type: CANCEL_FILE_UPLOAD_SUCCESS,
  };
};

export interface UploadFileSuccessAction {
  type: 'UPLOAD_FILE_SUCCESS';
  payload: {
    uploadInfo: UploadAcceptedDto;
    id: number;
  };
}
export const uploadFileSuccessAction = (uploadInfo: UploadAcceptedDto, id: number): UploadFileSuccessAction => {
  return {
    type: UPLOAD_FILE_SUCCESS,
    payload: {
      id,
      uploadInfo,
    },
  };
};

export interface UploadFileFailureAction {
  type: 'UPLOAD_FILE_FAILURE';
  payload: {
    id: number;
    resourceKey: string;
  };
}
export const uploadFileFailureAction = (id: number, resourceKey: string): UploadFileFailureAction => {
  return {
    type: UPLOAD_FILE_FAILURE,
    payload: {
      id,
      resourceKey,
    },
  };
};

export interface FetchSupportedFileExtensionsRequestAction {
  type: 'FETCH_SUPPORTED_FILE_EXTENSIONS_REQUEST';
}
export const fetchSupportedFileExtensionsRequestAction = (): FetchSupportedFileExtensionsRequestAction => {
  return {
    type: FETCH_SUPPORTED_FILE_EXTENSIONS_REQUEST,
  };
};

export interface FetchSupportedFileExtensionsSuccessAction {
  type: 'FETCH_SUPPORTED_FILE_EXTENSIONS_SUCCESS';
  payload: {
    supportedFileExtensions: string[];
  };
}
export const fetchSupportedFileExtensionsSuccessAction = (
  supportedFileExtensions: string[],
): FetchSupportedFileExtensionsSuccessAction => {
  return {
    type: FETCH_SUPPORTED_FILE_EXTENSIONS_SUCCESS,
    payload: {
      supportedFileExtensions,
    },
  };
};

export interface FetchSupportedFileExtensionsFailureAction {
  type: 'FETCH_SUPPORTED_FILE_EXTENSIONS_FAILURE';
  error: unknown;
}
export const fetchSupportedFileExtensionsFailureAction = (
  error: unknown,
): FetchSupportedFileExtensionsFailureAction => {
  return {
    type: FETCH_SUPPORTED_FILE_EXTENSIONS_FAILURE,
    error,
  };
};
