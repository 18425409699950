export const SFTP_DEACTIVATION_REQUEST = 'SFTP_DEACTIVATION_REQUEST';
export const SFTP_DEACTIVATION_REQUEST_SUCCESS = 'SFTP_DEACTIVATION_REQUEST_SUCCESS';
export const SFTP_DEACTIVATION_REQUEST_FAILED = 'SFTP_DEACTIVATION_REQUEST_FAILED';

export const SFTP_GENERATION_PASSWORD_REQUEST = 'SFTP_GENERATION_PASSWORD_REQUEST';
export const SFTP_GENERATION_PASSWORD_REQUEST_SUCCESS = 'SFTP_GENERATION_PASSWORD_REQUEST_SUCCESS';
export const SFTP_GENERATION_PASSWORD_REQUEST_FAILED = 'SFTP_GENERATION_PASSWORD_REQUEST_FAILED';

export const SFTP_PASS_DAYS_LEFT_REQUEST = 'SFTP_PASS_DAYS_LEFT_REQUEST';
export const SFTP_PASS_DAYS_LEFT_REQUEST_SUCCESS = 'SFTP_PASS_DAYS_LEFT_REQUEST_SUCCESS';
export const SFTP_PASS_DAYS_LEFT_REQUEST_FAILED = 'SFTP_PASS_DAYS_LEFT_REQUEST_FAILED';

export const SFTP_CONNECTION_SETTINGS_REQUEST = 'SFTP_CONNECTION_SETTINGS_REQUEST';
export const SFTP_CONNECTION_SETTINGS_REQUEST_SUCCESS = 'SFTP_CONNECTION_SETTINGS_REQUEST_SUCCESS';
export const SFTP_CONNECTION_SETTINGS_REQUEST_FAILED = 'SFTP_CONNECTION_SETTINGS_REQUEST_FAILED';
