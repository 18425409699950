import { all, takeEvery, put } from 'redux-saga/effects';
import { Action } from 'redux';

import * as NotificationActions from '@shared/redux/actions/notification-actions';
import * as UnhandledExceptionActions from '@shared/redux/actions/unhandledException-actions';
import * as NotificationSaga from '@shared/redux/sagas/notification-saga';
import { NotificationKinds } from '@shared/redux/state/notification';

/**
 * Describes error field of IErrorAction.
 */
export interface IErrorActionError {
  /**
   * Localization resource key of warning message.
   * This field must be set manually.
   */
  resourceKey?: string;
  /**
   * If this error is expected and we should not treat it as unhandled exception.
   * This field must be set manually.
   */
  expected?: boolean;
}

/**
 * Redux action that is dispatched when an error ocurred.
 */
export interface IErrorAction<TType = any> extends Action<TType> {
  /**
   * Indicator that an error ocurred, see watchErrors description for details.
   */
  error: IErrorActionError;
  /**
   * Action payload with ocurred Error.
   */
  payload: Error;
}

interface ISuccessAction extends Action<string> {
  successNotification?: {
    resourceKey?: string;
  };
}

export function* watchNotificationsWithTimeout() {
  yield takeEvery(NotificationActions.SHOW_NOTIFICATION_WITH_TIMEOUT, NotificationSaga.processNotificationWithTimeout);
}

export function* watchRevokeNotificationsWithTimeout() {
  yield takeEvery(
    NotificationActions.SHOW_REVOKE_NOTIFICATION_WITH_TIMEOUT,
    NotificationSaga.processRevokeNotificationWithTimeout,
  );
}

/**
 * Monitors the action pipeline and triggers error notifications for actions having
 * the `error` property.
 * @example
 * // Show a generic error message like "The operation failed, refresh and try again."
 * try {
 *     // saga
 * }
 * catch (e) {
 *     yield handleSagaError({ type: GET_FAILURE, error: e });
 * }
 * @example
 * // Show the error message defined by the `resourceKey`.
 * try {
 *     // saga
 * }
 * catch (e) {
 *     yield handleSagaError({
 *         type: GET_FAILURE,
 *         errorResourceKey: 'Error_Loading_Data',
 *         error: e });
 * }
 */
export function* watchErrors() {
  yield takeEvery(
    // Ignore actions from redux-forms and other libraries
    // (their types conventionally start with '@').
    (action: any) => typeof action.type === 'string' && !action.type.startsWith('@') && action.error !== undefined,
    function* notification(action: IErrorAction) {
      if (action.payload && !action.error.expected) {
        const logAction = UnhandledExceptionActions.logUnhandledException(action.type, action.payload);
        yield put(logAction);
      }
      const payload = {
        id: NotificationActions.ERROR_ID,
        resourceKey: action.error.resourceKey || NotificationActions.GENERIC_ERROR_KEY,
        kind: NotificationKinds.Warning,
      };
      const notificationAction = NotificationActions.showNotificationWithTimeout(payload);
      yield put(notificationAction);
    },
  );
}

export function* watchSuccessMessages() {
  yield takeEvery(
    // Ignore actions from redux-forms and other libraries
    // (their types conventionally start with '@').
    (action: ISuccessAction) =>
      typeof action.type === 'string' &&
      !action.type.startsWith('@') &&
      action.successNotification !== undefined &&
      action.successNotification.resourceKey !== undefined,
    function* notification(action: ISuccessAction) {
      const payload = {
        id: NotificationActions.SUCCESS_ID,
        resourceKey: (action.successNotification && action.successNotification.resourceKey) || '',
        kind: NotificationKinds.Success,
      };
      const notificationAction = NotificationActions.showNotificationWithTimeout(payload);
      yield put(notificationAction);
    },
  );
}

export default function* watchNotificationActions() {
  yield all([
    watchErrors(),
    watchNotificationsWithTimeout(),
    watchRevokeNotificationsWithTimeout(),
    watchSuccessMessages(),
  ]);
}
