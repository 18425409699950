import { Action } from 'redux';
import { createCustomAction } from 'typesafe-actions';

import { ActivateUserPayload, ResetPasswordPayload } from '@shared/redux/sagas/auth-saga';
import * as LoginPayloads from './payloadTypes/authActionPayloads';
import { CreateFormActions, createFormAction } from '@shared/util/createFormAction';
import { LogoutReason } from '@shared/constants';
import { ForgottenPasswordFormData } from '@shared/types/ForgottenPasswordFormData';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

export const UPDATE_USER_DATA = 'UPDATE_USER_DATA';

export const login = createFormAction('LOGIN')<LoginPayloads.LoginRequestPayload, LoginPayloads.LoginSuccess>();
export const rejectLogin = createCustomAction('LOGIN_REJECTED');

export enum TwoFactorLoginActionTypes {
  REQUIRE = 'TWO_FACTOR_LOGIN_REQUIRE',
  REQUEST = 'TWO_FACTOR_LOGIN_REQUEST',
  SUCCESS = 'TWO_FACTOR_LOGIN_SUCCESS',
  FAILURE = 'TWO_FACTOR_LOGIN_FAILURE',
  REJECTED = 'TWO_FACTOR_LOGIN_REJECTED',
}

export const logoutSuccess = (reason: LogoutReason) =>
  ({
    type: LOGOUT_SUCCESS,
    payload: {
      reason,
    },
  } as const);

export const secondFactorRequired = (payload: LoginPayloads.SecondFactorRequiredPayload) => {
  return {
    type: TwoFactorLoginActionTypes.REQUIRE,
    payload,
  } as const;
};

export const updateUserData = (payload: LoginPayloads.UserDataPayload) =>
  ({
    type: UPDATE_USER_DATA,
    payload,
  } as const);

export const secondFactorRejected = createCustomAction(TwoFactorLoginActionTypes.REJECTED);
export const twoFactorLogin = createFormAction('TWO_FACTOR_LOGIN')<
  LoginPayloads.SecondFactorLoginRequestPayload,
  LoginPayloads.LoginSuccess
>();

export const AUTH_DATA_CHANGED = 'AUTH_DATA_CHANGED';

export function authDataChanged() {
  return {
    type: AUTH_DATA_CHANGED,
    payload: {},
  } as const;
}

export function loggedOutDueToPasswordChange() {
  return {
    type: LOGOUT_REQUEST,
    payload: {
      reason: LogoutReason.PasswordChange,
    },
  } as const;
}

export function logOutWithReasonUnknown() {
  return {
    type: LOGOUT_REQUEST,
    payload: {
      reason: LogoutReason.Unknown,
    },
  } as const;
}

/**
 * See BrowserExtensions.fs b.Logout().
 */
export function logOutByAutotests() {
  return {
    type: LOGOUT_REQUEST,
    payload: {
      reason: LogoutReason.AutotestLogout,
    },
  } as const;
}

export function logOutByUsersRequest() {
  return {
    type: LOGOUT_REQUEST,
    payload: {
      reason: LogoutReason.LogoutByRequest,
    },
  } as const;
}

export function logOutOnIdle() {
  return {
    type: LOGOUT_REQUEST,
    payload: {
      reason: LogoutReason.Idle,
    },
  } as const;
}

export const sendForgottenPasswordEmail = createFormAction('SEND_FORGOTTEN_PASSWORD_MAIL')<
  ForgottenPasswordFormData,
  void
>();
export const resetPassword = createFormAction('RESET_PASSWORD')<ResetPasswordPayload>();
export const activateUser = createFormAction('ACTIVATE_USER')<ActivateUserPayload>();

export const PASSWORD_CHANGED = 'PASSWORD_CHANGED';

export function passwordChanged() {
  return {
    type: PASSWORD_CHANGED,
  } as const;
}

export type AuthActions =
  | CreateFormActions<typeof login>
  | ReturnType<typeof authDataChanged>
  | CreateFormActions<typeof twoFactorLogin>
  | ReturnType<typeof logoutSuccess>
  | Action<typeof LOGOUT_FAILURE>
  | ReturnType<typeof passwordChanged>;
