import { PatientSortingFields } from '@shared/constants/sortingFields';
import { SortDirections } from '@shared/constants/SortDirections';

export const PATIENTS_ADMIN_LIST_SET_SORTING = 'PATIENTS_ADMIN_LIST_SET_SORTING';

export const setSorting = (field: PatientSortingFields, defaultSortDirection?: SortDirections) => ({
  type: PATIENTS_ADMIN_LIST_SET_SORTING as typeof PATIENTS_ADMIN_LIST_SET_SORTING,
  payload: {
    field,
    defaultSortDirection,
  } as { field: PatientSortingFields; defaultSortDirection?: SortDirections },
});

export type SetSorting = ReturnType<typeof setSorting>;
