import { getLatinizedFileName } from './filesystem-helper';
import { validatePersonName } from './user';

export function getPicFileName(firstName: string, lastName: string, email: string): string {
  const validationResult = validatePersonName(firstName, lastName, email);
  const userName = validationResult.type === 'fullName' ? `${firstName}-${lastName}` : validationResult.value;

  // 103 = 128 - "prismaCloud-".length - ".auth".length - X,
  // where 128 is the longest file name p2.8 can support
  // and X is a 8-symbol buffer for whatever case,
  // like if user downloads same PIC twice and " (1)" is added to filename
  const latinizedFileName = getLatinizedFileName(userName, 103);
  return `prismaCloud-${latinizedFileName}.auth`;
}
