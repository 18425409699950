import { Middleware } from 'redux';

import { chronometerTick } from '@shared/redux/actions/chronometer-actions';
import { CHRONOMETER_TICK_INTERVAL } from '@shared/constants';

const chronometerMiddleware: Middleware = ({ dispatch }) => {
  window.setInterval(() => {
    dispatch(chronometerTick());
  }, CHRONOMETER_TICK_INTERVAL * 1000);

  return (next) => (action) => {
    next(action);
  };
};

export default chronometerMiddleware;
