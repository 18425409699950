import { combineReducers, Reducer } from 'redux';

import { AllActions } from '@shared/redux/actions';
import * as PatientActionTypes from '@shared/redux/actions/patientActions/patient-actions';
import { PatientActions } from '@shared/redux/actions/patientActions/patient-actions';
import {
  SetActivePatientListTabPayload,
  TelemonitoringChangePatientsSortingPayload,
} from '@shared/redux/actions/payloadTypes/patientActionPayloads';
import { ActionWithPayload } from '@shared/redux/actions/typings';
import * as UserActionTypes from '@shared/redux/actions/users-actions';
import {
  ExpandedPatientRowKeyState,
  FiltersState,
  PatientsRootState,
  SearchQueryState,
  SortState,
  TabsState,
  TelemonitoringState,
} from '@shared/redux/state/patients';
import { initPatientsRootState } from '@shared/redux/reducers/initState/initPatientsRootState';
import { adminList } from './patients-admin-list-reducer';
import * as PatientsTherapyFiltersActions from '@shared/redux/actions/patientActions/patients-therapy-filters-actions';
import { setDefaultValueForFiltersByKey } from './common';
import { SharingStatusFilters } from '@shared/constants/filters';
import { SortDirections } from '@shared/constants/SortDirections';
import { PatientListTabIds } from '@shared/types/patientListTypes';

function tabs(state: TabsState = initPatientsRootState.tabs, action: PatientActions): TabsState {
  switch (action.type) {
    case PatientActionTypes.SET_ACTIVE_PATIENT_LIST_TAB: {
      const { tabId }: SetActivePatientListTabPayload = action.payload;
      return { activeTab: tabId };
    }
  }

  return state;
}

function searchQuery(
  state: SearchQueryState = initPatientsRootState.searchQuery,
  action: PatientActions,
): SearchQueryState {
  switch (action.type) {
    case PatientActionTypes.SET_PATIENTS_SEARCH_QUERY: {
      return action.payload.searchQuery;
    }
  }
  return state;
}

function expandedPatientId_telemonitoring(
  state: ExpandedPatientRowKeyState = initPatientsRootState.telemonitoring.expandedPatientRowKey,
  action: PatientActions,
): ExpandedPatientRowKeyState {
  switch (action.type) {
    case PatientActionTypes.TELEMONITORING_TOGGLE_PATIENT_EXPAND: {
      const payload = action.payload;
      const { patientRowKey } = payload;

      return state === patientRowKey ? null : patientRowKey;
    }
  }

  return state;
}

function appliedFilters(state: FiltersState = initPatientsRootState.filters, action: ActionWithPayload): FiltersState {
  switch (action.type) {
    case PatientsTherapyFiltersActions.THERAPY_SET_FILTER:
      return {
        ...state,
        [action.payload.filterKey]: action.payload.filterValue,
      };
    case PatientsTherapyFiltersActions.THERAPY_CHANGE_SHARING_STATUS:
      return {
        ...state,
        sharedByMeToTenant: '',
        sharedToMeFromTenant: '',
        sharingStatusFilter: action.payload.filterValue,
      };
    case PatientsTherapyFiltersActions.THERAPY_CHANGE_SHARED_TO_ME:
      return {
        ...state,
        sharedByMeToTenant: '',
        sharingStatusFilter: SharingStatusFilters.Empty,
        sharedToMeFromTenant: action.payload.filterValue,
      };
    case PatientsTherapyFiltersActions.THERAPY_SHARE_TO_TENANT:
      return {
        ...state,
        sharedToMeFromTenant: '',
        sharingStatusFilter: SharingStatusFilters.Empty,
        sharedByMeToTenant: action.payload.filterValue,
      };
    case PatientsTherapyFiltersActions.THERAPY_SELECT_ALL:
      return {
        ...state,
        [action.payload.filterKey]: action.payload.filterValue,
      };
    case PatientsTherapyFiltersActions.THERAPY_SELECT_NONE:
      return {
        ...state,
        [action.payload.filterKey]: [],
      };
    case PatientsTherapyFiltersActions.THERAPY_SET_FILTERS_STATE:
      return {
        ...state,
        ...action.payload,
      };
    case PatientsTherapyFiltersActions.THERAPY_REMOVE_FILTER:
      return setDefaultValueForFiltersByKey(action.payload.filterKey, state);
    case PatientsTherapyFiltersActions.THERAPY_CLEAR_FILTERS:
      return initPatientsRootState.filters;
    case PatientsTherapyFiltersActions.THERAPY_CHANGE_USERS_IN_CHARGE:
      const filterValue = action.payload.filterValue;
      const valueToSet = state.usersInCharge.includes(filterValue)
        ? state.usersInCharge.filter((user) => user !== filterValue)
        : [...state.usersInCharge, filterValue];

      return {
        ...state,
        usersInCharge: valueToSet,
      };

    case UserActionTypes.USER_DELETE_SUCCESS:
      return {
        ...state,
        usersInCharge: state.usersInCharge.filter((i) => i !== action.payload.userId),
      };
  }
  return state;
}

function sort_telemonitoring(
  state: SortState = initPatientsRootState.telemonitoring.sort,
  action: ActionWithPayload<TelemonitoringChangePatientsSortingPayload>,
): SortState {
  const { type, payload } = action;

  if (type === PatientActionTypes.TELEMONITORING_CHANGE_PATIENTS_SORTING) {
    if (state.field === payload.field) {
      return {
        direction: state.direction === SortDirections.Descending ? SortDirections.Ascending : SortDirections.Descending,
        field: state.field,
      };
    } else {
      return {
        direction: !payload.defaultSortDirection ? SortDirections.Descending : payload.defaultSortDirection,
        field: payload.field,
      };
    }
  }
  return state;
}

function expandedPatientId_niv(
  state: ExpandedPatientRowKeyState = initPatientsRootState.ventilationTelemonitoring.expandedPatientRowKey,
  action: PatientActions,
): ExpandedPatientRowKeyState {
  switch (action.type) {
    case PatientActionTypes.NIV_SET_EXPANDED_PATIENT_ID: {
      return action.payload;
    }
  }

  return state;
}

function sort_niv(
  state: SortState = initPatientsRootState.ventilationTelemonitoring.sort,
  action: ActionWithPayload<SortState>,
): SortState {
  const { type, payload } = action;

  if (type === PatientActionTypes.VENTILATION_CHANGE_PATIENTS_SORTING) {
    return {
      field: payload.field,
      direction: payload.direction,
    };
  }
  return state;
}

function scheduledPatientsExpanded_telemonitoring(
  state: boolean = initPatientsRootState.telemonitoring.scheduledPatientsExpanded,
  action: AllActions,
): boolean {
  switch (action.type) {
    case PatientActionTypes.TOGGLE_SCHEDULED_PATIENTS: {
      if (action.payload.activeTab === PatientListTabIds.SdbTelemonitoring) {
        return action.payload.expanded;
      }
      return state;
    }

    default:
      return state;
  }
}

function scheduledPatientsExpanded_niv(
  state: boolean = initPatientsRootState.telemonitoring.scheduledPatientsExpanded,
  action: AllActions,
): boolean {
  switch (action.type) {
    case PatientActionTypes.TOGGLE_SCHEDULED_PATIENTS: {
      if (action.payload.activeTab === PatientListTabIds.NivTelemonitoring) {
        return action.payload.expanded;
      }
      return state;
    }

    default:
      return state;
  }
}

const telemonitoring: Reducer<TelemonitoringState> = combineReducers({
  sort: sort_telemonitoring,
  expandedPatientRowKey: expandedPatientId_telemonitoring,
  scheduledPatientsExpanded: scheduledPatientsExpanded_telemonitoring,
});

const ventilationTelemonitoring: Reducer<TelemonitoringState> = combineReducers({
  sort: sort_niv,
  expandedPatientRowKey: expandedPatientId_niv,
  scheduledPatientsExpanded: scheduledPatientsExpanded_niv,
});

export const patients: Reducer<PatientsRootState> = combineReducers({
  tabs,
  searchQuery,
  telemonitoring,
  ventilationTelemonitoring,
  filters: appliedFilters,
  adminList,
});
