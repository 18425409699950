import i18next from 'i18next';
import { destroy, SubmissionError } from 'redux-form';
import { call, put } from 'redux-saga/effects';

import * as AuthActions from '@shared/redux/actions/auth-actions';
import {
  LoginRequestPayload,
  SecondFactorLoginRequestPayload,
} from '@shared/redux/actions/payloadTypes/authActionPayloads';
import { ActionWithPayload } from '@shared/redux/actions/typings';
import { AuthData, TokenApi } from '@shared/api/auth-api';
import { LOGIN_FORM } from '@shared/constants/reduxFormNames';
import { handleErrorResponse } from '@shared/util/server-error-response-helper';
import { AuthErrorResponses } from '@shared/constants';
import { timeSpanToDuration } from '@shared/util/dateUtils';
import { handleSagaError } from '@shared/util/handleSagaError';
import { reportError } from '@shared/util/sentry';

const isSpecifiedErrorExists = (error: any, description: string) => {
  return error && error.error_description === description;
};

export function* fetchLoginRequest(action: ActionWithPayload<LoginRequestPayload>) {
  const { userName, password, resendVerificationCode } = action.payload;

  try {
    if (!userName || !password) {
      yield put(AuthActions.rejectLogin());
    } else {
      const response: AuthData = yield call(TokenApi.token, userName, password);
      yield put(AuthActions.login.success({ type: 'success', authData: response, userName }));
      yield put(destroy(LOGIN_FORM));
    }
  } catch (e) {
    const error = handleErrorResponse(e);
    if (isSpecifiedErrorExists(error, AuthErrorResponses.secondFactorRequired)) {
      const secondFactorRequiredAction = resendVerificationCode
        ? {
            ...AuthActions.secondFactorRequired({ phone: error.phone }),
            successNotification: { resourceKey: 'Notification_SmsIsSent' },
          }
        : AuthActions.secondFactorRequired({ phone: error.phone });
      yield put(AuthActions.login.success({ type: 'secondFactorRequired' }));
      yield put(secondFactorRequiredAction);
    } else if (isSpecifiedErrorExists(error, AuthErrorResponses.loginIsLocked)) {
      const lockDuration = timeSpanToDuration(error.remainingLockDuration)?.humanize();
      yield put(
        AuthActions.login.failure(
          new SubmissionError({
            _error: `${i18next.t('Login_CredentialsLocked')} ${lockDuration}`,
          }),
        ),
      );
    } else if (isSpecifiedErrorExists(error, AuthErrorResponses.invalidUserOrPassword)) {
      yield put(
        AuthActions.login.failure(
          new SubmissionError({
            _error: i18next.t('Login_InvalidUserOrPassword'),
          }),
        ),
      );
    } else {
      yield handleSagaError({
        type: AuthActions.login.FAILURE,
        error: e,
      });
    }
  }
}

export function* fetchSecondFactorLoginRequest(action: ActionWithPayload<SecondFactorLoginRequestPayload>) {
  const { userName, password, trustedBrowser, verificationCode } = action.payload;

  try {
    const response: AuthData = yield call(
      TokenApi.tokenWithVerificationCode,
      userName,
      password,
      verificationCode,
      trustedBrowser,
    );

    yield put(AuthActions.twoFactorLogin.success({ type: 'success', authData: response, userName }));
    yield put(destroy(LOGIN_FORM));
  } catch (e) {
    const error = handleErrorResponse(e);
    if (isSpecifiedErrorExists(error, AuthErrorResponses.secondFactorFailed)) {
      if (!userName || !password) {
        // It can be a bug in redux-form that is rarely reproduced
        // username and password become undefined.
        // show error and redirect to login page instead of getting stuck in verification code form.
        yield put(AuthActions.secondFactorRejected());
        yield put(
          AuthActions.login.failure(
            new SubmissionError({
              _error: i18next.t('Login_InvalidUserOrPassword'),
            }),
          ),
        );
      } else {
        yield put(
          AuthActions.twoFactorLogin.failure(
            new SubmissionError({
              verificationCode: i18next.t('Login_VerificationCode_Invalid'),
            }),
          ),
        );
      }
    } else if (isSpecifiedErrorExists(error, AuthErrorResponses.loginIsLocked)) {
      yield put(AuthActions.secondFactorRejected());
      const lockDuration = timeSpanToDuration(error.remainingLockDuration)?.humanize();
      yield put(
        AuthActions.login.failure(
          new SubmissionError({
            _error: `${i18next.t('Login_CredentialsLocked')} ${lockDuration}`,
          }),
        ),
      );
    } else {
      yield handleSagaError({
        ...AuthActions.secondFactorRejected(),
        error: e,
      });
      // HACK that redux-form will handle this failure action and reset the form.
      // Otherwise UnhandledRejection will be thrown and sent to Sentry.
      yield put(AuthActions.login.failure(new SubmissionError({})));

      if (!userName || !password) {
        reportError(new Error('It was an attempt to login without username or password.'));
      }
    }
  }
}
