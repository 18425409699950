import { all } from 'redux-saga/effects';

import * as PhoneVerificationActions from '@shared/redux/actions/phoneVerification-actions';
import * as PhoneVerificationSaga from '@shared/redux/sagas/phoneVerification-saga';

function* watchSendVerificationSms() {
  yield PhoneVerificationActions.sendVerificationSms.watch(PhoneVerificationSaga.fetchSendVerificationSms);
}

function* watchVerifyCode() {
  yield PhoneVerificationActions.verifyCode.watch(PhoneVerificationSaga.fetchVerifyCode);
}

export default function* watchPhoneVerificationActions() {
  yield all([watchSendVerificationSms(), watchVerifyCode()]);
}
