import * as SdCardUploadActionTypes from '@shared/redux/actions/sdCardUpload-actions';
import { getInitRootState } from './initState/rootState';
import {
  CancelFileUploadSuccessAction,
  FetchSupportedFileExtensionsSuccessAction,
  UploadFileFailureAction,
  UploadFileProgressAction,
  UploadFileRequestAction,
  UploadFileStartedAction,
  UploadFileSuccessAction,
} from '@shared/redux/actions/sdCardUpload-actions';
import { DeviceGeneration } from '@shared/api/dto/DeviceGeneration';

export interface SdCardFile extends File {
  id: number;
  name: string;
  uploadProgress?: number;
  serialNumber?: string;
  removedDaysBelowLimit: boolean;
  removedDaysAboveLimit: boolean;
  errorResourceKey?: string;
  cancellationCallback?: () => void;
}

export interface SdCardUploadState {
  uploadingInProgress: boolean;
  supportedFileExtensions: string[] | null;
  filesToUpload: SdCardFile[];
  lastUsedFileId: number;
  pendingUploadTask: PendingSdCardUploadTask | null;
}

export interface PendingSdCardUploadTask {
  id: number;
  deviceGeneration: DeviceGeneration;
  file: SdCardFile;
}

export function sdCardUpload(
  state: SdCardUploadState = getInitRootState().sdCardUpload,
  action:
    | UploadFileRequestAction
    | CancelFileUploadSuccessAction
    | UploadFileStartedAction
    | UploadFileSuccessAction
    | UploadFileFailureAction
    | UploadFileProgressAction
    | FetchSupportedFileExtensionsSuccessAction,
): SdCardUploadState {
  switch (action.type) {
    case SdCardUploadActionTypes.UPLOAD_FILE_REQUEST: {
      return {
        ...state,
        uploadingInProgress: true,
        lastUsedFileId: state.lastUsedFileId + 1,
        filesToUpload: [
          {
            ...action.payload.file,
            id: state.lastUsedFileId + 1,
            name: action.payload.file.name,
            uploadProgress: 0,
            serialNumber: undefined,
            removedDaysAboveLimit: false,
            removedDaysBelowLimit: false,
            errorResourceKey: undefined,
            cancellationCallback: undefined,
          },
          ...state.filesToUpload,
        ],
      };
    }
    case SdCardUploadActionTypes.CANCEL_FILE_UPLOAD_SUCCESS: {
      const files = state.filesToUpload;
      const filesToStop: SdCardFile[] = state.filesToUpload.filter(
        (file) =>
          file.serialNumber === undefined &&
          file.errorResourceKey === undefined &&
          file.uploadProgress !== 0 &&
          file.uploadProgress !== 100,
      );

      for (const file of filesToStop) {
        const index = files.indexOf(file);
        files.splice(index, 1);
      }

      return {
        ...state,
        uploadingInProgress: false,
        filesToUpload: [...files],
      };
    }
    case SdCardUploadActionTypes.UPLOAD_FILE_STARTED: {
      const { cancellationCallback, id } = action.payload;

      const files = state.filesToUpload;
      const elementIndex = getFileIndexById(files, id);
      const newElement = {
        ...files[elementIndex],
        cancellationCallback,
      };
      files.splice(elementIndex, 1, newElement);

      return {
        ...state,
        uploadingInProgress: true,
        filesToUpload: [...files],
      };
    }
    case SdCardUploadActionTypes.UPLOAD_FILE_SUCCESS: {
      const { uploadInfo, id } = action.payload;

      const files = state.filesToUpload;
      const elementIndex = getFileIndexById(files, id);
      const newElement: SdCardFile = {
        ...files[elementIndex],
        uploadProgress: 100,
        ...uploadInfo,
        serialNumber: uploadInfo.deviceSerialNumber,
      };
      files.splice(elementIndex, 1, newElement);

      return {
        ...state,
        uploadingInProgress: false,
        filesToUpload: [...files],
      };
    }
    case SdCardUploadActionTypes.UPLOAD_FILE_FAILURE: {
      const { id } = action.payload;

      const files = state.filesToUpload;
      const elementIndex = getFileIndexById(files, id);
      const newElement = {
        ...files[elementIndex],
        errorResourceKey: action.payload.resourceKey,
      };
      files.splice(elementIndex, 1, newElement);

      return {
        ...state,
        uploadingInProgress: false,
        filesToUpload: [...files],
      };
    }
    case SdCardUploadActionTypes.UPLOAD_FILE_PROGRESS: {
      const files = setProgressToFile(state.filesToUpload, action.payload.id, action.payload.progress);

      return {
        ...state,
        filesToUpload: files,
      };
    }
    case SdCardUploadActionTypes.FETCH_SUPPORTED_FILE_EXTENSIONS_SUCCESS: {
      return {
        ...state,
        supportedFileExtensions: action.payload.supportedFileExtensions,
      };
    }
  }
  return state;
}

function getFileIndexById(files: any[], id: number): number {
  const element = files.filter((x) => x.id === id)[0];
  return files.indexOf(element);
}

function setProgressToFile(files: SdCardFile[], id: number, progress: number): SdCardFile[] {
  const elementIndex = getFileIndexById(files, id);
  const newElement = {
    ...files[elementIndex],
    uploadProgress: progress,
  };
  files.splice(elementIndex, 1, newElement);
  return [...files];
}

export default sdCardUpload;
