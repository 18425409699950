import { initOrganisationsRootState } from '../reducers/initState/initOrganisationRootState';
import { FiltersState } from '../state/organisations';
import { CurrentOrganisationInfoDto } from '@shared/api/core-api/organisation-api';
import { SupportedCountries } from '@shared/constants/countries';
import { FilterValue } from '@shared/types/filteringTypes';
import { createFormAction } from '@shared/util/createFormAction';
import { OrganisationFilterValue } from '@shared/util/filters/organisation-filters-helper';

export const getCurrentOrganisation = createFormAction('GET_CURRENT_ORGANISATION')<void, CurrentOrganisationInfoDto>();
export const SET_ORGANISATION_ROLES = 'SET_ORGANISATION_ROLES';

export const ORGANISATION_SET_FILTER = 'ORGANISATION_SET_FILTER';
export const ORGANISATION_CLEAR_FILTERS = 'ORGANISATION_CLEAR_FILTERS';
export const ORGANISATION_SELECT_ALL = 'ORGANISATION_SELECT_ALL';
export const ORGANISATION_SELECT_NONE = 'ORGANISATION_SELECT_NONE';
export const ORGANISATION_REMOVE_FILTER = 'ORGANISATION_REMOVE_FILTER';
export const ORGANISATION_CHANGE_COUNTRY = 'ORGANISATION_CHANGE_COUNTRY';

export const setFilter = <K extends keyof FiltersState>(filterKey: K, filterValue: FilterValue<FiltersState[K]>) =>
  ({
    type: ORGANISATION_SET_FILTER,
    payload: {
      filterKey,
      filterValue,
    },
  } as const);

export const changeCountry = (filterValue: SupportedCountries) =>
  ({
    type: ORGANISATION_CHANGE_COUNTRY,
    payload: {
      filterValue: filterValue,
    },
  } as const);

export const clearFilters = () =>
  ({
    type: ORGANISATION_CLEAR_FILTERS,
    payload: initOrganisationsRootState.filters,
  } as const);

export const selectAll = (filterKey: keyof FiltersState, options: OrganisationFilterValue[]) =>
  ({
    type: ORGANISATION_SELECT_ALL,
    payload: {
      filterKey,
      filterValue: options.filter((opt) => opt != null),
    },
  } as const);

export const selectNone = (filterKey: keyof FiltersState) =>
  ({
    type: ORGANISATION_SELECT_NONE,
    payload: {
      filterKey,
      filterValue: [],
    },
  } as const);

export const removeFilter = (filterKey: keyof FiltersState) =>
  ({
    type: ORGANISATION_REMOVE_FILTER,
    payload: { filterKey },
  } as const);

export type GetCurrentOrganisationSuccessAction = ReturnType<typeof getCurrentOrganisation.success>;

export type OrganisationActions =
  | ReturnType<typeof getCurrentOrganisation.request>
  | GetCurrentOrganisationSuccessAction
  | ReturnType<typeof getCurrentOrganisation.failure>
  | ReturnType<typeof setFilter>
  | ReturnType<typeof changeCountry>
  | ReturnType<typeof clearFilters>
  | ReturnType<typeof selectAll>
  | ReturnType<typeof selectNone>
  | ReturnType<typeof removeFilter>;
