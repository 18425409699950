import { PatientListTabIds } from '@shared/types/patientListTypes';

const storage = window.localStorage;

export const keys = {
  authCodeState: 'authCodeState',
  authCodeVerifier: 'authCodeVerifier',
  lastActionTime: 'lastTimeActive',
  userName: 'userName',
  accessToken: 'accessToken',
  refreshToken: 'refreshToken',
  authTimeLocal: 'authTimeLocal',
  refreshAuthData: 'refreshAuthData',
  culture: 'culture',
  email: 'email',
  activeMonitoringTabId: 'activeMonitoringTabId',
  hiddenMaintenanceNotificationIds: 'hiddenMaintenanceNotificationIds',
  hiddenExternalNotificationIds: 'hiddenExternalNotificationIds',
  delayedExternalNotifications: 'delayedExternalNotifications',
} as const;

export const getStorageItem = <T>(key: keyof typeof keys) => {
  const content = storage.getItem(key);
  return content ? (JSON.parse(content) as T) : null;
};

export const getAuthCodeState = () => storage.getItem(keys.authCodeState);
export const getAuthCodeVerifier = () => storage.getItem(keys.authCodeVerifier);
export const getName = (): string | null => storage.getItem(keys.userName);
export const getToken = (): string | null => storage.getItem(keys.accessToken);
export const getCulture = (): string | null => storage.getItem(keys.culture);
export const getRefreshToken = (): string | null => storage.getItem(keys.refreshToken);
export const getLastActive = (): string | null => storage.getItem(keys.lastActionTime);
export const getAuthTimeLocal = (): string | null => storage.getItem(keys.authTimeLocal);
export const getActiveMonitoringTabId = (): PatientListTabIds | null =>
  storage.getItem(keys.activeMonitoringTabId) as PatientListTabIds;
export const getHiddenMaintenanceNotificationIds = (): string[] | null =>
  storage.getItem(keys.hiddenMaintenanceNotificationIds)?.split(',') ?? null;

export type DelayedExternalNotification = { id: string; delayedUntilMsTimestamp: number };
export const getDelayedExternalNotifications = (): DelayedExternalNotification[] | null => {
  const items = storage.getItem(keys.delayedExternalNotifications);
  return items ? JSON.parse(items) : null;
};
export const getHiddenExternalNotificationIds = (): string[] | null => {
  const ids = storage.getItem(keys.hiddenExternalNotificationIds);
  return ids ? JSON.parse(ids) : null;
};

export const subscribeAuthTimeLocalChanged = (callback: () => void) => {
  window.addEventListener('storage', (event) => {
    if (keys.authTimeLocal === event.key) {
      callback();
    }
  });
};

export const clearUserState = (): void => {
  storage.removeItem(keys.userName);
  storage.removeItem(keys.accessToken);
  storage.removeItem(keys.refreshToken);
  storage.removeItem(keys.lastActionTime);
  storage.removeItem(keys.authTimeLocal);
  storage.removeItem(keys.email);
  storage.removeItem(keys.hiddenMaintenanceNotificationIds);
  storage.removeItem(keys.authCodeState);
  storage.removeItem(keys.authCodeVerifier);
};

export const setStorageItem = <T>(key: keyof typeof keys, value: T) => storage.setItem(key, JSON.stringify(value));

export const setUserState = (userName: string, accessToken: string, refreshToken: string): void => {
  storage.setItem(keys.userName, userName);
  storage.setItem(keys.accessToken, accessToken);
  storage.setItem(keys.refreshToken, refreshToken);
  storage.removeItem(keys.authCodeState);
  storage.removeItem(keys.authCodeVerifier);
};

export const setAuthenticationState = (state: string, codeVerifier: string) => {
  storage.setItem(keys.authCodeState, state);
  storage.setItem(keys.authCodeVerifier, codeVerifier);
};

export const setAuthTimeLocal = (time: Date): void => storage.setItem(keys.authTimeLocal, time.toISOString());

export interface CultureStorageObject {
  culture: string;
  dayJSLocale: string;
  isCultureUserDefined: boolean;
}

export const setCulture = (culture: string, dayJSLocale: string, isCultureUserDefined: boolean): void => {
  const object: CultureStorageObject = { culture, isCultureUserDefined, dayJSLocale };
  storage.setItem(keys.culture, JSON.stringify(object));
};

export const setDelayedExternalNotifications = (delayedNotifications: DelayedExternalNotification[]): void =>
  storage.setItem(keys.delayedExternalNotifications, JSON.stringify(delayedNotifications));

export const setActive = (): void => storage.setItem(keys.lastActionTime, Date.now().toString());

export const setActiveMonitoringTabId = (tabId: PatientListTabIds) =>
  storage.setItem(keys.activeMonitoringTabId, tabId);

export const setHiddenMaintenanceNotifications = (ids: string[]) => {
  storage.setItem(keys.hiddenMaintenanceNotificationIds, ids.join());
};

export const setHiddenExternalNotifications = (ids: string[]) =>
  storage.setItem(keys.hiddenExternalNotificationIds, JSON.stringify(ids));
