// tslint:disable:typedef no-submodule-imports no-default-export
import { call, put } from 'redux-saga/effects';

import { OrganisationApi } from '@shared/api/core-api';
import { CurrentOrganisationInfoDto } from '@shared/api/core-api/organisation-api';
import * as OrganisationActionTypes from '@shared/redux/actions/organisation-actions';

export function* fetchGetCurrentOrganisation() {
  try {
    const currentOrganisation: CurrentOrganisationInfoDto = yield call(OrganisationApi.getCurrent);
    yield put(OrganisationActionTypes.getCurrentOrganisation.success(currentOrganisation));
  } catch (e) {
    yield put(OrganisationActionTypes.getCurrentOrganisation.failure(e));
  }
}
