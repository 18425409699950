import { put, PutEffect } from 'redux-saga/effects';
import { SubmissionError } from 'redux-form';
import { Action } from 'redux';

import { IErrorAction, IErrorActionError } from '@shared/redux/sagas/watchFunctions/watchNotificationActions';
import { ActionWithPayload } from '@shared/redux/actions/typings';
import { getToken } from './localStorageAccessor';
import { HttpStatusCodes } from '@shared/constants/httpStatusCodes';

type ExpectedErrorAction<T> = ActionWithPayload<Error, T>;

function isError(error: any): error is Error {
  if (error && error.message && error.stack) {
    return true;
  }

  return false;
}

/**
 * Process exception occurred in saga. It automatically puts failedAction if it
 * has form related error or sends common error.
 * @param error Ocurred error from catch payload.
 * @param formError Processed error acceptable by form.
 * @param failedAction Put action for formError.
 */
export function handleReactFormSagaError<T>(value: {
  error: any;
  formError: SubmissionError<T>;
  failedAction: ActionWithPayload<any, any>;
}): PutEffect<IErrorAction | ExpectedErrorAction<any>> {
  const { error, formError, failedAction } = value;
  if (formError.errors) {
    return put(failedAction);
  } else {
    return handleSagaError({
      type: failedAction.type,
      error,
    });
  }
}

/**
 * Process exception of saga. It standardized handling of exception occurred in it.
 * @param type action type.
 * @param error Ocurred error from catch payload.
 * @param payload Additional payload that will be added.
 * @param errorResourceKey Resource key that must be used in error render.
 */
export function handleSagaError<TType extends string = ''>(
  value: Action<TType> & {
    error: any;
    errorResourceKey?: string;
  },
): PutEffect<IErrorAction | ExpectedErrorAction<typeof value.type>>;
export function handleSagaError<TActionWithPayload extends ActionWithPayload>(
  value: TActionWithPayload & {
    error: any;
    errorResourceKey?: string;
  },
): PutEffect<IErrorAction | ExpectedErrorAction<typeof value.type>>;
export function handleSagaError<TPayload = any, TType extends string = ''>(value: {
  type: TType;
  error: any;
  payload?: TPayload;
  errorResourceKey?: string;
}): PutEffect<IErrorAction | ExpectedErrorAction<typeof value.type>> {
  const { error } = value;
  let { errorResourceKey } = value;

  if (!errorResourceKey) {
    if (error && error.resourceKey && typeof error.resourceKey === 'string') {
      errorResourceKey = error.resourceKey;
    }

    if (error && error.error && error.error.resourceKey && typeof error.error.resourceKey === 'string') {
      errorResourceKey = error.error.resourceKey;
    }
  }

  let payloadError: Error;
  if (!isError(error)) {
    payloadError = new Error(`Unknown error has occurred: ${JSON.stringify(error, Object.getOwnPropertyNames(error))}`);
    // spread operator doesn't work with Error object, but it can be
    // extended with Object.assign if Error is first argument.
    Object.assign(payloadError, error);
  } else {
    payloadError = error;
  }

  if (value.payload) {
    Object.assign(payloadError, value.payload);
  }

  const errorActionError: IErrorActionError = {};
  if (errorResourceKey) {
    errorActionError.resourceKey = errorResourceKey;
    // now we treat defined resource key as expected error.
    errorActionError.expected = true;
  }

  if (error?.response?.status === HttpStatusCodes.Unauthorized && getToken() == null) {
    // Seems like UI did request to API after logout, complete saga but
    // do not show user an error (no error field in action).
    const expectedErrorAction: ActionWithPayload<Error, typeof value.type> = {
      type: value.type,
      payload: payloadError,
    };

    return put(expectedErrorAction);
  }

  const errorAction: IErrorAction<typeof value.type> = {
    type: value.type,
    payload: payloadError,
    error: errorActionError,
  };

  return put(errorAction);
}
