import { initSleepMonitoringState } from './initState/initSleepMonitoringState';
import { AllActions } from '@shared/redux/actions';
import * as ReportActionTypes from '@shared/redux/actions/report-actions';
import { SleepMonitoringState } from '@shared/redux/state/sleepMonitoring';

export default function sleepMonitoring(
  state: SleepMonitoringState = initSleepMonitoringState,
  action: AllActions,
): SleepMonitoringState {
  switch (action.type) {
    case ReportActionTypes.GENERATE_REPORT_POPUP_OPEN: {
      return {
        ...state,
        reportGeneratorPopupOpen: true,
      };
    }

    case ReportActionTypes.GENERATE_REPORT_POPUP_CLOSE: {
      return { ...state, reportGeneratorPopupOpen: false };
    }
  }

  return state;
}
