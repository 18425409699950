import * as Sentry from '@sentry/browser';
import React from 'react';

import { GetKibanaUrl } from '@shared/config/feature-flags';
import { GetKibanaIndexUnid } from '@shared/config/feature-flags';
import { getAuthTimeLocal, getLastActive } from './localStorageAccessor';
import { getDecodedToken } from './user';
import { HttpStatusCodes } from '@shared/constants/httpStatusCodes';
import { getSessionId } from './session-helper';
import { getTenant } from './tenant';
import { isAxiosError } from '@shared/api/axios-extensions';

const createKibanaUrl = (sessionId: string, traceId?: string): string => {
  const KIBANA_URL = GetKibanaUrl();
  const KIBANA_INDEX_UNID = GetKibanaIndexUnid();
  let query =
    `${KIBANA_URL}?_g=(time:(from:now-1y,to:now))` +
    `&_a=(columns:!(message),index:'${KIBANA_INDEX_UNID}',` +
    `&_a=(discover:(columns:!(message),sort:!('@timestamp',desc)),metadata:(indexPattern:'${KIBANA_INDEX_UNID}',view:discover))` +
    `&_q=(filters:!(),query:(language:kuery,query:'SessionId:"${sessionId}"'))`;
  if (traceId) {
    query += `%20AND%20TraceId:"${traceId}"`;
  }

  return query + "'),sort:!('@timestamp',desc))";
};

export const reportError = (error: unknown): void => {
  Sentry.withScope(() => {
    let traceId: string | undefined = undefined;

    if (isAxiosError(error)) {
      traceId = error?.response?.headers['x-correlation-id'];

      if (error.response?.status) {
        Sentry.setTag('response.statusCode', error.response.status.toString());
      }
      if (error.config?.method) {
        Sentry.setTag('request.method', error.config.method.toString());
      }
      if (error.response?.status === HttpStatusCodes.Unauthorized) {
        try {
          // Put additional data if receive request with status 401 to find why it has happened.
          const lastActiveTime = getLastActive();
          const authTimeLocal = getAuthTimeLocal();
          const decodedToken = getDecodedToken();
          const exp = decodedToken?.exp;
          const nbf = decodedToken?.nbf;
          // It is impossible to use "auth" as part of the key in Sentry,
          // it is identified as PII data and filtered.
          if (lastActiveTime) {
            Sentry.setExtra('details.lastActiveTime', new Date(parseInt(lastActiveTime)));
          }
          if (authTimeLocal) {
            Sentry.setExtra('details.loginUserTime', new Date(authTimeLocal));
          }
          if (exp) {
            Sentry.setExtra('details.token.exp', new Date(exp * 1000));
          }
          if (nbf) {
            Sentry.setExtra('details.token.nbf', new Date(nbf * 1000));
          }
        } catch {
          // Attempt to extract extra data has failed but we will continue to work.
        }
      }

      let url = '';
      if (error.config?.baseURL) {
        url = error.config.baseURL;
      }
      if (error.config?.url) {
        if (error.config.url.startsWith(url)) {
          url = error.config.url;
        } else {
          url += error.config.url;
        }
      }

      try {
        const loc = new URL(url);
        if (loc) {
          if (loc.pathname) {
            Sentry.setTag('request.urlPath', loc.pathname);
          }
          if (loc.hostname) {
            Sentry.setTag('request.urlHostname', loc.hostname);
          }
          if (loc.search) {
            Sentry.setTag('request.urlSearch', loc.search);
          }
        }
      } catch {
        // Attempt to extract valid URL from error has failed,
        // but we will continue to generate report.
      }
    }

    if (GetKibanaUrl()) {
      const kibanaUrl = createKibanaUrl(getSessionId(), traceId);
      Sentry.setExtra('Kibana URL', kibanaUrl);
    }

    if (traceId !== null) {
      Sentry.setExtra('traceId', traceId);
    }

    const tenant = getTenant();
    if (tenant !== null) {
      Sentry.setTag('tenant', tenant);
    }

    Sentry.setExtra('Error content', error);
    Sentry.captureException(error);
  });
};
export const reportMessage = (message: string): void => {
  Sentry.captureMessage(message);
};

export class ErrorBoundary extends React.Component<{ children?: React.ReactNode }> {
  componentDidCatch(error: Error, info: React.ErrorInfo) {
    reportError(error);
  }

  render() {
    return this.props.children;
  }
}
