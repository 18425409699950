import {
  DeviceAssignmentFilters,
  PatientNoteFilters,
  SharingStatusFilters,
  TeleSettingsFilters,
} from '@shared/constants/filters';
import { PatientFilterCategory } from '@shared/constants/PatientFilterCategory';
import { SortDirections } from '@shared/constants/SortDirections';
import { PatientSortingFields } from '@shared/constants/sortingFields';
import { PatientsRootState } from '@shared/redux/state/patients';
import { PatientListTabIds } from '@shared/types/patientListTypes';

export const initPatientsRootState: PatientsRootState = {
  tabs: {
    activeTab: PatientListTabIds.SdbTelemonitoring,
  },
  searchQuery: '',
  telemonitoring: {
    sort: {
      field: PatientSortingFields.OverallRelevance,
      direction: SortDirections.Descending,
    },
    expandedPatientRowKey: null,
    scheduledPatientsExpanded: true,
  },
  ventilationTelemonitoring: {
    sort: {
      field: PatientSortingFields.OverallRelevance,
      direction: SortDirections.Descending,
    },
    expandedPatientRowKey: null,
    scheduledPatientsExpanded: true,
  },
  adminList: {
    scheduledPatientsExpanded: true,
    filters: {
      usersInCharge: [],
      deviceAssignmentFilter: DeviceAssignmentFilters.Empty,
      patientNoteFilter: PatientNoteFilters.Empty,
      teleSettingsFilter: TeleSettingsFilters.Empty,
      sharingStatusFilter: SharingStatusFilters.Empty,
      sharedByMeToTenant: '',
      sharedToMeFromTenant: '',
      medicalConditionId: null,
      patientCategory: PatientFilterCategory.All,
      prismaAppUsageFilter: 'Empty',
      questionnaireStatus: 'All',
      questionnaireType: 'All',
    },
    searchQuery: '',
    sort: {
      field: PatientSortingFields.Name,
      direction: SortDirections.Ascending,
    },
  },
  filters: {
    usersInCharge: [],
    deviceAssignmentFilter: DeviceAssignmentFilters.Empty,
    patientNoteFilter: PatientNoteFilters.Empty,
    teleSettingsFilter: TeleSettingsFilters.Empty,
    sharingStatusFilter: SharingStatusFilters.Empty,
    sharedByMeToTenant: '',
    sharedToMeFromTenant: '',
    medicalConditionId: null,
    patientCategory: PatientFilterCategory.All,
    prismaAppUsageFilter: 'Empty',
    questionnaireType: 'All',
    questionnaireStatus: 'All',
  },
};
