import { UAParser } from 'ua-parser-js';

export type BrowserSupport = 'supported' | 'not recommended' | 'user allowed' | 'not supported';

const storage = window.sessionStorage;
// If user selected to continue to work with unsupported browser
// We store it in session to do not show that browser not supported after user refreshed the page.
const continueToWorkWithBrowser = 'continueToWorkWithBrowser';

const BLINK = 'Blink';
const GECKO = 'Gecko';
const TRIDENT = 'Trident';
const EDGE_HTML = 'EdgeHTML';
const WEB_KIT = 'WebKit';

// Blink used by Chrome, Opera and Recent Edge (from v.79)
// Gecko used by Firefox
const supportedEngines = [GECKO, BLINK];
// Trident used by IE, EdgeHTML by old Edge (till v.79).
const notSupported = [TRIDENT, EDGE_HTML];

export function getBrowserSupport(): BrowserSupport {
  const parser = new UAParser();
  const engine = parser.getEngine();
  const continueToWork = storage.getItem(continueToWorkWithBrowser);
  if (continueToWork) {
    return 'user allowed';
  }

  if (supportedEngines.includes(engine.name ?? '')) {
    return 'supported';
  }
  if (notSupported.includes(engine.name ?? '')) {
    return 'not supported';
  }

  return 'not recommended';
}

export function enableWorkWithBrowser() {
  storage.setItem(continueToWorkWithBrowser, 'true');
}

export const isBlink = () => {
  const parser = new UAParser();
  const engine = parser.getEngine();
  return engine.name === BLINK;
};
export const isWebKit = () => {
  const parser = new UAParser();
  const engine = parser.getEngine();
  return engine.name == WEB_KIT;
};
