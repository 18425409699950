import { UserRoleTypes } from '@shared/constants/UserRoleTypes';
import enumExtensions from '@shared/util/enum';

export const combineUserRoles = (
  roles: UserRoleTypes[],
  isPrismaLineSleepEditAllowed?: boolean,
  isPrismaLineVentiEditAllowed?: boolean,
  isPrismaSmartEditAllowed?: boolean,
  isPrismaVentEditAllowed?: boolean,
  isTelemonitoringSwitchingAllowed?: boolean,
) => {
  const userRoles = [...roles];

  if (isPrismaSmartEditAllowed) {
    userRoles.push(UserRoleTypes.PrismaSmartConfigurationEditor);
  }
  if (isPrismaLineSleepEditAllowed) {
    userRoles.push(UserRoleTypes.PrismaLineSleepConfigurationEditor);
  }
  if (isPrismaLineVentiEditAllowed) {
    userRoles.push(UserRoleTypes.PrismaLineVentiConfigurationEditor);
  }
  if (isPrismaVentEditAllowed) {
    userRoles.push(UserRoleTypes.PrismaVentConfigurationEditor);
  }
  if (isTelemonitoringSwitchingAllowed) {
    userRoles.push(UserRoleTypes.PrismaSmartTelemonitoringSwitcher);
  }

  return userRoles;
};

export const decomposeUserRolesIntoRolesAndAdditionalPermissions = (
  combinedRoles: UserRoleTypes,
  allowedRoles: UserRoleTypes[],
) => {
  const rolesList = enumExtensions.getAllPresentedFlags(combinedRoles);

  return {
    roles: rolesList
      .filter((r) => allowedRoles.includes(r))
      .filter(
        (r) =>
          r != UserRoleTypes.PrismaSmartConfigurationEditor &&
          r != UserRoleTypes.PrismaLineSleepConfigurationEditor &&
          r != UserRoleTypes.PrismaLineVentiConfigurationEditor &&
          r != UserRoleTypes.PrismaVentConfigurationEditor &&
          r != UserRoleTypes.PrismaSmartTelemonitoringSwitcher,
      ),
    isPrismaSmartEditAllowed: rolesList.includes(UserRoleTypes.PrismaSmartConfigurationEditor),
    isPrismaLineSleepEditAllowed: rolesList.includes(UserRoleTypes.PrismaLineSleepConfigurationEditor),
    isPrismaLineVentiEditAllowed: rolesList.includes(UserRoleTypes.PrismaLineVentiConfigurationEditor),
    isPrismaVentEditAllowed: rolesList.includes(UserRoleTypes.PrismaVentConfigurationEditor),
    isTelemonitoringSwitchingAllowed: rolesList.includes(UserRoleTypes.PrismaSmartTelemonitoringSwitcher),
  };
};

export const onMainRoleChange =
  (props: { changeFieldValue: (fieldName: string, value: boolean) => void }) => (value: UserRoleTypes[]) => {
    // If the PatientCarer role is removed from the user, the edit device configurations
    // roles have to be removed as well.
    if (!value.includes(UserRoleTypes.MedicalProfessional)) {
      props.changeFieldValue('isPrismaSmartEditAllowed', false);
      props.changeFieldValue('isPrismaLineSleepEditAllowed', false);
      props.changeFieldValue('isPrismaLineVentiEditAllowed', false);
      props.changeFieldValue('isPrismaVentEditAllowed', false);
    }
    // If the Technician role is removed from the user, the telemonitoring switching
    // roles have to be removed as well.
    if (!value.includes(UserRoleTypes.Technician)) {
      props.changeFieldValue('isTelemonitoringSwitchingAllowed', false);
    }
  };

export const reduceUserRoles = (roles: UserRoleTypes[]) => {
  return roles.length > 0 ? roles.reduce((prev: any, curr: any) => prev | curr) : UserRoleTypes.None;
};

export const parseUserRoles = (userRoles: string | null): UserRoleTypes => {
  if (userRoles == null) {
    return UserRoleTypes.None;
  }

  type UserRole = keyof typeof UserRoleTypes;
  const roles = userRoles.split(',').map((x) => x.trim()) as UserRole[];
  let result: UserRoleTypes = UserRoleTypes.None;
  for (const role of roles) {
    result |= UserRoleTypes[role];
  }

  return result;
};
