// tslint:disable:typedef no-submodule-imports no-default-export
import { all, ForkEffect, takeEvery, takeLatest } from 'redux-saga/effects';

import * as ReportsSaga from '@shared/redux/sagas/report-saga';
import * as ReportsActionTypes from '@shared/redux/actions/report-actions';

function* watchGenerateReportRequest() {
  yield ReportsActionTypes.GENERATE_REPORT.watch(ReportsSaga.fetchGenerateReport);
}

function* watchGenerateReportPopupFromNivView(): IterableIterator<ForkEffect> {
  yield takeLatest(
    ReportsActionTypes.GENERATE_REPORT_POPUP_FROM_NIV_VIEW,
    ReportsSaga.generateReportPopupFromPatientDetails,
  );
}

function* watchGenerateReportPopupFromPatientDetails(): IterableIterator<ForkEffect> {
  yield takeLatest(
    ReportsActionTypes.GENERATE_REPORT_POPUP_FROM_PATIENT_DETAILS,
    ReportsSaga.generateReportPopupFromPatientDetails,
  );
}

function* watchGenerateReportPopupFromContext(): IterableIterator<ForkEffect> {
  yield takeLatest(ReportsActionTypes.GENERATE_REPORT_POPUP_FROM_CONTEXT, ReportsSaga.generateReportPopupFromContext);
}

function* watchFetchValidDatesForReport() {
  yield ReportsActionTypes.REPORT_VALID_DATES.watch(ReportsSaga.fetchValidDateRangeForPatientReport);
}

function* watchFetchDownloadReport(): IterableIterator<ForkEffect> {
  yield takeEvery(ReportsActionTypes.GENERATE_REPORT.SUCCESS, ReportsSaga.fetchDownloadReport);
}

function* watchFetchNotification(): IterableIterator<ForkEffect> {
  yield takeEvery(ReportsActionTypes.GENERATE_REPORT.REQUEST, ReportsSaga.fetchReportNotification);
}

function* watchHideNotification(): IterableIterator<ForkEffect> {
  yield takeEvery(ReportsActionTypes.DOWNLOAD_REPORT.SUCCESS, ReportsSaga.fetchReportNotificationHide);
  yield takeEvery(ReportsActionTypes.GENERATE_REPORT.FAILURE, ReportsSaga.fetchReportNotificationHide);
  yield takeEvery(ReportsActionTypes.DOWNLOAD_REPORT.FAILURE, ReportsSaga.fetchReportNotificationHide);
}

export default function* watchReportsActions() {
  yield all([
    watchFetchValidDatesForReport(),
    watchGenerateReportPopupFromNivView(),
    watchGenerateReportPopupFromPatientDetails(),
    watchGenerateReportPopupFromContext(),
    watchGenerateReportRequest(),
    watchFetchDownloadReport(),
    watchFetchNotification(),
    watchHideNotification(),
  ]);
}
