export enum UserRoleTypes {
  None = 0,
  OrganisationManager = 1,
  MedicalProfessional = 2,
  PortalOrganisationManager = 4,
  Technician = 16,
  PortalUserManager = 32,
  PreRegistrationManager = 128,
  PrismaSmartConfigurationEditor = 256,
  PrismaLineSleepConfigurationEditor = 512,
  PrismaVentConfigurationEditor = 1024,
  PrismaLineVentiConfigurationEditor = 2048,
  PrismaSmartTelemonitoringSwitcher = 4096,
}
