import { Action } from 'redux';

import { EditableSettingsDto } from '@shared/api/core-api/settings-api';
import { ActionWithPayload } from './typings';

export const TOKEN_REVOKE = 'TOKEN_REVOKE';

export const SETTINGS_REQUEST = 'SETTINGS_REQUEST';
export const SETTINGS_REQUEST_SUCCESS = 'SETTINGS_REQUEST_SUCCESS';
export const SETTINGS_REQUEST_FAILURE = 'SETTINGS_REQUEST_FAILURE';

export const CREATE_TOKEN_REQUEST = 'CREATE_TOKEN_REQUEST';
export const CREATE_TOKEN_SUCCESS = 'CREATE_TOKEN_SUCCESS';
export const CREATE_TOKEN_FAILURE = 'CREATE_TOKEN_FAILURE';

export const REVOKE_TOKEN_REQUEST = 'REVOKE_TOKEN_REQUEST';
export const REVOKE_TOKEN_SUCCESS = 'REVOKE_TOKEN_SUCCESS';
export const REVOKE_TOKEN_FAILURE = 'REVOKE_TOKEN_FAILURE';

export const HIDE_TOKEN = 'HIDE_TOKEN';
export const TOKEN_DAYS_LEFT_REQUEST = 'TOKEN_DAYS_LEFT_REQUEST';
export const TOKENS_DAYS_LEFT_REQUEST_SUCCESS = 'TOKENS_DAYS_LEFT_REQUEST_SUCCESS';
export const TOKENS_DAYS_LEFT_REQUEST_FAILED = 'TOKENS_DAYS_LEFT_REQUEST_FAILED';

export const TWO_FACTOR_AUTHENTICATION_ACTIVATE = 'TWO_FACTOR_AUTHENTICATION_ACTIVATE_REQUEST';
export const TWO_FACTOR_AUTHENTICATION_ACTIVATE_SUCCESS = 'TWO_FACTOR_AUTHENTICATION_ACTIVATE_SUCCESS';
export const TWO_FACTOR_AUTHENTICATION_ACTIVATE_FAILED = 'TWO_FACTOR_AUTHENTICATION_ACTIVATE_FAILED';

export const TWO_FACTOR_AUTHENTICATION_DEACTIVATE = 'TWO_FACTOR_AUTHENTICATION_DEACTIVATE_REQUEST';
export const TWO_FACTOR_AUTHENTICATION_DEACTIVATE_SUCCESS = 'TWO_FACTOR_AUTHENTICATION_DEACTIVATE_SUCCESS';
export const TWO_FACTOR_AUTHENTICATION_DEACTIVATE_FAILED = 'TWO_FACTOR_AUTHENTICATION_DEACTIVATE_FAILED';

export const TWO_FACTOR_AUTHENTICATION_CONFIRMATION_MODAL_OPEN = 'TWO_FACTOR_AUTHENTICATION_CONFIRMATION_MODAL_OPEN';
export const TWO_FACTOR_AUTHENTICATION_CONFIRMATION_MODAL_CLOSE = 'TWO_FACTOR_AUTHENTICATION_CONFIRMATION_MODAL_CLOSE';

export const ACTIVATE_CONFIRM_SETTINGS_REQUIRED = 'ACTIVATE_CONFIRM_SETTINGS_REQUIRED';
export const ACTIVATE_CONFIRM_SETTINGS_REQUIRED_SUCCESS = 'ACTIVATE_CONFIRM_SETTINGS_REQUIRED_SUCCESS';
export const ACTIVATE_CONFIRM_SETTINGS_REQUIRED_FAILED = 'ACTIVATE_CONFIRM_SETTINGS_REQUIRED_FAILED';

export const DEACTIVATE_CONFIRM_SETTINGS_REQUIRED = 'DEACTIVATE_CONFIRM_SETTINGS_REQUIRED';
export const DEACTIVATE_CONFIRM_SETTINGS_REQUIRED_SUCCESS = 'DEACTIVATE_CONFIRM_SETTINGS_REQUIRED_SUCCESS';
export const DEACTIVATE_CONFIRM_SETTINGS_REQUIRED_FAILED = 'DEACTIVATE_CONFIRM_SETTINGS_REQUIRED_FAILED';
export type SettingsRequestSuccessAction = ActionWithPayload<EditableSettingsDto, typeof SETTINGS_REQUEST_SUCCESS>;
export type TwoFactorAuthenticationActivateAction = Action<typeof TWO_FACTOR_AUTHENTICATION_ACTIVATE_SUCCESS>;
export type TwoFactorAuthenticationDeactivateAction = Action<typeof TWO_FACTOR_AUTHENTICATION_DEACTIVATE_SUCCESS>;

export type SettingsActions =
  | SettingsRequestSuccessAction
  | TwoFactorAuthenticationActivateAction
  | TwoFactorAuthenticationDeactivateAction;
