import { useDispatch } from 'react-redux';

import {
  HideNotificationPayload,
  ShowNotificationPayload,
  ShowNotificationWithTimeoutPayload,
  ShowRevokeNotificationPayload,
} from './payloadTypes/notificationPayloads';

export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';
export const HIDE_NOTIFICATION = 'HIDE_NOTIFICATION';
export const SHOW_NOTIFICATION_WITH_TIMEOUT = 'SHOW_NOTIFICATION_WITH_TIMEOUT';
export const SHOW_REVOKE_NOTIFICATION = 'SHOW_REVOKE_NOTIFICATION';
export const SHOW_REVOKE_NOTIFICATION_WITH_TIMEOUT = 'SHOW_REVOKE_NOTIFICATION_WITH_TIMEOUT';

export const ERROR_ID = 'ERROR_ID';
export const SUCCESS_ID = 'SUCCESS_ID';
export const INFO_ID = 'INFO_ID';
export const GENERIC_ERROR_KEY = 'Generic_Error';

export const showNotification = (payload: ShowNotificationPayload) => {
  return {
    type: SHOW_NOTIFICATION,
    payload,
  } as const;
};

export const hideNotification = (payload: HideNotificationPayload) => {
  return {
    type: HIDE_NOTIFICATION,
    payload,
  } as const;
};

export const showNotificationWithTimeout = (payload: ShowNotificationWithTimeoutPayload) => {
  return {
    type: SHOW_NOTIFICATION_WITH_TIMEOUT,
    payload,
  } as const;
};

export const showRevokeNotification = (payload: ShowRevokeNotificationPayload) => {
  return {
    type: SHOW_REVOKE_NOTIFICATION,
    payload,
  } as const;
};

export const showRevokeNotificationWithTimeout = (payload: ShowRevokeNotificationPayload) => {
  return {
    type: SHOW_REVOKE_NOTIFICATION_WITH_TIMEOUT,
    payload,
  } as const;
};

export const useShowNotificationWithTimeout = () => {
  const dispatch = useDispatch();

  return (payload: ShowNotificationWithTimeoutPayload) => {
    dispatch(showNotificationWithTimeout(payload));
  };
};

export type NotificationActions =
  | ReturnType<typeof showNotification>
  | ReturnType<typeof hideNotification>
  | ReturnType<typeof showNotificationWithTimeout>
  | ReturnType<typeof showRevokeNotification>
  | ReturnType<typeof showRevokeNotificationWithTimeout>;
