import { AxiosRequestConfig } from 'axios';

/**
 * Converts a number to a 2-digit hexadecimal string.
 * i.e. 0-255 -> '00'-'ff'
 * @param dec The number to convert.
 */
function dec2hex(dec: number) {
  return ('0' + dec.toString(16)).substring(-2);
}

/** Generates a random 32-digit hexadecimal string. */
function generateId(): string {
  const arr = new Uint8Array(16);
  const crypto = window.crypto || window.msCrypto;
  crypto.getRandomValues(arr);
  return Array.from(arr, dec2hex).join('');
}

export const getSessionId = () => {
  // Separate storage for each tab/window, the data is deleted when the browser tab is closed.
  const storage = window.sessionStorage;
  let id = storage.getItem('sessionId');
  if (!id) {
    id = generateId();
    storage.setItem('sessionId', id);
  }
  return id;
};

/**
 * Intercepts all HTTP calls via axios and adds a session-id header.
 */
export const sessionAxiosInterceptor = () => {
  return async (config: AxiosRequestConfig) => {
    if (!config.headers) {
      config.headers = {};
    }
    config.headers['session-id'] = getSessionId();
    return config;
  };
};
