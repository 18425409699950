import { PatientFilterCategory } from '@shared/constants/PatientFilterCategory';

export enum PatientListTabIds {
  SdbTelemonitoring = 'SdbTelemonitoring',
  NivTelemonitoring = 'NivTelemonitoring',
}

export const PatientFilterCategoryPerTab: { [key in PatientListTabIds]: PatientFilterCategory } = {
  [PatientListTabIds.SdbTelemonitoring]: PatientFilterCategory.SleepTelemonitoring,
  [PatientListTabIds.NivTelemonitoring]: PatientFilterCategory.VentilationTelemonitoring,
};
