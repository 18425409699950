import { AvailableRolesState } from '@shared/redux/state/availableRoles';
import { UserRoleTypes } from '@shared/constants/UserRoleTypes';

export const initialAvailableRolesState = (): AvailableRolesState => ({
  portalRoles: [
    UserRoleTypes.PortalOrganisationManager,
    UserRoleTypes.PortalUserManager,
    UserRoleTypes.PreRegistrationManager,
  ],
  tenantRoles: [UserRoleTypes.Technician, UserRoleTypes.MedicalProfessional, UserRoleTypes.OrganisationManager],
});
