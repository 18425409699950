import { TenantState } from '@shared/redux/state/tenantState';
import { RequestState } from '@shared/constants/request';

export const initTenantState: TenantState = {
  exists: false,
  impossibleToVerify: false,
  requestState: RequestState.NOT_STARTED,
  organisationLanguage: '',
  isExternalLoginUsed: false,
};
