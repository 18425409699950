import * as url from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';

import { Configuration } from '@shared/api/base-api/configuration';
import { PATH_TO_AUTH_API } from '@shared/constants';
import { RequestArgs, postProcessAxiosError } from '@shared/api/base-api';

/**
 * Command to change the password of a user
 * @export
 * @interface ChangePasswordCommand
 */
export interface ChangePasswordCommand {
  /**
   * The users subject, for which the password should be changed
   * @type {string}
   * @memberof ChangePasswordCommand
   */
  email: string;
  /**
   * The current password
   * @type {string}
   * @memberof ChangePasswordCommand
   */
  currentPassword: string;
  /**
   * The new password to be set
   * @type {string}
   * @memberof ChangePasswordCommand
   */
  newPassword: string;
  languageAbbreviation: string;
}

/**
 * Command to initiate password reset procedure
 * @export
 * @interface ForgotPasswordCommand
 */
export interface ForgotPasswordCommand {
  /**
   * The email address, for which the password reset procedure should be
   * initiated.
   * @type {string}
   * @memberof ForgotPasswordCommand
   */
  email: string;
  languageAbbreviation: string;
}

/**
 * Command to reset a password for a user
 * @export
 * @interface ResetPasswordCommand
 */
export interface ResetPasswordCommand {
  /**
   * The token to verify that this request has been initiated by the real user
   * @type {string}
   * @memberof ResetPasswordCommand
   */
  token: string;
  /**
   * The email address of this user
   * @type {string}
   * @memberof ResetPasswordCommand
   */
  email: string;
  /**
   * The new password to be applied
   * @type {string}
   * @memberof ResetPasswordCommand
   */
  password: string;
  /*
   * Flag indicating that user confirmed agreement to terms of use.
   * */
  areTermsOfUseSigned: boolean;
  languageAbbreviation: string;
}

/**
 * UsersApi - axios parameter creator
 * @export
 */
export const IdentityApiAxiosParamCreator = (configuration?: Configuration) => {
  return {
    /**
     *
     * @summary Changes the password of the current logged in user.
     * @param {ChangePasswordCommand} [changePasswordCommand] a DTO
     * containing the new password
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changeYourOwnPassword(changePasswordCommand?: ChangePasswordCommand, options: any = {}): RequestArgs {
      const localVarPath = `/User/password`;
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail:
      // https://stackoverflow.com/a/7517673/1077943
      delete (localVarUrlObj as any).search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        ('ChangePasswordCommand' as any) !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(changePasswordCommand || {})
        : changePasswordCommand || '';

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Initializes the password forgotten procedure by sending an
     * email.
     * @param {ForgotPasswordCommand} [forgotPasswordCommand] The command
     * containing the email to be reset.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    forgotPassword(forgotPasswordCommand?: ForgotPasswordCommand, options: any = {}): RequestArgs {
      const localVarPath = `/User/forgotPassword`;
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail:
      // https://stackoverflow.com/a/7517673/1077943
      delete (localVarUrlObj as any).search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        ('ForgotPasswordCommand' as any) !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(forgotPasswordCommand || {})
        : forgotPasswordCommand || '';

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Tries to reset a password for the given user.
     * @param {ResetPasswordCommand} [resetPasswordCommand] Command
     * containing the new password, email and token.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resetPassword(resetPasswordCommand?: ResetPasswordCommand, options: any = {}): RequestArgs {
      const localVarPath = `/User/resetPassword`;
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail:
      // https://stackoverflow.com/a/7517673/1077943
      delete (localVarUrlObj as any).search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        ('ResetPasswordCommand' as any) !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(resetPasswordCommand || {})
        : resetPasswordCommand || '';

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const IdentityApiFp = (configuration?: Configuration) => {
  return {
    /**
     *
     * @summary Changes the password of the current logged in user.
     * @param {ChangePasswordCommand} [changePasswordCommand] a DTO
     * containing the new password
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changeYourOwnPassword(
      changePasswordCommand?: ChangePasswordCommand,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
      const localVarAxiosArgs = IdentityApiAxiosParamCreator(configuration).changeYourOwnPassword(
        changePasswordCommand,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = PATH_TO_AUTH_API) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs).catch(postProcessAxiosError);
      };
    },
    /**
     *
     * @summary Initializes the password forgotten procedure by sending an
     * email.
     * @param {ForgotPasswordCommand} [forgotPasswordCommand] The command
     * containing the email to be reset.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    forgotPassword(
      forgotPasswordCommand?: ForgotPasswordCommand,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
      const localVarAxiosArgs = IdentityApiAxiosParamCreator(configuration).forgotPassword(
        forgotPasswordCommand,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = PATH_TO_AUTH_API) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs).catch(postProcessAxiosError);
      };
    },
    /**
     *
     * @summary Tries to reset a password for the given user.
     * @param {ResetPasswordCommand} [resetPasswordCommand] Command
     * containing the new password, email and token.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resetPassword(
      resetPasswordCommand?: ResetPasswordCommand,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
      const localVarAxiosArgs = IdentityApiAxiosParamCreator(configuration).resetPassword(
        resetPasswordCommand,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = PATH_TO_AUTH_API) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs).catch(postProcessAxiosError);
      };
    },
  };
};

/**
 * UsersApi - factory interface
 * @export
 */
export const IdentityApiFactory = (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) => {
  return {
    /**
     * Changes the password of the current logged in user.
     * @param {ChangePasswordCommand} [changePasswordCommand] a DTO
     * containing the new password
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changeYourOwnPassword(changePasswordCommand?: ChangePasswordCommand, options?: any) {
      return IdentityApiFp(configuration).changeYourOwnPassword(changePasswordCommand, options)(axios, basePath);
    },

    /**
     * Initializes the password forgotten procedure by sending an
     * email.
     * @param {ForgotPasswordCommand} [forgotPasswordCommand] The command
     * containing the email to be reset.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    forgotPassword(forgotPasswordCommand?: ForgotPasswordCommand, options?: any) {
      return IdentityApiFp(configuration).forgotPassword(forgotPasswordCommand, options)(axios, basePath);
    },

    /**
     * Tries to reset a password for the given user.
     * @param {ResetPasswordCommand} [resetPasswordCommand] Command
     * containing the new password, email and token.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resetPassword(resetPasswordCommand?: ResetPasswordCommand, options?: any) {
      return IdentityApiFp(configuration).resetPassword(resetPasswordCommand, options)(axios, basePath);
    },
  };
};
