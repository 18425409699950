import i18next from 'i18next';

/**
 * Map with { country ISO code - localization key}
 * IMPORTANT: Country name abbreviation should be ISO2, use only ISO2,
 * otherwise you will get a conflict with backend and such country wouldn't be found.
 */
export const supportedCountryToLocalizedKey = {
  ZZ: 'CountryNames.Other',
  BR: 'CountryNames.Brazil',
  CL: 'CountryNames.Chile',
  CO: 'CountryNames.Colombia',
  CR: 'CountryNames.CostaRica',
  FR: 'CountryNames.France',
  DE: 'CountryNames.Germany',
  JP: 'CountryNames.Japan',
  MX: 'CountryNames.Mexico',
  PA: 'CountryNames.Panama',
  CH: 'CountryNames.Switzerland',
  IL: 'CountryNames.Israel',
  SI: 'CountryNames.Slovenia',
  MA: 'CountryNames.Morocco',
  CZ: 'CountryNames.Czechia',
  PL: 'CountryNames.Poland',
  IT: 'CountryNames.Italy',
  SK: 'CountryNames.Slovakia',
  DK: 'CountryNames.Denmark',
  KR: 'CountryNames.Korea',
  NL: 'CountryNames.Netherlands',
  LV: 'CountryNames.Latvia',
  GR: 'CountryNames.Greece',
  BG: 'CountryNames.Bulgaria',
  TR: 'CountryNames.Turkey',
  IE: 'CountryNames.Ireland',
  GB: 'CountryNames.UnitedKingdom',
  AU: 'CountryNames.Australia',
  HK: 'CountryNames.HongKong',
  LB: 'CountryNames.Lebanon',
  MU: 'CountryNames.Mauritius',
  NZ: 'CountryNames.NewZealand',
  NO: 'CountryNames.Norway',
  RO: 'CountryNames.Romania',
  SE: 'CountryNames.Sweden',
  CA: 'CountryNames.Canada',
  EE: 'CountryNames.Estonia',
  US: 'CountryNames.USA',
  SA: 'CountryNames.SaudiArabia',
  ES: 'CountryNames.Spain',
  SG: 'CountryNames.Singapore',
  PT: 'CountryNames.Portugal',
  LU: 'CountryNames.Luxembourg',
  KW: 'CountryNames.Kuwait',
} as const;

export type SupportedCountries = keyof typeof supportedCountryToLocalizedKey;

/**
 * Country codes used in code. Are used as indices for countryTypeToLocalizedKey.
 * IMPORTANT: Country name abbreviation should be ISO2, use only ISO2,
 * otherwise you will get a conflict with backend and such country wouldn't be found.
 */
export const supportedCountryKeys = {
  Other: 'ZZ',
  Germany: 'DE',
} as const;

export const localizedCountryTypedString = (country: SupportedCountries): string => {
  return i18next.t(supportedCountryToLocalizedKey[country]);
};
