import axios, { AxiosRequestConfig } from 'axios';
import urlFormat from 'url';

/**
 *
 * @export
 * @interface RequestArgs
 */
export interface RequestArgs {
  url: string;
  options: any;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
  private _options: AxiosRequestConfig;

  constructor(protected baseURL: string) {
    this._options = {
      baseURL,
    };
  }

  get = <T>(url: string, query?: object, options?: AxiosRequestConfig) =>
    axios.get<T>(url, {
      ...this._options,
      ...options,
      params: query,
    });

  post = <T>(url: string, query?: object, body: any = {}, options?: AxiosRequestConfig) =>
    axios.post<T>(url, body, {
      ...this._options,
      ...options,
      params: query,
    });

  request = <T>(options?: AxiosRequestConfig) => axios.request<T>({ ...this._options, ...options });

  put = <T>(url: string, query?: object, body?: any, options?: AxiosRequestConfig) =>
    axios.put<T>(url, body, {
      ...this._options,
      ...options,
      params: query,
    });

  delete = <T>(url: string, query?: object, options?: AxiosRequestConfig) =>
    axios.delete<T>(url, {
      ...this._options,
      ...options,
      params: query,
    });
}

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
  name: 'RequiredError' = 'RequiredError';
  constructor(public field: string, msg?: string) {
    super(msg);
  }
}

export function postProcessAxiosError(error: any): never {
  const { response } = error;
  if (response && response.data) {
    const { type } = response.data;

    // if type exists and starts with 'urn' we assume it's error in ProblemDetails format
    if (type && type.startsWith('urn')) {
      // .replace is to avoid creating namespaces in i18n
      const normalizedErrorType = type.replace(/:/g, '_');
      error.message = normalizedErrorType;
      error.data = response.data;
    }
  }
  throw error;
}

/**
 * Prepares request to upload file to the specified endpoint.
 * @param file - file to upload
 * @param options - request options
 * @param apiUrl - upload file endpoint
 */
export function prepareRequestAndFillFormData(file: any, options: any = {}, apiUrl: string): RequestArgs {
  // verify required parameter 'file' is not null or undefined
  if (file === null || file === undefined) {
    throw new RequiredError('file', 'Required parameter file was null or undefined when calling upload.');
  }
  const localVarPath = apiUrl;
  const localVarUrlObj = urlFormat.parse(localVarPath, true);
  const localVarRequestOptions = { method: 'POST', ...options };
  const localVarHeaderParameter = {} as any;
  const localVarQueryParameter = {} as any;
  const localVarFormParams = new FormData();

  localVarFormParams.set('file', file);

  localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

  localVarUrlObj.query = {
    ...localVarUrlObj.query,
    ...localVarQueryParameter,
    ...options.query,
  };
  // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
  delete (localVarUrlObj as any).search;
  localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
  localVarRequestOptions.data = localVarFormParams;
  return {
    url: urlFormat.format(localVarUrlObj),
    options: localVarRequestOptions,
  };
}
