import { RequestState } from '@shared/constants/request';
import { OrganisationsRootState } from '@shared/redux/state/organisations';

export const initOrganisationsRootState: OrganisationsRootState = {
  current: {
    allowTeleConfiguration: true,
    confirmSettingsRequired: false,
    countryCode: 'DE',
    name: '',
    tenant: '',
    twoFactorEnabled: false,
    requestState: RequestState.NOT_STARTED,
    timezoneInfo: {
      timezoneOffset_Minutes: 0,
      displayName: 'UTC',
      id: 'UTC',
    },
  },
  filters: {
    loginType: 'All',
    countries: [],
  },
};
