import { escapeRegExp } from 'lodash';

import { getBaseAddress } from '@shared/constants';
import { isSubdomainMatchesRegexp } from './subdomain-validator';

/**
 * Gets the subdomain from opened URL.
 * Returns null in case if no subdomain found.
 */
export const getTenant = () => {
  const stageBaseAddress = getBaseAddress();
  const tenantMatches = window.location.hostname.match(`^([^.]+)\\.${escapeRegExp(stageBaseAddress)}`);
  if (tenantMatches === null || !isSubdomainMatchesRegexp(tenantMatches[1])) {
    return null;
  }
  return tenantMatches[1];
};
