import { Action } from 'redux';

import { ActionWithPayload } from './typings';
import { userFormType } from '@shared/constants/reduxFormNames';

export const REDUX_FORM_CHANGE = '@@redux-form/CHANGE';
export const REDUX_FORM_RESET = '@@redux-form/RESET';

export type ReduxFormChangeAction = ActionWithPayload<any, typeof REDUX_FORM_CHANGE> & {
  meta: {
    form: userFormType | string;
    field: string;
  };
};

export type ReduxFormResetAction = Action<typeof REDUX_FORM_RESET> & {
  meta: {
    form: userFormType | string;
  };
};

export type ReduxFormActions = ReduxFormChangeAction | ReduxFormResetAction;
