import { takeLatest, all } from 'redux-saga/effects';

import * as SdCardUploadActionTypes from '@shared/redux/actions/sdCardUpload-actions';
import * as SdCardUploadSaga from '@shared/redux/sagas/sdCardUpload-saga';

function* watchSendFile() {
  yield takeLatest(SdCardUploadActionTypes.UPLOAD_FILE_REQUEST, SdCardUploadSaga.uploadStatistics);
}

function* watchCancelFileUpload() {
  yield takeLatest(SdCardUploadActionTypes.CANCEL_FILE_UPLOAD_REQUEST, SdCardUploadSaga.cancelUpload);
}

function* watchFetchSupportedFileExtensions() {
  yield takeLatest(
    SdCardUploadActionTypes.FETCH_SUPPORTED_FILE_EXTENSIONS_REQUEST,
    SdCardUploadSaga.fetchSupportedFileExtensions,
  );
}

export default function* watchSdCardUploadActions() {
  yield all([watchSendFile(), watchCancelFileUpload(), watchFetchSupportedFileExtensions()]);
}
