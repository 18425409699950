import i18next from 'i18next';

import { reportError } from './sentry';

export const isNetworkError = (e: any) => {
  return e.message && e.message === 'Network Error';
};

export const handleErrorResponse = (error?: unknown, localizeIt = true) => {
  const response = error ? (error as XMLHttpRequest).response : null;

  let errors = null;
  if (response && response.data) {
    errors = response.data;
  }

  if (errors != null) {
    // We have different format of error messages from backend,
    // sometimes it is plain list in root object, sometimes it is
    // .errors field within. Depends on service.
    if (errors.errors && typeof errors.errors !== 'string') {
      errors = errors.errors;
    }

    if (localizeIt) {
      for (const key in errors) {
        if (!errors.hasOwnProperty(key)) {
          continue;
        }

        const value = errors[key];
        // backend can return array in errors[key], but redux-form expect string as value
        // of errors[key], so convert it to new line separated string.
        if (Array.isArray(value)) {
          errors[key] = value.reduce((prevValue: string, currentValue: string) => {
            return prevValue + (prevValue !== '' ? '\n' : '') + i18next.t(currentValue, { defaultValue: currentValue });
          }, '');
        } else {
          errors[key] = i18next.t(value, { defaultValue: value });
        }
      }
    }

    return errors;
  } else {
    const e = new Error(`Unknown error received: ${error}`);
    reportError(e);
  }
};

/**
 * Re-packs the specified error according to phone
 * field structure {phone: {number, countryCode}}.
 * (Hacky workaround for our custom phone number control that expects an
 * extra wrapper around the error.)
 */
export const handlePhoneNumberServerError = (fieldName: string, formError: any): void => {
  if (formError.errors && formError.errors[fieldName]) {
    formError.errors[fieldName] = { number: formError.errors[fieldName] };
  }
};
