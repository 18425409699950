import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export const CalendarIcon = () => {
  return (
    <SvgIcon className='calendar-icon' viewBox='-1 -1 19 21'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3 2H2C0.895431 2 0 2.89543 0 4V7V16C0 17.1046 0.89543 18 2 18H14C15.1046 18 16 17.1046 16 16V7V4C16 2.89543 15.1046 2 14 2H13V0H11V2H5V0H3V2ZM12 4H4H2V6H14V4H12ZM14 8H2V16H14V8ZM5 9V11H3V9H5ZM5 15V13H3V15H5ZM7 9H9V11H7V9ZM9 13H7V15H9V13ZM13 9V11H11V9H13ZM13 15V13H11V15H13Z'
      />
    </SvgIcon>
  );
};
