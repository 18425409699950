import React from 'react';

export const CheckboxIcon: React.FC<{
  borderClassName: string;
  checkClassName: string;
}> = (props) => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect className={props.borderClassName} x='2.5' y='2.5' width='15' height='15' />
    <path className={props.checkClassName} d='M5 10L9 13L15 6' strokeWidth='2' />
  </svg>
);
