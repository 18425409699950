import i18next from 'i18next';

export const getSubdomainValidationResult = (subdomain: string) => {
  if (subdomain.length < 3 || subdomain.length > 63) {
    return i18next.t('Errors_InvalidSubdomainLength');
  }

  if (subdomain.charAt(0) === '-' || subdomain.charAt(subdomain.length - 1) === '-') {
    return i18next.t('Errors_SubdomainHyphen');
  }

  if (!isSubdomainMatchesRegexp(subdomain)) {
    return i18next.t('Errors_SubdomainInvalidChars');
  }

  return '';
};

/*
 * Should pass subdomains like 'mcc-tomsk', 'mcc-2'
 * Should not pass subdomains like '-mcc-tomsk-', '-'
 * If you adjust these rules, please adjust it as well in
 * OrganisationsController in AdminService.
 */
export const isSubdomainMatchesRegexp = (subdomain: string) => {
  const regex = /^[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]$/;

  return regex.test(subdomain);
};
