import * as Sentry from '@sentry/browser';
import axios, { TransitionalOptions } from 'axios';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import dayOfYear from 'dayjs/plugin/dayOfYear.js';
// It's important to import Days.js plugins with js extension, otherwise this happens https://dev.azure.com/prismacode/prismacloud/_workitems/edit/40999/
import duration from 'dayjs/plugin/duration.js';
import isBetween from 'dayjs/plugin/isBetween.js';
import localeData from 'dayjs/plugin/localeData.js';
import localizedFormat from 'dayjs/plugin/localizedFormat.js';
import relativeTime from 'dayjs/plugin/relativeTime.js';
import utc from 'dayjs/plugin/utc.js';
import React from 'react';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import { createRoot } from 'react-dom/client';

import { LicenseInfo } from '@mui/x-license-pro';

import App from '@app/App';
// Call it before any action
import '@app/browserValidator';
import initConfiguration from '@app/store';
import { authAxiosInterceptor } from '@shared/api/axios-extensions/axios-auth-interceptor';
import { axiosErrorHandler } from '@shared/api/axios-extensions/axiosErrorHandler';
import { noContentInterceptor } from '@shared/api/no-content-interceptor';
import { PAppQueryFactory } from '@shared/api/papp-api';
import { MATOMO_CONFIG, MUI_LICENSE_KEY, PATH_TO_PATIENT_APP, SENTRY_CONFIG, STAGE_NAME } from '@shared/constants';
import { authDataChanged } from '@shared/redux/actions/auth-actions';
import '@shared/util/dateLocaleRegistrator';
import { integrateLocalization } from '@shared/util/i18n';
import { initMatomo } from '@shared/util/matomo/matomo';
import { getSessionId, sessionAxiosInterceptor } from '@shared/util/session-helper';

LicenseInfo.setLicenseKey(MUI_LICENSE_KEY);

dayjs.extend(isBetween);
dayjs.extend(localizedFormat);
dayjs.extend(utc);
dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(localeData);
dayjs.extend(dayOfYear);
dayjs.extend(customParseFormat);

if (SENTRY_CONFIG.IsEnabled) {
  Sentry.init({
    dsn: SENTRY_CONFIG.DSN,
    environment: STAGE_NAME,
    release: VERSION,
    beforeSend(event) {
      event.extra = { ...event.extra, sessionId: getSessionId() };
      return event;
    },
  });
}
if (MATOMO_CONFIG.IsEnabled) {
  initMatomo(MATOMO_CONFIG.Url, MATOMO_CONFIG.SiteId);
}

// Make sessionId visible to UI tests.
window.sessionId = getSessionId();

const configuration = initConfiguration();
const store = configuration.store;
// restore user authentication state.
store.dispatch(authDataChanged());

axios.defaults.transitional = {
  silentJSONParsing: false,
  forcedJSONParsing: true,
  clarifyTimeoutError: true,
} as TransitionalOptions;
axios.interceptors.request.use(authAxiosInterceptor());
axios.interceptors.request.use(sessionAxiosInterceptor());
axios.interceptors.response.use((response) => response, axiosErrorHandler);
axios.interceptors.response.use(noContentInterceptor);
PAppQueryFactory.setAxiosFactory(() => axios);
PAppQueryFactory.setBaseUrl(PATH_TO_PATIENT_APP);

const render = () => {
  const container = document.getElementById('root');
  const root = createRoot(container!);
  root.render(<App store={store} configuration={configuration} />);
};

integrateLocalization(VERSION).then(() => {
  render();

  // === Set enablePerf to true to investigate component performance problems.
  const enablePerf = false;
  if (COMPILE_MODE === 'development' && enablePerf) {
    const { whyDidYouRender } = require('@welldone-software/why-did-you-render');
    whyDidYouRender(React, {
      trackAllPureComponents: true,
    });
  }
});
