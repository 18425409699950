import { takeLatest, all } from 'redux-saga/effects';

import * as TrustedBrowserActionTypes from '@shared/redux/actions/trustedBrowser-actions';
import * as BrowserSaga from '@shared/redux/sagas/trustedBrowser-saga';

function* watchClearBrowsersRequest() {
  yield takeLatest(TrustedBrowserActionTypes.CLEAR_USERS_BROWSERS_REQUEST, BrowserSaga.fetchClearAllBrowsers);
}

function* watchTrustedBrowsersRequest() {
  yield takeLatest(TrustedBrowserActionTypes.FETCH_TRUSTED_BROWSERS_REQUEST, BrowserSaga.fetchAllTrustedBrowsers);
}

export default function* watchTrustedBrowserActions() {
  yield all([watchClearBrowsersRequest(), watchTrustedBrowsersRequest()]);
}
