import { all } from 'redux-saga/effects';

import watchNotificationActions from './watchFunctions/watchNotificationActions';
import watchUnhandledExceptionActions from './watchFunctions/watchUnhandledExceptionActions';
import watchAuthActions from './watchFunctions/watchAuthActions';
import watchUserActions from './watchFunctions/watchUserActions';
import watchOrganisationActions from './watchFunctions/watchOrganisationActions';
import watchSettingActions from './watchFunctions/watchSettingActions';
import watchTenantActions from './watchFunctions/watchTenantActions';
import watchPhoneVerificationActions from './watchFunctions/watchPhoneVerificationActions';
import watchTrustedBrowserActions from './watchFunctions/watchTrustedBrowserActions';
import watchHomeActions from './watchFunctions/watchHomeActions';
import watchReportsAction from './watchFunctions/watchReportsActions';
import watchSdCardUploadAction from './watchFunctions/watchSdCardUploadActions';
import watchLocalisationActions from './watchFunctions/watchLocalisationActions';
import watchPreregisteredDevicesUploadActions from './watchFunctions/watchPreregisteredDevicesUploadActions';

export default function* rootSaga() {
  yield all([
    watchNotificationActions(),
    watchUnhandledExceptionActions(),
    watchAuthActions(),
    watchUserActions(),
    watchOrganisationActions(),
    watchSettingActions(),
    watchTenantActions(),
    watchPhoneVerificationActions(),
    watchTrustedBrowserActions(),
    watchHomeActions(),
    watchReportsAction(),
    watchSdCardUploadAction(),
    watchLocalisationActions(),
    watchPreregisteredDevicesUploadActions(),
  ]);
}
