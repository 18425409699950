import dayjs from 'dayjs';

import { PasswordExpirationDto } from '@shared/api/sftp';

interface SftpServerInfo {
  sftpPort: number;
  sftpHost: string;
}

interface Deactivated {
  type: 'DEACTIVATED';
  serverInfo?: SftpServerInfo;
}

interface Activated {
  type: 'ACTIVATED';
  serverInfo?: SftpServerInfo;
  sftpPasswordExpirationState: PasswordExpirationDto;
  daysLeft: number;
}

interface RecentlyGenerated {
  type: 'RECENTLY_GENERATED';
  serverInfo?: SftpServerInfo;
  sftpPassword: string;
  selectedLifetimeDays: number;
}

export function initSftpState(): Deactivated {
  return {
    type: 'DEACTIVATED',
  };
}

export function generated(state: SftpSettingsState, password: string, selectedLifetimeDays: number): RecentlyGenerated {
  return {
    ...state,
    type: 'RECENTLY_GENERATED',
    sftpPassword: password,
    selectedLifetimeDays,
  };
}

export function deactivate(state: SftpSettingsState): Deactivated {
  return {
    ...initSftpState(),
    serverInfo: state.serverInfo,
  };
}

export function applyExpirationState(
  state: SftpSettingsState,
  sftpPasswordExpirationState: PasswordExpirationDto,
): Activated | Deactivated {
  if (
    sftpPasswordExpirationState &&
    (sftpPasswordExpirationState.isUnlimited || sftpPasswordExpirationState.timeLeft)
  ) {
    const duration = dayjs.duration(sftpPasswordExpirationState.timeLeft);
    if (duration.asSeconds() === 0 && !sftpPasswordExpirationState.isUnlimited) {
      return deactivate(state);
    } else {
      const daysLeft = Math.round(duration.asDays());
      return {
        ...state,
        type: 'ACTIVATED',
        sftpPasswordExpirationState,
        daysLeft,
      };
    }
  } else {
    return deactivate(state);
  }
}

export type SftpSettingsState = Deactivated | RecentlyGenerated | Activated;
