import { Action } from 'redux';
import { Canceler } from 'axios';

import { NotificationKinds } from '@shared/redux/state/notification';
import * as NotificationActions from './notification-actions';
import { ActionWithPayload } from './typings';
import { PreregisteredDevicesUploadData } from '@shared/types/devicePreRegistrationTypes';

export const UPLOAD_DEVICES_FILE_REQUEST = 'UPLOAD_DEVICES_FILE_REQUEST';
export const UPLOAD_DEVICES_FILE_STARTED = 'UPLOAD_DEVICES_FILE_STARTED';
export const UPLOAD_DEVICES_FILE_SUCCESS = 'UPLOAD_DEVICES_FILE_SUCCESS';
export const UPLOAD_DEVICES_FILE_FAILURE = 'UPLOAD_DEVICES_FILE_FAILURE';

export type UploadDevicesFileRequestAction = ActionWithPayload<{ file: File }, typeof UPLOAD_DEVICES_FILE_REQUEST>;

export type UploadDevicesFileStartedAction = ActionWithPayload<
  { cancellationCallback: Canceler },
  typeof UPLOAD_DEVICES_FILE_STARTED
>;

export type UploadDevicesFileSuccessAction = ActionWithPayload<
  {
    uploadInfo: PreregisteredDevicesUploadData[];
  },
  typeof UPLOAD_DEVICES_FILE_SUCCESS
>;

export type UploadDevicesFileFailureAction = Action<typeof UPLOAD_DEVICES_FILE_FAILURE>;

export type UploadDevicesActions =
  | UploadDevicesFileRequestAction
  | UploadDevicesFileStartedAction
  | UploadDevicesFileSuccessAction
  | UploadDevicesFileFailureAction;

export const movePreRegisteredDeviceSuccess = () =>
  NotificationActions.showNotificationWithTimeout({
    id: NotificationActions.SUCCESS_ID,
    kind: NotificationKinds.Success,
    resourceKey: 'MovePreRegisteredDevices.Notifications.Success',
  });
