import download from 'downloadjs';

export function fileDownload(data: string | ArrayBuffer, filename: string, fileContentType: string, isBase64: boolean) {
  let dataToSave: string | Uint8Array | ArrayBuffer = data;
  if (typeof data == 'string' && isBase64) {
    dataToSave = base64ToArrayBuffer(data);
  }

  // downloadjs typings is missing ArrayBuffer as data, but can process it. So use any as workaround.
  // there is no sense to write a bug, there is no commits for 4 years.
  download(dataToSave as any, filename, fileContentType);
}

export function base64ToArrayBuffer(base64: string): Uint8Array {
  const binary_string = window.atob(base64);
  const len = binary_string.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) bytes[i] = binary_string.charCodeAt(i);
  return bytes;
}
