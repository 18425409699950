import { UnauthorizedState } from '@shared/redux/reducers/auth-reducer';
import { LogoutReason } from '@shared/constants';

export const initUnauthorizedState: UnauthorizedState = {
  isAuthenticated: false,
  loginWindowStep: 'login',
  phone: '',
  errorMessage: '',
  lastLogoutReason: LogoutReason.Unknown,
};
