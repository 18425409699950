import dayjs, { Dayjs } from 'dayjs';
import i18next from 'i18next';

import { getStoredOrDefaultCulture } from './user';

const dayAndShortMonthFormat: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'short' };
const dayShortMonthAndYearFormat: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'short', year: 'numeric' };

const getIsWordWrappingFixNeededForCulture = (culture: string) => {
  return ['ja', 'ko', 'zh-cn'].some((x) => x == culture);
};

const getIsFormatFixNeededForCulture = (culture: string) => {
  return ['bg', 'cs', 'sk'].some((x) => x == culture);
};

const fixWordWrapping = (dateObj: Date, culture: string, formatOptions: Intl.DateTimeFormatOptions) => {
  // Add zero-width-space and word-joiner for correct word wrapping.
  return dateObj.toLocaleDateString(culture, formatOptions).replace(/\d+/g, '​$&').replace(/\D+/g, '⁠$&');
};

export const getLocalizedDayjsDate = (date: string | Date | Dayjs | undefined) => {
  if (dayjs.isDayjs(date)) {
    return date.locale(getStoredOrDefaultCulture().dayJSLocale);
  }

  return dayjs(date, undefined, true).locale(getStoredOrDefaultCulture().dayJSLocale);
};

/*
 * Format 'L'.
 * Example: 08/16/2018.
 */
export const getLocalizedDate = (date: string | Date | Dayjs | undefined) => {
  return getLocalizedDayjsDate(date).format('L');
};

/*
 * Format 'LL'.
 * Example: August 16, 2018.
 */
export const getLocalizedLongDate = (dateTimeIso: string) => {
  return getLocalizedDayjsDate(dateTimeIso).format('LL');
};

/*
 * Format: 'LLL'.
 * Example: August 16, 2018 8:02 PM.
 */
export const getLocalizedLongDateAndTime = (dateTimeIso: string) => {
  return `${getLocalizedDayjsDate(dateTimeIso).format('LL')} ${getLocalizedTime(dateTimeIso)}`;
};

/*
 * Example: Never, Today, a day ago, 2 days ago, a month ago, 2 months ago...
 */
export const getTimeSinceLastSeenAsString = (secondsSinceLastSeen?: number | string | null) => {
  if (secondsSinceLastSeen === null || secondsSinceLastSeen === undefined) {
    return i18next.t('UserList_NeverSeen');
  }

  const userLastSeenNumber =
    typeof secondsSinceLastSeen === 'number'
      ? secondsSinceLastSeen
      : parseInt(secondsSinceLastSeen as unknown as string);

  const lastLogInTime = dayjs().add(userLastSeenNumber * -1, 'seconds');
  const isCurrentDate = lastLogInTime.isSame(new Date(), 'day');
  return isCurrentDate ? i18next.t('UserList_TodaySeen') : lastLogInTime.fromNow();
};

export const getLocalizedNumeric = (number: number) => {
  const culture = getStoredOrDefaultCulture().dayJSLocale;
  return number.toLocaleString(culture, { useGrouping: false });
};

/*
 * Example: 20 oct 2024.
 */
export const getLocalizedDayShortMonthAndYear = (dateTimeIso: string) => {
  const dateObj = new Date(dateTimeIso);

  const culture = getStoredOrDefaultCulture().dayJSLocale;

  if (getIsWordWrappingFixNeededForCulture(culture)) {
    return fixWordWrapping(dateObj, culture, dayShortMonthAndYearFormat);
  }
  if (getIsFormatFixNeededForCulture(culture)) {
    // JS Date uses wrong month short format for these languages — numbers separated by dots instead of shortened words
    // (see https://stackoverflow.com/questions/64506088/cs-cz-locale-and-javascript-new-date-constructor-doesnt-allow-for-shorthand-dat).
    return getLocalizedLongDateWithShortMonth(dateTimeIso);
  }

  return dateObj.toLocaleDateString(culture, dayShortMonthAndYearFormat);
};

/*
 * Example: 20 sep / 20 sep 2022.
 */
const getLocalizedDayAndShortMonthImpl = (dateTimeIso: string, isYearShownIfNotCurrent: boolean) => {
  const dateObj = new Date(dateTimeIso);

  const culture = getStoredOrDefaultCulture().dayJSLocale;

  const options: Intl.DateTimeFormatOptions =
    dateObj.getFullYear() == dayjs().year() || !isYearShownIfNotCurrent
      ? dayAndShortMonthFormat
      : dayShortMonthAndYearFormat;

  if (getIsWordWrappingFixNeededForCulture(culture)) {
    return fixWordWrapping(dateObj, culture, options);
  }
  if (getIsFormatFixNeededForCulture(culture)) {
    // JS Date uses wrong month short format for these languages — numbers separated by dots instead of shortened words
    // (see https://stackoverflow.com/questions/64506088/cs-cz-locale-and-javascript-new-date-constructor-doesnt-allow-for-shorthand-dat).
    // Dayjs has correct short month format, but doesn't have an option to show 'll'-formatted date without year.
    // Removing the current year manually from dayjs date.
    return getLocalizedLongDateWithShortMonth(dateTimeIso).replace(dayjs().year().toString(), '').trim();
  }

  return dateObj.toLocaleDateString(culture, options);
};

/*
 * Format: 'D MMM'.
 * Example: 20 sep.
 */
export const getLocalizedDayAndShortMonth = (dateTimeIso: string) => {
  const isYearShownIfNotCurrent = false;
  return getLocalizedDayAndShortMonthImpl(dateTimeIso, isYearShownIfNotCurrent);
};

/*
 * Format: 'D MMM' / 'D MMM YYYY'.
 * If current year is not equal to a year of given date, adds also a year.
 * Example: 20 sep / 20 sep 2022.
 */
export const getLocalizedDayMonthAndYearIfNotCurrent = (dateTimeIso: string) => {
  const isYearShownIfNotCurrent = true;
  return getLocalizedDayAndShortMonthImpl(dateTimeIso, isYearShownIfNotCurrent);
};

/*
 * Format: 'D MMM, LT'.
 * Example: 15 feb, 10:25.
 */
export const getLocalizedDayShortMonthAndTime = (dateTimeIso: string) => {
  return `${getLocalizedDayAndShortMonth(dateTimeIso)}, ${getLocalizedTime(dateTimeIso)}`;
};

/*
 * Example: 19:25 / 7:25 PM.
 * If dateTimeIso string includes timezone, it is adjusted according to the current timezone.
 * In opposite case time is returned as is.
 * Note: most timestamps with time coming from backend have UTC timezone (type DateTimeOffset).
 * In rare cases they have no timezone (type DateTime).
 */
export const getLocalizedTime = (dateTimeIso: string) => {
  if (!dateTimeIso) {
    return '';
  }

  const culture = getStoredOrDefaultCulture().dayJSLocale;
  const date = new Date(dateTimeIso);
  return Intl.DateTimeFormat(culture, {
    hour: 'numeric',
    minute: 'numeric',
  }).format(date);
};

/*
 * Format: 'L LT'.
 * Example: 08/16/2018 2:48 PM.
 */
export const getLocalizedDateAndTime = (dateTimeIso: string) => {
  return `${getLocalizedDate(dateTimeIso)} ${getLocalizedTime(dateTimeIso)}`;
};

/*
 * Format: 'll', 'MMM D, YYYY'.
 * Example: Aug 16, 2018.
 */
export const getLocalizedLongDateWithShortMonth = (dateTimeIso: string) => {
  return getLocalizedDayjsDate(dateTimeIso).format('ll');
};

/*
 * Format: 'lll'.
 * Example: Aug 16, 2018 8:02 PM.
 */
export const getLocalizedLongDateWithShortMonthAndTime = (dateTimeIso: string) => {
  return `${getLocalizedDayShortMonthAndYear(dateTimeIso)} ${getLocalizedTime(dateTimeIso)}`;
};

/*
 * Format: 'MMMM YYYY'.
 * Example: September 2024
 */
export const getLocalizedMonthAndYear = (date: string | Date | Dayjs) => {
  return getLocalizedDayjsDate(date).format('MMMM YYYY');
};

/*
 * Format: 'L (ddd)'.
 * Example: 08/16/2018 (Thu).
 */
export const getLocalizedDateWithWeekDay = (dateTimeIso: string) => {
  return getLocalizedDayjsDate(dateTimeIso).format('L (ddd)');
};

/*
 * Format: 'L (ddd), LT'.
 * Example: 08/16/2018 (Thu), 2:48 PM.
 */
export const getLocalizedDateWithWeekDayAndTime = (dateTimeIso: string) => {
  return `${getLocalizedDateWithWeekDay(dateTimeIso)}, ${getLocalizedTime(dateTimeIso)}`;
};
