import { HomeAction, NEW_VERSION_RELEASED, ALLOW_TO_WORK_WITH_BROWSER } from '@shared/redux/actions/home-actions';
import { getInitHomeState } from './initState/initHomeState';
import { HomeState } from '@shared/redux/state/homeState';

export function home(state: HomeState = getInitHomeState(), action: HomeAction): HomeState {
  switch (action.type) {
    case NEW_VERSION_RELEASED:
      return {
        ...state,
        newVersionReleased: true,
      };
    case ALLOW_TO_WORK_WITH_BROWSER:
      return {
        ...state,
        allowToWorkWithBrowser: 'user allowed',
      };
    default:
      return state;
  }
}
