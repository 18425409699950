import { allCountries } from 'country-telephone-data';
import parsePhoneNumberFromString, {
  AsYouType,
  CountryCallingCode,
  CountryCode,
  formatNumber,
  getCountryCallingCode,
  isSupportedCountry,
} from 'libphonenumber-js';

import { SupportedCountries, supportedCountryKeys } from '@shared/constants/countries';

export type PossibleCountryCode = '🏳️' | CountryCode;

export const emptyCountry = {
  name: '',
  iso2code: '🏳️',
  callingCode: '',
} as const;

export const countries: PhoneCountry[] = allCountries
  .filter((country: any) => isSupportedCountry(country.iso2.toUpperCase()))
  .map((country: any) => ({
    ...country,
    callingCode: getCountryCallingCode(country.iso2.toUpperCase()),
    iso2code: country.iso2.toUpperCase(),
  }));
countries.unshift(emptyCountry);

export const getFormattedCountryCode = (code: CountryCallingCode): string => (code ? `+${code}` : '');

export const getPhoneNumberWithoutPrefix = (phoneNumber: string, countryCode: string): string => {
  const result = phoneNumber.startsWith('+') && countryCode ? phoneNumber.slice(countryCode.length + 1) : phoneNumber;

  return result.trim();
};

export const findCountry = (countryShortName: string): PhoneCountry => {
  return countries.find((x): boolean => x.iso2code === countryShortName) ?? emptyCountry;
};

export const getAsYouType = (phoneNumber: string, countryCode: any) => {
  const asYouType = new AsYouType(countryCode);
  asYouType.input(phoneNumber);
  return asYouType;
};

export const replaceZeroZeroByPlus = (phoneNumber: string): string => {
  if (phoneNumber?.startsWith('00')) {
    return phoneNumber.replace('00', '+'); // replaces only first match
  }

  return phoneNumber;
};

export const getFormattedPhoneNumberWithoutPrefix = (phoneNumber: string, countryCode: string): string => {
  const formattedPhoneNumber = getFormattedPhoneNumberByCallingCode(phoneNumber, countryCode);
  return getPhoneNumberWithoutPrefix(formattedPhoneNumber, getFormattedCountryCode(countryCode));
};

export const getFormattedPhoneNumberByCallingCode = (phoneNumber: string, callingCountryCode: string): string => {
  if (callingCountryCode === emptyCountry.callingCode) {
    return phoneNumber;
  }

  // If the number is to short we need to format it by hand.
  // Because formatNumber will return an empty string
  if (phoneNumber.length < 2) {
    const formattedPhone = `${getFormattedCountryCode(callingCountryCode)} ${phoneNumber}`;
    return formattedPhone;
  }

  return formatNumber(`${getFormattedCountryCode(callingCountryCode)}${phoneNumber}`, 'International');
};

export const getFullPhoneNumberByIso2CountryCode = (phoneNumber: any, iso2CountryCode?: PossibleCountryCode) => {
  if (!phoneNumber || phoneNumber.number === '') {
    return null;
  }
  if (!isCountryCode(iso2CountryCode)) {
    return phoneNumber;
  }
  const callingCountryCode = getCountryCallingCode(iso2CountryCode) as CountryCallingCode;
  return `${getFormattedCountryCode(callingCountryCode)}${phoneNumber}`;
};

export const getIso2CountryCode = (countryCode: SupportedCountries): PossibleCountryCode => {
  if (countryCode === supportedCountryKeys.Other) {
    return '🏳️';
  }

  return countryCode;
};

export const parsePhoneNumber = (
  phoneNumber: string | null,
  defaultCountryCode: PossibleCountryCode = emptyCountry.iso2code,
) => {
  if (phoneNumber) {
    const parsedPhoneNumber = parsePhoneNumberFromString(phoneNumber);
    if (parsedPhoneNumber) {
      return {
        number: parsedPhoneNumber.nationalNumber as string,
        countryCode: parsedPhoneNumber.country ?? defaultCountryCode,
      };
    }
  }

  return {
    number: '',
    countryCode: defaultCountryCode,
  };
};

export interface PhoneCountry {
  /// <summary>
  /// Country name
  /// </summary>
  name: string;
  /// <summary>
  /// ISO Country Code.
  /// </summary>
  iso2code: CountryCode | '🏳️';
  /// <summary>
  /// The first several numbers of phone number e.g. +44, +33 etc.
  /// </summary>
  callingCode: string;
}

function isCountryCode(iso2CountryCode?: PossibleCountryCode): iso2CountryCode is CountryCode {
  if (!iso2CountryCode || iso2CountryCode === emptyCountry.iso2code) {
    return false;
  }

  return true;
}
