import { all, takeLatest } from 'redux-saga/effects';

import * as AuthActionTypes from '@shared/redux/actions/auth-actions';
import * as LoginSaga from '@shared/redux/sagas/login-saga';
import * as LogoutSaga from '@shared/redux/sagas/logout-saga';
import * as AuthSaga from '@shared/redux/sagas/auth-saga';

function* watchLoginRequest() {
  yield AuthActionTypes.login.watch(LoginSaga.fetchLoginRequest);
}

function* watchLogoutRequest() {
  yield takeLatest(AuthActionTypes.LOGOUT_REQUEST, LogoutSaga.fetchLogoutRequest);
}

function* watchSecondFactorLoginRequest() {
  yield AuthActionTypes.twoFactorLogin.watch(LoginSaga.fetchSecondFactorLoginRequest);
}

function* watchSendForgottenPasswordMail() {
  yield AuthActionTypes.sendForgottenPasswordEmail.watch(AuthSaga.fetchSendForgottenPasswordEmail);
}

function* watchResetPasswordAfterPasswordForgotten() {
  yield AuthActionTypes.resetPassword.watch(AuthSaga.fetchResetPasswordAfterPasswordForgotten);
}

function* watchActivateUserRequest() {
  yield AuthActionTypes.activateUser.watch(AuthSaga.fetchActivateUser);
}

export default function* watchAuthActions() {
  yield all([
    watchLoginRequest(),
    watchLogoutRequest(),
    watchSecondFactorLoginRequest(),
    watchSendForgottenPasswordMail(),
    watchResetPasswordAfterPasswordForgotten(),
    watchActivateUserRequest(),
  ]);
}
