import dayjs from 'dayjs';
import i18next from 'i18next';
import { useEffect, useState } from 'react';

import { setCulture } from './localStorageAccessor';
import { CultureInfo, getStoredOrDefaultCulture } from './user';

const updateStateCallbacks: ((culture: CultureInfo) => void)[] = [];

export function changeLanguage(culture: string, dayJSLocale: string, isCultureUserDefined: boolean) {
  if (i18next.changeLanguage) i18next.changeLanguage(culture);
  dayjs.locale(dayJSLocale);

  setCulture(culture, dayJSLocale, isCultureUserDefined);

  const cultureInfo = { culture, isCultureUserDefined, dayJSLocale };
  updateStateCallbacks.forEach((x) => x(cultureInfo));
}

export const useStoredOrDefaultCulture = () => {
  const [cultureInfo, setCultureInfo] = useState(getStoredOrDefaultCulture());
  useEffect(() => {
    const updateState = (cultureInfo: CultureInfo) => {
      setCultureInfo(cultureInfo);
    };

    updateStateCallbacks.push(updateState);
    return () => {
      const actionIndex = updateStateCallbacks.indexOf(updateState);
      if (actionIndex !== -1) {
        updateStateCallbacks.splice(actionIndex, 1);
      }
    };
  }, []);

  return cultureInfo;
};
