export enum PatientFilterCategory {
  /**
   * No filter all Patients
   */
  All = 0,

  /**
   * Return only patients which have PatientCategories.Unspecified
   */
  Unspecified = 1,

  /**
   * Return only patients which contains PatientCategories.SleepTelemonitoring
   */
  SleepTelemonitoring = 2,

  /**
   * Return only patients which contains PatientCategories.OfflineDataUpload
   */
  OfflineDataUpload = 3,

  /**
   * Return only patients which contains PatientCategories.VentilationTelemonitoring
   */
  VentilationTelemonitoring = 4,
}
