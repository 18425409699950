import globalAxios from 'axios';
import { call, put } from 'redux-saga/effects';

import * as PreregisteredDevicesActionTypes from '@shared/redux/actions/preregisteredDevicesUpload-actions';
import { DevicesApi } from '@shared/api/core-api';
import { handleSagaError } from '@shared/util/handleSagaError';
import { PreregisteredDevicesUploadData } from '@shared/types/devicePreRegistrationTypes';

export function* uploadDevices(action: PreregisteredDevicesActionTypes.UploadDevicesFileRequestAction) {
  try {
    const { file } = action.payload;
    const source = globalAxios.CancelToken.source();
    const uploadInfo: PreregisteredDevicesUploadData[] = yield call(DevicesApi.uploadPreregisteredDevices, file);
    yield put<PreregisteredDevicesActionTypes.UploadDevicesFileStartedAction>({
      type: PreregisteredDevicesActionTypes.UPLOAD_DEVICES_FILE_STARTED,
      payload: {
        cancellationCallback: source.cancel,
      },
    });

    yield put<PreregisteredDevicesActionTypes.UploadDevicesFileSuccessAction>({
      type: PreregisteredDevicesActionTypes.UPLOAD_DEVICES_FILE_SUCCESS,
      payload: {
        uploadInfo,
      },
    });
  } catch (e: any) {
    // Set details as resource key to show not localized error.
    // This is OK because we use preregistration internally.
    const errorDetails = e?.data?.detail;
    yield handleSagaError<typeof PreregisteredDevicesActionTypes.UPLOAD_DEVICES_FILE_FAILURE>({
      type: PreregisteredDevicesActionTypes.UPLOAD_DEVICES_FILE_FAILURE,
      error: e,
      errorResourceKey: errorDetails,
    });
  }
}
