import axios, { AxiosRequestConfig } from 'axios';

import { PATH_TO_SFTP_SERVICE } from '@shared/constants';

/**
 * Contains the remaining lifetime of an SFTP password.
 */
export interface PasswordExpirationDto {
  /**
   * The flag indicating whether the password does not expire.
   * If true, the timeLeft property should not be analyzed.
   * If false, the timeLeft contains the remaining lifetime.
   */
  isUnlimited: boolean;

  /**
   * Remaining lifetime of the password (here dotnet TimeSpan serialized as ISO 8601).
   */
  timeLeft: string;
}

/**
 * Contains the address of the SFTP server.
 */
export interface SftpInfoDto {
  port: number;
  host: string;
}

/** Contains a result of SFTP password generation. */
export interface PasswordUpdatedDto {
  /** The generated password. */
  sftpPassword: string;
}

const defaultOpts: AxiosRequestConfig = {
  baseURL: PATH_TO_SFTP_SERVICE,
};

/**
 * SftpApi - axios API to call UsageSftp service endpoints.
 */
export const SftpApi = {
  /**
   * Creates a password to access the SFTP service.
   *
   * @param {number} [sftpPasswordLifetimeDays] The desired lifetime of the SFTP password in days.
   *                 `null` or `undefined` means that a non-expiring password should be created.
   * @param {AxiosRequestConfig} [options] Overrides HTTP request options.
   */
  async generateSftpPassword(
    sftpPasswordLifetimeDays: number | null | undefined,
    options?: AxiosRequestConfig,
  ): Promise<PasswordUpdatedDto> {
    const response = await axios.post<PasswordUpdatedDto>(
      '/api/password',
      { sftpPasswordLifetimeDays },
      { ...defaultOpts, ...options },
    );
    return response.data;
  },

  /**
   * Gets the remaining time until the SFTP password for the tenant of
   * the current user becomes invalid.
   *
   * @param {AxiosRequestConfig} [options] Overrides HTTP request options.
   */
  async getSftpPasswordDaysLeft(options?: AxiosRequestConfig): Promise<PasswordExpirationDto> {
    const response = await axios.get<PasswordExpirationDto>('/api/password/daysleft', { ...defaultOpts, ...options });
    return response.data;
  },

  /**
   * Revokes the SFTP access for the tenant of the current user.
   *
   * @param {AxiosRequestConfig} [options] Overrides HTTP request options.
   */
  async revokeSftpPassword(options?: AxiosRequestConfig): Promise<void> {
    await axios.delete<void>('/api/password', { ...defaultOpts, ...options });
  },

  /**
   * Gets the host/port of the SFTP service.
   *
   * @param {AxiosRequestConfig} [options] Overrides HTTP request options.
   */
  async getSftpInfo(options?: AxiosRequestConfig): Promise<SftpInfoDto> {
    const response = await axios.get<SftpInfoDto>('/api/info', { ...defaultOpts, ...options });
    return response.data;
  },
};
