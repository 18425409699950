import { all, takeEvery } from 'redux-saga/effects';

import * as UnhandledExceptionActions from '@shared/redux/actions/unhandledException-actions';
import * as ErrorLoggingSaga from '@shared/redux/sagas/errorLogging-saga';

function* watchLogUnhandledErrors() {
  yield takeEvery(UnhandledExceptionActions.LOG_UNHANDLED_EXCEPTION, ErrorLoggingSaga.processLogError);
}

export default function* watchUnhandledExceptionActions() {
  yield all([watchLogUnhandledErrors()]);
}
