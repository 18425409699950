import { Middleware } from 'redux';

import { wait } from '@shared/util/async-helper';
import { RootState } from '@shared/redux/state/root';
import { isAuthenticated } from '@shared/util/user';
import {
  NEW_VERSION_RELEASED,
  NewVersionReleasedAction,
  RELOAD_ALL_TABS,
  ReloadAllTabsAction,
} from '@shared/redux/actions/home-actions';
import { AllActions } from '@shared/redux/actions';
import { reportError } from '@shared/util/sentry';
import { CLIENT_REFRESH, SERVICEWORKER_REFRESH, SKIP_WAITING } from '../../../../serviceWorker/common';

/**
 * This middleware is registered only in production mode.
 * We do not need service worker in development process,
 * see how it is defined in webpack.config.js productionConfig->resolve->alias
 */
const serviceWorkerMiddleware: Middleware<{}, RootState> = ({ dispatch }) => {
  let registration: ServiceWorkerRegistration;

  const registerServiceWorker = async () => {
    // According to src: third_party/blink/renderer/modules/service_worker/service_worker_container.cc
    // It is related to Edge, but looks like it is used in Chrome.
    // Service cannot be initialized until window.frames is defined.
    // And we should not call navigator?.serviceWorker, it can initialize it in invalid state.
    // There we try to solve bug: #24191. Move SW to previous state if it does not help
    while (!window.frames) {
      await wait(500);
    }

    if (!navigator?.serviceWorker?.register || !('SyncManager' in window)) {
      // Browser do not support service worker, simply exit.
      return;
    }

    navigator.serviceWorker.onmessage = (event) => {
      if (event.data && event.data.type === CLIENT_REFRESH) {
        window.location.reload();
      }
    };

    const updateFound = () => {
      if (registration?.installing != null) {
        const newWorker = registration.installing;
        newWorker.addEventListener('statechange', (e) => {
          if (newWorker.state === 'installed') {
            newWorker.postMessage(SKIP_WAITING);
            if (isAuthenticated()) {
              dispatch<NewVersionReleasedAction>({
                type: NEW_VERSION_RELEASED,
              });
            } else {
              dispatch<ReloadAllTabsAction>({
                type: RELOAD_ALL_TABS,
              });
            }
          }
        });
      }
    };

    try {
      const registrationResult = navigator.serviceWorker.register('/sw.js');
      if (!registrationResult) {
        return;
      }

      registration = await registrationResult;

      if (registration.installing) {
        registration.installing.onerror = (e) => {
          reportError(e);
        };
      }

      const ensureInstalled = () => {
        if (registration.installing) {
          // if still installing, wait 100ms and check again.
          window.setTimeout(ensureInstalled, 100);
        } else {
          if (registration.active) {
            registration.active.onerror = (e) => {
              reportError(e);
            };
          }

          // subscribe to the event only if installed.
          registration.addEventListener('updatefound', updateFound);
        }
      };

      ensureInstalled();

      return registrationResult;
    } catch (registrationError: any) {
      if (registrationError?.message !== 'Rejected') {
        // User can reject service worker registration, do not report to sentry
        // it is known action.
        reportError(registrationError);
      }
    }
  };

  registerServiceWorker();

  return (next) => (action: AllActions) => {
    if (action.type === RELOAD_ALL_TABS) {
      registration?.active?.postMessage({
        type: SERVICEWORKER_REFRESH,
      });
    }

    next(action);
  };
};

export default serviceWorkerMiddleware;
