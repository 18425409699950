import * as url from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';

import { PATH_TO_AUTH_API } from '@shared/constants';
import { Configuration } from '@shared/api/base-api/configuration';
import { RequestArgs, postProcessAxiosError } from '@shared/api/base-api';

/**
 * Command containing a verification code to verify a phone number.
 * @export
 * @interface VerifyPhoneCommand
 */
export interface VerifyPhoneCommand {
  /**
   * The verification code.
   * @type {string}
   * @memberof VerifyPhoneCommand
   */
  verificationCode: string;
}

/**
 * PhoneVerificationApi - axios parameter creator
 * @export
 */
export const PhoneVerificationApiAxiosParamCreator = (configuration?: Configuration) => {
  return {
    /**
     *
     * @summary Sends a verification code to the current logged in user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    post(options: any = {}): RequestArgs {
      const localVarPath = '/PhoneVerification/sendCode';
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete (localVarUrlObj as any).search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Verifies the one time password
     * @param {VerifyPhoneCommand} [verifyPhoneCommand] a DTO containing the one time password
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    verifyOtp(verifyPhoneCommand: VerifyPhoneCommand, options: any = {}): RequestArgs {
      const localVarPath = '/PhoneVerification/verifyCode';
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete (localVarUrlObj as any).search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        ('VerifyPhoneCommand' as any) !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(verifyPhoneCommand || {})
        : verifyPhoneCommand || '';

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PhoneVerificationApi - functional programming interface
 * @export
 */
export const PhoneVerificationApiFp = (configuration?: Configuration) => {
  return {
    /**
     *
     * @summary Sends a verification code to the current logged in user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    post(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
      const localVarAxiosArgs = PhoneVerificationApiAxiosParamCreator(configuration).post(options);
      return (axios: AxiosInstance = globalAxios, basePath: string = PATH_TO_AUTH_API) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs).catch(postProcessAxiosError);
      };
    },
    /**
     *
     * @summary Verifies the one time password
     * @param {VerifyPhoneCommand} [verifyPhoneCommand] a DTO containing the one time password
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    verifyOtp(
      verifyPhoneCommand: VerifyPhoneCommand,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
      const localVarAxiosArgs = PhoneVerificationApiAxiosParamCreator(configuration).verifyOtp(
        verifyPhoneCommand,
        options,
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = PATH_TO_AUTH_API) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs).catch(postProcessAxiosError);
      };
    },
  };
};

/**
 * PhoneVerificationApi - factory interface
 * @export
 */
export const PhoneVerificationApiFactory = (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) => {
  return {
    /**
     * Sends a verification code to the current logged in user.
     * @param {*} [options] override http request option.
     */
    post(options?: any) {
      return PhoneVerificationApiFp(configuration).post(options)(axios, basePath);
    },

    /**
     * Verifies the one time password
     * @param {VerifyPhoneCommand} [verifyPhoneCommand]
     * The DTO containing the one time password;
     * @param {*} [options] Override http request option.
     */
    verifyOtp(verifyPhoneCommand: VerifyPhoneCommand, options?: any) {
      return PhoneVerificationApiFp(configuration).verifyOtp(verifyPhoneCommand, options)(axios, basePath);
    },
  };
};
