import { AxiosRequestConfig } from 'axios';
import i18next from 'i18next';

import { getToken } from '@shared/util/localStorageAccessor';

/**
 * This interceptor is used to intercept all http calls via axios,
 * and place token within authorization header.
 */
export const authAxiosInterceptor = () => {
  const insertAuthorizationHeader = (config: AxiosRequestConfig) => {
    const token = getToken();
    if (token) {
      if (!config.headers) {
        config.headers = {};
      }
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  };

  const insertAcceptLanguageHeader = (config: AxiosRequestConfig) => {
    if (!config.headers) {
      config.headers = {};
    }
    config.headers['Accept-Language'] = i18next.language;
    return config;
  };

  return async (config: AxiosRequestConfig) => {
    insertAuthorizationHeader(config);
    insertAcceptLanguageHeader(config);
    return config;
  };
};
