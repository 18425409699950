import { Action } from 'typesafe-actions';

export const ALLOW_TO_WORK_WITH_BROWSER = 'ALLOW_TO_WORK_WITH_BROWSER';

export const NEW_VERSION_RELEASED = 'NEW_VERSION_RELEASED';
export const RELOAD_ALL_TABS = 'RELOAD_ALL_TABS';

export type AllowToWorkWithBrowser = Action<typeof ALLOW_TO_WORK_WITH_BROWSER>;
export type NewVersionReleasedAction = Action<typeof NEW_VERSION_RELEASED>;
export type ReloadAllTabsAction = Action<typeof RELOAD_ALL_TABS>;

export type HomeAction = AllowToWorkWithBrowser | NewVersionReleasedAction | ReloadAllTabsAction;
