import { call, put } from 'redux-saga/effects';

import { requestLocalisation } from '@shared/redux/actions/localisation-actions';
import * as TenantActionTypes from '@shared/redux/actions/tenant-actions';
import { ActionWithPayload } from '@shared/redux/actions/typings';
import { getCultures } from '@shared/util/cultures';
import { OrganisationApi } from '@shared/api/core-api';
import { HttpStatusCodes } from '@shared/constants/httpStatusCodes';
import { handleSagaError } from '@shared/util/handleSagaError';

export function* verifyTenant(action: ActionWithPayload<{ tenant: string }>) {
  try {
    const { tenant } = action.payload;

    const {
        exists,
        isExternalLoginUsed,
        language
     }: { exists: boolean; isExternalLoginUsed: boolean, language?: string } =
        yield call(OrganisationApi.getOrganisationSummary, tenant);

    const lowerCaseLanguage = language?.toLocaleLowerCase() ?? '';
    const dayJSLocale =
      getCultures().find((x) => x.languageCulture.toLocaleLowerCase() === lowerCaseLanguage)?.dayJSLocale ?? 'en-gb';
    const isCultureUserDefined = false;
    yield put(requestLocalisation(language ?? 'en-GB', dayJSLocale, isCultureUserDefined));

    yield put({
      type: TenantActionTypes.TENANT_VERIFY_REQUEST_SUCCESS,
      payload: {
        exists,
        organisationLanguage: language,
        isExternalLoginUsed
      },
    });
  } catch (e: any) {
    if (e?.status === HttpStatusCodes.NotFound) {
      yield put({
        type: TenantActionTypes.TENANT_VERIFY_REQUEST_SUCCESS,
        payload: {
          exists: false,
          organisationLanguage: '',
        },
      });
    } else {
      yield handleSagaError({
        type: TenantActionTypes.TENANT_VERIFY_REQUEST_FAILURE,
        error: e,
      });
    }
  }
}
