import { call, put, select } from 'redux-saga/effects';
import globalAxios from 'axios';

import { SdCardFile } from '@shared/redux/reducers/sdCardUpload-reducer';
import { RootState } from '@shared/redux/state/root';
import { RequestFailedException } from '@shared/api/requestFailedException';
import { handleSagaError } from '@shared/util/handleSagaError';
import { reportError } from '@shared/util/sentry';
import {
  cancelFileUploadSuccessAction,
  fetchSupportedFileExtensionsFailureAction,
  fetchSupportedFileExtensionsSuccessAction,
  uploadFileFailureAction,
  UploadFileRequestAction,
  uploadFileStartedAction,
  uploadFileSuccessAction,
} from '@shared/redux/actions/sdCardUpload-actions';
import { SdCardApi, UploadAcceptedDto } from '@shared/api/sd-upload-api/sd-upload-api';

export function* cancelUpload() {
  const uploadingFiles: SdCardFile[] = yield select((state: RootState) => state.sdCardUpload.filesToUpload);

  if (uploadingFiles !== undefined) {
    uploadingFiles.forEach((file: SdCardFile): void => file.cancellationCallback && file.cancellationCallback());
  }

  yield put(cancelFileUploadSuccessAction());
}

export function* uploadStatistics(action: UploadFileRequestAction) {
  try {
    const { file, progressCallback, id } = action.payload;
    const source = globalAxios.CancelToken.source();
    const config = {
      onUploadProgress: progressCallback,
      cancelToken: source.token,
    };

    const uploadInfo: UploadAcceptedDto = yield call(SdCardApi.upload, file, config);

    yield put(uploadFileStartedAction(id, source.cancel));

    yield put(uploadFileSuccessAction(uploadInfo, id));
  } catch (e) {
    const exception = e as RequestFailedException;
    if (!exception.error?.resourceKey) {
      // UI has custom error renderer for file upload
      // so report an error in case of unknown error.
      reportError(e);
    }
    const resourceKey = exception.error?.resourceKey ?? 'Error_UploadingSdCard';
    yield put(uploadFileFailureAction(action.payload.id, resourceKey));
  }
}

export function* fetchSupportedFileExtensions(): any {
  try {
    const response = yield call(SdCardApi.getSupportedFileExtensions);

    yield put(fetchSupportedFileExtensionsSuccessAction(response));
  } catch (e) {
    yield handleSagaError(fetchSupportedFileExtensionsFailureAction(e));
  }
}
