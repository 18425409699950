import { PatientActions } from './patientActions/patient-actions';
import { UploadDevicesActions } from './preregisteredDevicesUpload-actions';
import { AuthActions } from './auth-actions';
import { HomeAction } from './home-actions';
import { ReduxFormActions } from './redux-form-actions';
import { OrganisationActions } from './organisation-actions';
import { SettingsActions } from './settings-actions';
import { UsersActions } from './users-actions';
import { ReportActions } from './report-actions';
import { AssignDeviceActions } from './assign-device-actions';
import { PhoneVerificationActions } from './phoneVerification-actions';
import { ChronometerTickActions } from './chronometer-actions';

export const CLEAR_STORE = 'CLEAR_STORE';

export const clearStore = () => ({
  type: CLEAR_STORE,
});

export type AllActions =
  | AuthActions
  | ChronometerTickActions
  | PatientActions
  | UploadDevicesActions
  | HomeAction
  | ReduxFormActions
  | OrganisationActions
  | SettingsActions
  | UsersActions
  | PhoneVerificationActions
  | ReportActions
  | AssignDeviceActions;
