import { reportError } from '@shared/util/sentry';

/**
 * Process LOG_UNHANDLED_EXCEPTION action and writes the Err Object into the console
 * @export
 * @param {Object} action
 * @param {string} action.exceptionType - The Original Type which raise the Exception
 * @param {object} action.err - The Error object
 */
export function* processLogError({ exceptionType, err }: any): IterableIterator<any> {
  // Write error into log then we will see an error in UI tests if it will occur.
  console.error(`Error of type: ${exceptionType}`, err);
  reportError(err);
}
