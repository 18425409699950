import '@entities/App/ScheduledNotification/ScheduledNotification.css';

export const validate = () => {
  // check if there is access to local storage
  try {
    const storedKey = 'temp';
    window.localStorage.setItem(storedKey, storedKey);
    const storedItem = window.localStorage.getItem(storedKey);
    if (storedItem != storedKey) {
      window.location.replace('/not-supported');
    }
    window.localStorage.removeItem(storedKey);
  } catch {
    window.location.replace('/not-supported');
    // This exception throw before Sentry initialization so we will not have it in sentry, only in console.
    throw new Error('Browser is not supported stop script execution');
  }
  if (window.location.protocol === 'file:') {
    const infoText = document.createElement('div');
    infoText.className = 'scheduled-notifications';
    // i18n is not initialized here, so we provide warning string only in english.
    infoText.innerHTML = `<ul class='scheduled-notifications__notifications-list'>
      <li class='scheduled-notifications__notification'>
        <p class='scheduled-notifications__message'>
          prisma CLOUD cannot be loaded from a locally saved file. Please access the website through a supported browser using a valid URL to ensure proper functionality.
        </p>
      </li>
    </ul>`;
    document.body.insertBefore(infoText, document.body.firstChild);
    throw new Error('Browser run from local folder');
  }
};

validate();
