/* Defines URIs for types of errors specific to all services.
See the Problem Details spec https://tools.ietf.org/html/rfc7807#section-3.1  */
export enum ErrorTypes {
  ReportGenerator_NoTherapyDays = 'urn:lmt:stats-gateway:NoTherapyDays',
  SdCard_BadUploadRequest = 'urn:lmt:sd:BadUploadRequest',
  SdCard_MissingTherapyStatistics = 'urn:lmt:sd:MissingTherapyStatistics',
  SdCard_AllStatisticsBeforeLimit = 'urn:lmt:sd:AllStatisticsBeforeLimit',
  SdCard_AllStatisticsAfterLimit = 'urn:lmt:sd:AllStatisticsAfterLimit',
  SdCard_InvalidP2_8FirmwareVersion = 'urn:lmt:sd:InvalidP2_8FirmwareVersion',
  SdCard_InvalidP2_8FileExtension = 'urn:lmt:sd:InvalidP2_8FileExtension',
  SdCard_InvalidP3FirmwareVersion = 'urn:lmt:sd:InvalidP3FirmwareVersion',
  SdCard_InvalidP3FileExtension = 'urn:lmt:sd:InvalidP3FileExtension',
  SdCard_DeviceDoesNotBelongToOrganisation = 'urn:lmt:sd:DeviceDoesNotBelongToOrganisation',
  SdCard_InactivePic = 'urn:lmt:sd:InactivePic',
  ReportGenerator_PeriodTooLong = 'urn:lmt:stats-gateway:PeriodTooLong',
  TooManyRequests = 'urn:lmt:too-many-requests',
  AccessDenied = 'urn:lmt:access-denied',
  DeleteOrganisationForbidden = 'urn:lmt:core:DeleteForbiddenOrganisation',
}
