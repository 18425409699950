import { ComponentType, lazy, LazyExoticComponent } from 'react';

/**
 * Helper which retries to run a given async function if it is failed.
 */
export function retry<T>(fn: () => Promise<T>, retriesLeft = 3, intervalInMillis = 1000): Promise<T> {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((reason) => {
        if (retriesLeft === 0) {
          reject(reason);
          return;
        }

        setTimeout(() => {
          retry<T>(fn, retriesLeft - 1, intervalInMillis).then(resolve, reject);
        }, intervalInMillis);
      });
  });
}

/**
 * Helper which retries to load a lazy component if it is failed.
 */
export function lazyRetry<T extends ComponentType<any>>(
  factory: () => Promise<{ default: T }>,
): LazyExoticComponent<T> {
  return lazy(() => retry(factory));
}
