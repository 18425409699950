import { AxiosRequestConfig } from 'axios';

import { BaseAPI } from '@shared/api/base-api';
import { HttpStatusCodes } from '@shared/constants/httpStatusCodes';
import { queryBuilder } from '@shared/util/url-helper';

export interface IActivePicDto {
  pic: string;
  firstName: string;
  lastName: string;
  email: string;
}

export interface IPicUserInfoDto {
  firstName: string;
  lastName: string;
  email: string;
}

/**
 * PicApi - factory interface
 * @export
 */
export const PicApiFactory = (baseApi: BaseAPI) => {
  return {
    /**
     * Deactivates the PIC of user.
     * @param {number} [userId] ID of the user, whose PIC should be deactivated.
     */
    async delete(userId: string): Promise<void> {
      await baseApi.delete('/api/pics', { userId });
    },

    /**
     * Reads the active pic of the given user.
     * @param {number} [userId] ID of the user, whose PIC should be read.
     * @returns The active PIC (if any exists), encoded as base64.
     */
    async getPic(userId: string): Promise<string | null> {
      const result = await baseApi.get<string>('/api/pics', { userId });
      if (result?.status === HttpStatusCodes.NoContent) {
        return null;
      }

      return result.data;
    },

    /**
     * Creates a pic for a user.
     * @param {number} [userId] ID of the user, for whom the PIC should be created.
     */
    async createPickForUser(userId: string): Promise<string> {
      const result = await baseApi.post<string>('/api/pics', { userId });
      return result.data;
    },

    /**
     * Gets the info about the user for whom the current user intends to
     * download a PIC+firmware bundle.
     * @param {string} [picUserId] ID of the user whose info should be returned.
     */
    async getUserInfo(picUserId: string, options?: AxiosRequestConfig): Promise<IPicUserInfoDto> {
      const query = queryBuilder().append('picUserId', picUserId).build();
      const response = await baseApi.get<IPicUserInfoDto>(`/api/pics/get-user-for-pic-fw-bundle${query}`, {}, options);
      return response.data;
    },

    /**
     * Gets an active pic of the user or creates and returns a new one.
     * @param {number} [userId] ID of the user, for whom the PIC should be created
     */
    async getActiveOrCreatePicForUser(userId: string): Promise<IActivePicDto> {
      const result = await baseApi.put<IActivePicDto>(`/api/pics/${userId}`);
      return result.data;
    },
  };
};
