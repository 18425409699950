import { Action } from 'redux';

import {
  PatientSharedWithChangedPayload,
  SetActivePatientListTabPayload,
  TogglePatientExpandPayload,
} from '@shared/redux/actions/payloadTypes/patientActionPayloads';
import { ActionWithPayload } from '@shared/redux/actions/typings';
import { ExpandedPatientRowKeyState, SearchQueryState } from '@shared/redux/state/patients';
import { PatientListTabIds } from '@shared/types/patientListTypes';

export const REMOVE_PATIENT_SUCCESS = 'REMOVE_PATIENT_SUCCESS';

export const SET_ACTIVE_PATIENT_LIST_TAB = 'SET_ACTIVE_PATIENT_LIST_TAB';

export const TELEMONITORING_CHANGE_PATIENTS_SORTING = 'TELEMONITORING_CHANGE_PATIENTS_SORTING';
export const VENTILATION_CHANGE_PATIENTS_SORTING = 'VENTILATION_CHANGE_PATIENTS_SORTING';

export const PATIENT_SHARED_WITH_CHANGED = 'PATIENT_SHARED_WITH_CHANGED';

export const TELEMONITORING_TOGGLE_PATIENT_EXPAND = 'TELEMONITORING_TOGGLE_PATIENT_EXPAND';

export const NIV_SET_EXPANDED_PATIENT_ID = 'NIV_SET_EXPANDED_PATIENT_ID';

export const RESET_PATIENT_FORM = 'RESET_PATIENT_FORM';

export const LOCK_PATIENT_FORM = 'LOCK_PATIENT_FORM';
export const UNLOCK_PATIENT_FORM = 'UNLOCK_PATIENT_FORM';

export const SET_PATIENTS_SEARCH_QUERY = 'SET_PATIENTS_SEARCH_QUERY';

export const IGNORE_PIE_CHART = 'IGNORE_PIE_CHART';
export const UNIGNORE_PIE_CHART = 'UNIGNORE_PIE_CHART';

export const IGNORE_PIE_CHART_FAILED = 'IGNORE_PIE_CHART_FAILED';
export const UNIGNORE_PIE_CHART_FAILED = 'UNIGNORE_PIE_CHART_FAILED';

export const TOGGLE_SCHEDULED_PATIENTS = 'TOGGLE_SCHEDULED_PATIENTS';

export const removePatientSuccess = () => ({
  type: REMOVE_PATIENT_SUCCESS,
  successNotification: { resourceKey: 'Notification_Patient_Deleted' },
});

export const setPatientsSearchQuery = (searchQuery: SearchQueryState) => ({
  type: SET_PATIENTS_SEARCH_QUERY as typeof SET_PATIENTS_SEARCH_QUERY,
  payload: { searchQuery },
});

export const toggleScheduledPatients = (activeTab: PatientListTabIds | null, expanded: boolean) => ({
  type: TOGGLE_SCHEDULED_PATIENTS as typeof TOGGLE_SCHEDULED_PATIENTS,
  payload: {
    activeTab,
    expanded,
  },
});

export type SetPatientsSearchQuery = ReturnType<typeof setPatientsSearchQuery>;

export type SetActivePatientListTab = ActionWithPayload<
  SetActivePatientListTabPayload,
  typeof SET_ACTIVE_PATIENT_LIST_TAB
>;
export type PatientSharedWithChangedAction = ActionWithPayload<
  PatientSharedWithChangedPayload,
  typeof PATIENT_SHARED_WITH_CHANGED
>;
export type ResetPatientFormAction = Action<typeof RESET_PATIENT_FORM>;
export type LockPatientFormAction = Action<typeof LOCK_PATIENT_FORM>;
export type UnlockPatientFormAction = Action<typeof UNLOCK_PATIENT_FORM>;

export type TelemonitoringTogglePatientExpandAction = ActionWithPayload<
  TogglePatientExpandPayload,
  typeof TELEMONITORING_TOGGLE_PATIENT_EXPAND
>;
export type SetNivExpandedPatientId = ActionWithPayload<ExpandedPatientRowKeyState, typeof NIV_SET_EXPANDED_PATIENT_ID>;

export type ToggleScheduledPatientsAction = ReturnType<typeof toggleScheduledPatients>;

export type PatientActions =
  | PatientSharedWithChangedAction
  | SetPatientsSearchQuery
  | ResetPatientFormAction
  | LockPatientFormAction
  | UnlockPatientFormAction
  | SetNivExpandedPatientId
  | TelemonitoringTogglePatientExpandAction
  | SetActivePatientListTab
  | ToggleScheduledPatientsAction;
