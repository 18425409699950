import * as url from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';

import { Configuration } from '@shared/api/base-api/configuration';
import { RequestArgs, postProcessAxiosError } from '@shared/api/base-api';
import { LogoutReason, PATH_TO_AUTH_API } from '@shared/constants';

/**
 * A command to confirm an account after registration,
 * implying the password was already set (e.g. in PatientApp)
 * @export
 * @interface AccountConfirmationWithoutPasswordContext
 */
export interface AccountConfirmationWithoutPasswordContext {
  /**
   * The user id, for which the verification should be done.
   * @type {string}
   * @memberof AccountConfirmationWithoutPasswordContext
   */
  userId: string;
  /**
   * The activation code to verify that the user actually received the email.
   * @type {string}
   * @memberof AccountConfirmationWithoutPasswordContext
   */
  code: string;
}

/**
 * A command to confirm an account after registration
 * @export
 * @interface AccountConfirmationContext
 */
export interface AccountConfirmationContext {
  /**
   * The password to set
   * @type {string}
   * @memberof AccountConfirmationContext
   */
  password: string;
  /**
   * The user id, for which the verification should be done.
   * @type {string}
   * @memberof AccountConfirmationContext
   */
  userId: string;
  /**
   * The activation code to verify that the user actually received the email.
   * @type {string}
   * @memberof AccountConfirmationContext
   */
  code: string;

  /**
   * Flag indicating that user confirmed agreement to terms of use.
   */
  areTermsOfUseSigned: boolean;
}

/**
 * AccountApi - axios parameter creator
 * @export
 */
export const AccountApiAxiosParamCreator = (configuration?: Configuration) => {
  return {
    /**
     *
     * @summary Confirms a user account without providing a password.
     * @param {AccountConfirmationWithoutPasswordContext} [context]
     * a DTO containing userID and activation code.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    confirmWithoutPassword(context?: AccountConfirmationWithoutPasswordContext, options: any = {}): RequestArgs {
      const localVarPath = '/user/confirm/nopassword';
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete (localVarUrlObj as any).search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        ('AccountConfirmationWithoutPasswordContext' as any) !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization ? JSON.stringify(context || {}) : context || '';

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Confirms a user account.
     * @param {AccountConfirmationContext} [accountConfirmationContext]
     * a DTO containing password, the userID and the activation code.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    post(accountConfirmationContext?: AccountConfirmationContext, options: any = {}): RequestArgs {
      const localVarPath = '/user/confirm';
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete (localVarUrlObj as any).search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const needsSerialization =
        ('AccountConfirmationContext' as any) !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(accountConfirmationContext || {})
        : accountConfirmationContext || '';

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },

    /**
     * Notifies backend that user needs to be logged out due to
     * specified reason.
     * @param logoutReason
     * @param options Overrides HTTP request options.
     */
    logout(reason: LogoutReason, options: any = {}): RequestArgs {
      const localVarPath = '/connect/logout';
      const localVarUrlObj = url.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete (localVarUrlObj as any).search;
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...options.headers };
      const form = new URLSearchParams({
        logoutReason: reason,
      });

      localVarRequestOptions.data = form;

      return {
        url: url.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AccountApi - functional programming interface
 * @export
 */
export const AccountApiFp = (configuration?: Configuration) => {
  return {
    /**
     *
     * @summary Confirms a user account without providing a password.
     * @param {AccountConfirmationWithoutPasswordContext} [context]
     * a DTO containing userID and activation code.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    confirmWithoutPassword(
      context?: AccountConfirmationWithoutPasswordContext,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
      const localVarAxiosArgs = AccountApiAxiosParamCreator(configuration).confirmWithoutPassword(context, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = PATH_TO_AUTH_API) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs).catch(postProcessAxiosError);
      };
    },
    /**
     *
     * @summary Confirms a user account.
     * @param {AccountConfirmationContext} [accountConfirmationContext]
     * a DTO containing password, the userID and the activation code.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    post(
      accountConfirmationContext?: AccountConfirmationContext,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
      const localVarAxiosArgs = AccountApiAxiosParamCreator(configuration).post(accountConfirmationContext, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = PATH_TO_AUTH_API) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs).catch(postProcessAxiosError);
      };
    },

    /**
     * Notifies backend that user needs to be logged out due to
     * specified reason.
     * @param logoutReason
     * @param options Overrides HTTP request options.
     */
    logout(
      logoutReason: LogoutReason,
      options?: any,
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
      const localVarAxiosArgs = AccountApiAxiosParamCreator(configuration).logout(logoutReason, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = PATH_TO_AUTH_API) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs).catch(postProcessAxiosError);
      };
    },
  };
};

/**
 * AccountApi - factory interface
 * @export
 */
export const AccountApiFactory = (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) => {
  return {
    /**
     *
     * Confirms a user account without providing a password.
     * @param {AccountConfirmationWithoutPasswordContext} [context]
     * a DTO containing userID and activation code.
     * @param {*} [options] Override http request option.
     */
    confirmWithoutPassword(context?: AccountConfirmationWithoutPasswordContext, options?: any) {
      return AccountApiFp(configuration).confirmWithoutPassword(context, options)(axios, basePath);
    },
    /**
     *
     * Confirms a user account.
     * @param {AccountConfirmationContext} [context]
     * a DTO containing password, the user id and the activation code;
     * @param {*} [options] override http request option.
     */
    confirm(context?: AccountConfirmationContext, options?: any) {
      return AccountApiFp(configuration).post(context, options)(axios, basePath);
    },

    /**
     * Notifies backend that user needs to be logged out due to
     * specified reason.
     * @param logoutReason
     * @param options Overrides HTTP request options.
     */
    logout(logoutReason: LogoutReason, options?: any) {
      return AccountApiFp(configuration).logout(logoutReason, options)(axios, basePath);
    },
  };
};
