import { RequestState } from '@shared/constants/request';
import * as TenantActionTypes from '@shared/redux/actions/tenant-actions';
import { initTenantState } from './initState/initTenantState';
import { ActionWithPayload } from '@shared/redux/actions/typings';
import { TenantState } from '@shared/redux/state/tenantState';

export default function tenant(state: TenantState = initTenantState, action: ActionWithPayload<any>) {
  switch (action.type) {
    case TenantActionTypes.TENANT_VERIFY_REQUEST:
      return {
        ...state,
        requestState: RequestState.PENDING,
      };

    case TenantActionTypes.TENANT_VERIFY_REQUEST_SUCCESS:
      return {
        exists: action.payload.exists,
        isExternalLoginUsed: action.payload.isExternalLoginUsed,
        impossibleToVerify: false,
        requestState: RequestState.SUCCESS,
        organisationLanguage: action.payload.organisationLanguage,
      };

    case TenantActionTypes.TENANT_VERIFY_REQUEST_FAILURE:
      return {
        exists: false,
        impossibleToVerify: true,
        requestState: RequestState.FAILED,
        organisationLanguage: '',
        isExternalLoginUsed: false,
      };

    default:
      return state;
  }
}
