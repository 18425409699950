import enumExtensions from '@shared/util/enum';
import { UserRoleTypes } from '@shared/constants/UserRoleTypes';
import * as PhoneVerificationActions from '@shared/redux/actions/phoneVerification-actions';
import { RequestState } from '@shared/constants/request';
import { AccountState } from '@shared/redux/state/account';
import { initialAccountState } from './initState/initAccountState';
import {
  ACCOUNT_DETAILS_REQUEST,
  ACCOUNT_DETAILS_REQUEST_FAILED,
  ACCOUNT_DETAILS_REQUEST_SUCCESS,
  UsersActions,
} from '@shared/redux/actions/users-actions';

export default function account(
  state: AccountState = initialAccountState,
  action: UsersActions | PhoneVerificationActions.PhoneVerificationActions,
): AccountState {
  switch (action.type) {
    case ACCOUNT_DETAILS_REQUEST:
      return {
        ...state,
        requestState: RequestState.PENDING,
      };

    case ACCOUNT_DETAILS_REQUEST_SUCCESS:
      return {
        ...state,
        details: action.payload,
        isOrganisationManager: enumExtensions.hasFlag(action.payload.userRoles, UserRoleTypes.OrganisationManager),
        requestState: RequestState.SUCCESS,
      };

    case ACCOUNT_DETAILS_REQUEST_FAILED:
      return {
        ...state,
        details: null,
        error: action.payload,
        requestState: RequestState.FAILED,
      };

    case PhoneVerificationActions.sendVerificationSms.SUCCESS:
      return {
        ...state,
        isCodeSent: true,
      };

    case PhoneVerificationActions.sendVerificationSms.FAILURE:
      return {
        ...state,
        isCodeSent: false,
      };
    case PhoneVerificationActions.verifyCode.SUCCESS:
      if (state.requestState === RequestState.SUCCESS) {
        return {
          ...state,
          details: {
            ...state.details,
            phoneNumberConfirmed: true,
          },
          isCodeSent: false,
        };
      } else {
        return {
          ...state,
          isCodeSent: false,
        };
      }

    default:
      return state;
  }
}
