import { match } from 'ts-pattern';

import { buttonClasses, ButtonProps, Components, Theme } from '@mui/material';

type MuiButtonTheme = Components<Theme>['MuiButton'];
const getPalette = (theme: Theme, ownerState: ButtonProps) =>
  theme.palette[ownerState.color === 'inherit' ? 'primary' : ownerState.color ?? 'primary'];

/* View of all the button styles can be found here:
    https://prismacode.visualstudio.com/prismacloud/_wiki/wikis/prismacloud.wiki/2611/MUI-theme# */
export const buttonTheme: MuiButtonTheme = {
  defaultProps: {
    variant: 'contained',
  },
  styleOverrides: {
    root: ({ ownerState, theme }) => ({
      height: match(ownerState.size)
        .with('medium', () => 32)
        .with('large', () => 38)
        .with('small', () => 32)
        .with(undefined, () => 32)
        .exhaustive(),
      transition: theme.transitions.create('all', { duration: theme.transitions.duration.shortest }),
      fontSize: match(ownerState.size)
        .with('medium', () => '14px')
        .with('large', () => '18px')
        .with('small', () => '12px')
        .with(undefined, () => '14px')
        .exhaustive(),
      fontWeight: match(ownerState.size)
        .with('medium', () => 300)
        .with('large', () => 700)
        .with('small', () => 300)
        .with(undefined, () => 300)
        .exhaustive(),
      borderRadius: 4,
      color: match(ownerState)
        .with({ variant: 'outlined' }, () =>
          match(ownerState)
            .with({ color: 'secondary' }, () => theme.palette.grey[600])
            .otherwise(() => getPalette(theme, ownerState).dark),
        )
        .with({ variant: 'contained' }, () =>
          match(ownerState)
            .with({ color: 'primary' }, () => '#fff')
            .with({ color: 'error' }, () => '#fff')
            .otherwise(() => getPalette(theme, ownerState).contrastText),
        )
        .otherwise(() => getPalette(theme, ownerState).contrastText),
      backgroundColor: match(ownerState)
        .with({ variant: 'outlined' }, () => '#fff')
        .with({ variant: 'text' }, () => 'transparent')
        .otherwise(() => getPalette(theme, ownerState).main),
      textTransform: 'none',
      boxShadow: 'none',
      border: match(ownerState)
        .with({ variant: 'outlined' }, () => `1px solid ${getPalette(theme, ownerState).dark}`)
        .otherwise(() => '0px'),
      ['&:hover']: {
        backgroundColor: match(ownerState)
          .with({ variant: 'contained' }, () => getPalette(theme, ownerState).dark)
          .otherwise(() => getPalette(theme, ownerState).light),
        color: match(ownerState)
          .with({ variant: 'text' }, () => '#fff')
          .with({ variant: 'contained' }, () => '#fff')
          .with({ variant: 'outlined' }, () => getPalette(theme, ownerState).dark)
          .otherwise(() => getPalette(theme, ownerState).contrastText),
        boxShadow: 'none',
      },
      [`&.${buttonClasses.disabled}`]: {
        ...(ownerState.variant === 'contained' && {
          color: '#fff',
          backgroundColor: theme.palette.action.disabled,
        }),
      },
    }),
  },
};
