import { Action, combineReducers } from 'redux';

import * as UsersActionTypes from '@shared/redux/actions/users-actions';

const userAccountFormInitialState = {
  isFormLocked: true,
  isEditModeEnabled: false,
};

function userAccountForm(state = userAccountFormInitialState, action: Action) {
  switch (action.type) {
    case UsersActionTypes.LOCK_USER_ACCOUNT_FORM: {
      return {
        ...state,
        isFormLocked: true,
      };
    }

    case UsersActionTypes.UNLOCK_USER_ACCOUNT_FORM: {
      return {
        ...state,
        isFormLocked: false,
      };
    }

    case UsersActionTypes.ENABLE_USER_ACCOUNT_FORM_EDIT_BUTTONS: {
      return {
        ...state,
        isEditModeEnabled: true,
      };
    }

    case UsersActionTypes.DISABLE_USER_ACCOUNT_FORM_EDIT_BUTTONS: {
      return {
        ...state,
        isEditModeEnabled: false,
      };
    }

    default:
      return state;
  }
}

const reduxForms = combineReducers({
  userAccountForm,
});

export default reduxForms;
