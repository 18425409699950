import { createFormAction } from '@shared/util/createFormAction';
import { VerifyPhoneCommand } from '@shared/api/auth-api/phone-verification-api';

export const sendVerificationSms = createFormAction('SEND_VERIFICATION_SMS')<any, void>();
export const verifyCode = createFormAction('VERIFY_CODE')<VerifyPhoneCommand, void>();

export const OPEN_PHONE_VERIFICATION_MODAL = 'OPEN_PHONE_VERIFICATION_MODAL';
export const CLOSE_PHONE_VERIFICATION_MODAL = 'CLOSE_PHONE_VERIFICATION_MODAL';

export type SendVerificationSmsRequestAction = ReturnType<typeof sendVerificationSms.request>;
export type SendVerificationSmsSuccessAction = ReturnType<typeof sendVerificationSms.success>;
export type SendVerificationSmsFailureAction = ReturnType<typeof sendVerificationSms.failure>;
export type VerifyCodeRequestAction = ReturnType<typeof verifyCode.request>;
export type VerifyCodeSuccessAction = ReturnType<typeof verifyCode.success>;
export type VerifyCodeFailureAction = ReturnType<typeof verifyCode.failure>;

export type PhoneVerificationActions =
  | SendVerificationSmsRequestAction
  | SendVerificationSmsSuccessAction
  | SendVerificationSmsFailureAction
  | VerifyCodeRequestAction
  | VerifyCodeSuccessAction
  | VerifyCodeFailureAction;
