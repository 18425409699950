import i18next, { TFunction } from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import sprintf from 'i18next-sprintf-postprocessor';
import LanguageDetector from 'i18next-browser-languagedetector';

import { PATH_TO_WEBAPP_NGINX } from '@shared/constants';

export const integrateLocalization = (stageVersion: string): Promise<TFunction> => {
  return i18next
    .use(sprintf)
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      ns: ['translation', 'configuration', 'deviceLocalization'],
      defaultNS: ['translation', 'deviceLocalization'],
      backend: {
        loadPath: (lngs: string[], namespaces: string[]) => {
          switch (namespaces[0]) {
            case 'configuration':
              return `${PATH_TO_WEBAPP_NGINX}/configs/configuration.${lngs}.json`;
            case 'deviceLocalization':
              return `${PATH_TO_WEBAPP_NGINX}/deviceLocalization/device_localization.${lngs}.json`;
            default:
              return `${PATH_TO_WEBAPP_NGINX}/dictionaries/localization.${lngs}.json`;
          }
        },
        crossDomain: true,
        queryStringParams: { v: stageVersion },
      },
      fallbackLng: 'en',
      load: 'currentOnly',
      interpolation: {
        escapeValue: false, // not needed for react!!,
        skipOnVariables: false, // default used for v20.x.x
      },
      react: {
        useSuspense: false,
        nsMode: 'fallback',
      },
      compatibilityJSON: 'v3', // default in v20.x.x
    });
};
