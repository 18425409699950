import { ActionWithPayload } from '@shared/redux/actions/typings';
import { put } from 'redux-saga/effects';

import { call, select } from 'redux-saga-test-plan/matchers';
import { logoutSuccess, LOGOUT_FAILURE } from '@shared/redux/actions/auth-actions';
import { RootState } from '@shared/redux/state/root';
import { LogoutReason } from '@shared/constants';
import { AccountApi } from '@shared/api/auth-api';
import { HttpStatusCodes } from '@shared/constants/httpStatusCodes';
import { handleSagaError } from '@shared/util/handleSagaError';

export interface LogoutRequestPayload {
  reason?: LogoutReason;
}

export function* fetchLogoutRequest(action: ActionWithPayload<LogoutRequestPayload>) {
  try {
    const isAuthorized: boolean = yield select((state: RootState) => state.auth.isAuthenticated);
    if (!isAuthorized) {
      return;
    }

    const reason = action?.payload?.reason ?? LogoutReason.Unknown;
    try {
      yield call(AccountApi.logout, reason);
    } catch (e: any) {
      // there is no error if we do logout and user is not authorized.
      if (e?.request?.status !== HttpStatusCodes.Unauthorized) {
        throw e;
      }
    }

    yield put(logoutSuccess(reason));
  } catch (e) {
    yield handleSagaError({
      type: LOGOUT_FAILURE,
      error: e,
    });
  }
}
