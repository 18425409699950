import { createStore, applyMiddleware, compose } from 'redux';
import { createBrowserHistory } from 'history';
import createSagaMiddleware from 'redux-saga';
import storeEnhancer from 'redux-history-transitions';

import middleware from '@app/store/middleware';
import appReducer from '@shared/redux/reducers';
import rootSaga from '@shared/redux/sagas';
import { formActionSaga } from '@shared/util/createFormAction';
import { reportError } from '@shared/util/sentry';
export const history = createBrowserHistory();
const reduxDevTools = window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();

const sagaMiddleware = createSagaMiddleware({
  onError(error) {
    const message = error.stack || error.toString();
    // eslint-disable-next-line no-console
    console.error(message);
    reportError(error);
  },
});

const finalCreateStore = compose(applyMiddleware(...middleware, sagaMiddleware), storeEnhancer(history))(createStore);

export default function initConfiguration() {
  const store = finalCreateStore(appReducer(history), reduxDevTools);
  sagaMiddleware.run(rootSaga);
  sagaMiddleware.run(formActionSaga);

  return { store, history };
}
