import { takeLatest, all } from 'redux-saga/effects';

import { ALLOW_TO_WORK_WITH_BROWSER } from '@shared/redux/actions/home-actions';
import { allowToWorkWithBrowser } from '@shared/redux/sagas/home-saga';

function* watchAllowToWorkWithBrowser() {
  yield takeLatest(ALLOW_TO_WORK_WITH_BROWSER, allowToWorkWithBrowser);
}

export default function* watchHomeActions() {
  yield all([watchAllowToWorkWithBrowser()]);
}
