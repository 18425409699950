import { RequestState } from '@shared/constants/request';
import * as SettingsActions from '@shared/redux/actions/settings-actions';
import * as SftpActions from '@shared/redux/actions/sftp-actions';
import * as PhoneVerificationActions from '@shared/redux/actions/phoneVerification-actions';
import { initSettings } from './initState/initSettings';
import { applyExpirationState, deactivate, generated, initSftpState, SftpSettingsState } from '@shared/redux/state/sftpSettings';
import { DeviceConfigurationSettingsState } from '@shared/redux/state/deviceConfigurationSettings';
import { TwoFactorAuthenticationState } from '@shared/redux/state/twoFactorAutentication';
import { SecuritySettingsState } from '@shared/redux/state/security-settings';
import { SftpInfoDto } from '@shared/api/sftp';
import { EditableSettingsDto } from '@shared/api/core-api/settings-api';

const initialTokenState: SecuritySettingsState = {
  webApiToken: null,
  tokenExpirationState: null,
  selectedLifetimeDays: null,
};

const initialTwoFactorAuthenticationState = {
  isActivated: false,
  isPhoneVerificationModalOpened: false,
  isConfirmationMessageBoxOpened: false,
  requestState: RequestState.NOT_STARTED,
};

const initialDeviceConfigurationState = {
  confirmSettingsRequired: null,
};

export function securitySettings(state: SecuritySettingsState = initialTokenState, action: any): SecuritySettingsState {
  switch (action.type) {
    case SettingsActions.TOKENS_DAYS_LEFT_REQUEST_SUCCESS: {
      const { tokenExpirationState } = action.payload;
      return { ...state, tokenExpirationState };
    }
    case SettingsActions.CREATE_TOKEN_SUCCESS: {
      const { webApiToken, selectedLifetimeDays } = action.payload;
      return { ...state, webApiToken, selectedLifetimeDays, tokenExpirationState: null };
    }
    case SettingsActions.REVOKE_TOKEN_SUCCESS: {
      return {
        ...state,
        webApiToken: null,
        tokenExpirationState: null,
        selectedLifetimeDays: null,
      };
    }

    case SettingsActions.HIDE_TOKEN: {
      return {
        ...state,
        webApiToken: null,
      };
    }
  }
  return state;
}

export function sftpSettings(state: SftpSettingsState = initSftpState(), action: any) {
  switch (action.type) {
    case SftpActions.SFTP_PASS_DAYS_LEFT_REQUEST_SUCCESS: {
      const { sftpPasswordExpirationState } = action.payload;
      return applyExpirationState(state, sftpPasswordExpirationState);
    }
    case SftpActions.SFTP_GENERATION_PASSWORD_REQUEST_SUCCESS: {
      const { sftpPassword, selectedLifetimeDays } = action.payload;
      return generated(state, sftpPassword, selectedLifetimeDays);
    }
    case SftpActions.SFTP_DEACTIVATION_REQUEST_SUCCESS: {
      return deactivate(state);
    }
    case SftpActions.SFTP_CONNECTION_SETTINGS_REQUEST_SUCCESS: {
      const { port, host } = action.payload.sftpConnectionSettings as SftpInfoDto;
      return { ...state, serverInfo: { sftpPort: port, sftpHost: host } };
    }
  }
  return state;
}

export function deviceConfigurationSetting(
  state: DeviceConfigurationSettingsState = initialDeviceConfigurationState,
  action: any,
): DeviceConfigurationSettingsState {
  switch (action.type) {
    case SettingsActions.SETTINGS_REQUEST_SUCCESS: {
      const payload: EditableSettingsDto = action.payload;
      const { isDeviceSettingsConfirmationRequiresPassword } = payload;
      return {
        ...state,
        confirmSettingsRequired: isDeviceSettingsConfirmationRequiresPassword,
      };
    }
    case SettingsActions.ACTIVATE_CONFIRM_SETTINGS_REQUIRED_SUCCESS: {
      return {
        ...state,
        confirmSettingsRequired: true,
      };
    }
    case SettingsActions.ACTIVATE_CONFIRM_SETTINGS_REQUIRED_FAILED: {
      return {
        ...state,
        confirmSettingsRequired: false,
      };
    }
    case SettingsActions.DEACTIVATE_CONFIRM_SETTINGS_REQUIRED_SUCCESS: {
      return {
        ...state,
        confirmSettingsRequired: false,
      };
    }
    case SettingsActions.DEACTIVATE_CONFIRM_SETTINGS_REQUIRED_FAILED: {
      return {
        ...state,
        confirmSettingsRequired: true,
      };
    }
  }
  return state;
}

export function twoFactorAuthenticationSetting(
  state: TwoFactorAuthenticationState = initialTwoFactorAuthenticationState,
  action: any,
): TwoFactorAuthenticationState {
  switch (action.type) {
    case SettingsActions.SETTINGS_REQUEST: {
      return {
        ...state,
        requestState: RequestState.PENDING,
      };
    }

    case SettingsActions.SETTINGS_REQUEST_SUCCESS: {
      const payload: EditableSettingsDto = action.payload;
      const { isTwoFactorAuthenticationEnabled } = payload;

      return {
        ...state,
        isActivated: isTwoFactorAuthenticationEnabled,
        requestState: RequestState.SUCCESS,
      };
    }

    case SettingsActions.SETTINGS_REQUEST_FAILURE: {
      return {
        ...state,
        requestState: RequestState.FAILED,
      };
    }

    case SettingsActions.TWO_FACTOR_AUTHENTICATION_ACTIVATE_SUCCESS: {
      return {
        ...state,
        isActivated: true,
        isPhoneVerificationModalOpened: false,
        isConfirmationMessageBoxOpened: false,
      };
    }

    case SettingsActions.TWO_FACTOR_AUTHENTICATION_DEACTIVATE_SUCCESS: {
      return {
        ...state,
        isActivated: false,
        isPhoneVerificationModalOpened: false,
        isConfirmationMessageBoxOpened: false,
      };
    }

    case SettingsActions.TWO_FACTOR_AUTHENTICATION_CONFIRMATION_MODAL_OPEN: {
      return {
        ...state,
        isConfirmationMessageBoxOpened: true,
      };
    }

    case SettingsActions.TWO_FACTOR_AUTHENTICATION_CONFIRMATION_MODAL_CLOSE: {
      return {
        ...state,
        isConfirmationMessageBoxOpened: false,
      };
    }

    case PhoneVerificationActions.verifyCode.SUCCESS: {
      return {
        ...state,
        isPhoneVerificationModalOpened: false,
        isConfirmationMessageBoxOpened: true,
      };
    }

    case PhoneVerificationActions.OPEN_PHONE_VERIFICATION_MODAL: {
      return {
        ...state,
        isPhoneVerificationModalOpened: true,
      };
    }

    case PhoneVerificationActions.CLOSE_PHONE_VERIFICATION_MODAL: {
      return {
        ...state,
        isPhoneVerificationModalOpened: false,
      };
    }

    default:
      return state;
  }
}

export default function settings(state = initSettings, action: any): EditableSettingsDto {
  switch (action.type) {
    case SettingsActions.SETTINGS_REQUEST_SUCCESS:
      const payload: EditableSettingsDto = action.payload;
      return payload;
    case SettingsActions.TOKEN_REVOKE:
      return {
        ...state,
      };
    default:
      return state;
  }
}
