import { combineReducers, Reducer } from 'redux';

import * as PatientsAdminListFiltersActions from '@shared/redux/actions/patientActions/patients-admin-list-filters-actions';
import {
  PATIENTS_ADMIN_LIST_SET_SORTING,
  SetSorting,
} from '@shared/redux/actions/patientActions/patients_admin-list_sorting_actions';
import {
  PATIENTS_ADMIN_LIST_SET_SEARCH_QUERY,
  SetSearchQuery,
} from '@shared/redux/actions/patientActions/patients-admin-list-search-actions';
import { PatientsAdminListFiltersAction } from '@shared/redux/actions/patientActions/patients-admin-list-filters-actions';
import { AdminListState, FiltersState, SearchQueryState, SortState } from '@shared/redux/state/patients';
import { initPatientsRootState } from '@shared/redux/reducers/initState/initPatientsRootState';
import { AllActions } from '@shared/redux/actions';
import * as PatientActionTypes from '@shared/redux/actions/patientActions/patient-actions';
import { setDefaultValueForFiltersByKey } from './common';
import { SharingStatusFilters } from '@shared/constants/filters';
import { SortDirections } from '@shared/constants/SortDirections';

function filters(
  state: FiltersState = initPatientsRootState.adminList.filters,
  action: PatientsAdminListFiltersAction,
): FiltersState {
  switch (action.type) {
    case PatientsAdminListFiltersActions.PATIENTS_ADMIN_LIST_SET_FILTER:
      return {
        ...state,
        [action.payload.filterKey]: action.payload.filterValue,
      };
    case PatientsAdminListFiltersActions.PATIENTS_ADMIN_LIST_CHANGE_SHARING_STATUS:
      return {
        ...state,
        sharedByMeToTenant: '',
        sharedToMeFromTenant: '',
        sharingStatusFilter: action.payload.filterValue,
      };
    case PatientsAdminListFiltersActions.PATIENTS_ADMIN_LIST_CHANGE_SHARED_TO_ME:
      return {
        ...state,
        sharedByMeToTenant: '',
        sharingStatusFilter: SharingStatusFilters.Empty,
        sharedToMeFromTenant: action.payload.filterValue,
      };
    case PatientsAdminListFiltersActions.PATIENTS_ADMIN_LIST_SHARE_TO_TENANT:
      return {
        ...state,
        sharedToMeFromTenant: '',
        sharingStatusFilter: SharingStatusFilters.Empty,
        sharedByMeToTenant: action.payload.filterValue,
      };
    case PatientsAdminListFiltersActions.PATIENTS_ADMIN_LIST_SELECT_ALL:
      return {
        ...state,
        [action.payload.filterKey]: action.payload.filterValue,
      };
    case PatientsAdminListFiltersActions.PATIENTS_ADMIN_LIST_SELECT_NONE:
      return {
        ...state,
        [action.payload.filterKey]: [],
      };
    case PatientsAdminListFiltersActions.PATIENTS_ADMIN_LIST_SET_FILTERS_STATE:
      return {
        ...state,
        ...action.payload,
      };
    case PatientsAdminListFiltersActions.PATIENTS_ADMIN_LIST_REMOVE_FILTER:
      return setDefaultValueForFiltersByKey(action.payload.filterKey, state);
    case PatientsAdminListFiltersActions.PATIENTS_ADMIN_LIST_CLEAR_FILTERS:
      return initPatientsRootState.filters;
    case PatientsAdminListFiltersActions.PATIENTS_ADMIN_LIST_CHANGE_USERS_IN_CHARGE:
      const filterValue = action.payload.filterValue;
      const valueToSet = state.usersInCharge.includes(filterValue)
        ? state.usersInCharge.filter((user) => user !== filterValue)
        : [...state.usersInCharge, filterValue];

      return {
        ...state,
        usersInCharge: valueToSet,
      };
  }
  return state;
}

function sorting(state: SortState = initPatientsRootState.adminList.sort, action: SetSorting): SortState {
  const { type, payload } = action;

  if (type === PATIENTS_ADMIN_LIST_SET_SORTING) {
    if (state.field === payload.field) {
      return {
        direction: state.direction === SortDirections.Descending ? SortDirections.Ascending : SortDirections.Descending,
        field: state.field,
      };
    } else {
      return {
        direction: !payload.defaultSortDirection ? SortDirections.Descending : payload.defaultSortDirection,
        field: payload.field,
      };
    }
  }
  return state;
}

function searchQuery(
  state: SearchQueryState = initPatientsRootState.adminList.searchQuery,
  action: SetSearchQuery,
): SearchQueryState {
  if (action.type === PATIENTS_ADMIN_LIST_SET_SEARCH_QUERY) {
    return action.payload.searchQuery;
  }
  return state;
}

function scheduledPatientsExpanded_adminList(
  state: boolean = initPatientsRootState.adminList.scheduledPatientsExpanded,
  action: AllActions,
): boolean {
  switch (action.type) {
    case PatientActionTypes.TOGGLE_SCHEDULED_PATIENTS: {
      if (action.payload.activeTab === null) {
        return action.payload.expanded;
      }
      return state;
    }

    default:
      return state;
  }
}

export const adminList: Reducer<AdminListState> = combineReducers({
  scheduledPatientsExpanded: scheduledPatientsExpanded_adminList,
  filters,
  sort: sorting,
  searchQuery,
});
