import { Dispatch } from 'redux';
import { takeEvery, all, race, take, put, call, takeLatest } from 'redux-saga/effects';

import { ActionWithPayload } from '@shared/redux/actions/typings';

const PROMISE = '@@createFormAction/PROMISE';

type PromiseAction = ActionWithPayload<
  {
    request: ActionWithPayload<any, any>;
    defer: {
      resolve: (value: any) => void;
      reject: (reason?: any) => void;
    };
    types: [successType: string, failureType: string];
  },
  typeof PROMISE
>;

export const createFormAction =
  <T extends string>(name: T) =>
  <TRequestPayload = any, TSuccessPayload = void, TFailurePayload = unknown>() => {
    const requestType = (name + '_REQUEST') as `${T}_REQUEST`;
    const successType = (name + '_SUCCESS') as `${T}_SUCCESS`;
    const failureType = (name + '_FAILURE') as `${T}_FAILURE`;

    function* watch(sagaAction: (payload: ActionWithPayload<TRequestPayload, `${T}_REQUEST`>) => any) {
      yield takeLatest(requestType, sagaAction);
    }

    const actionMethods = {
      REQUEST: requestType,
      SUCCESS: successType,
      FAILURE: failureType,

      watch,
      request: (payload: TRequestPayload) =>
        ({
          type: requestType,
          payload,
        } as const),
      success: (payload: TSuccessPayload) =>
        ({
          type: successType,
          payload,
        } as const),
      failure: (payload?: TFailurePayload) => ({
        type: failureType,
        payload,
      }),
    };

    const handleSubmit = (data: TRequestPayload, dispatch: Dispatch<PromiseAction>) => {
      return new Promise<void>((resolve, reject) => {
        dispatch({
          type: PROMISE,
          payload: {
            request: actionMethods.request(data),
            defer: { resolve, reject },
            types: [actionMethods.SUCCESS, actionMethods.FAILURE],
          },
        });
      });
    };

    return Object.assign(handleSubmit, actionMethods);
  };

function* handlePromiseSaga({ payload }: PromiseAction): any {
  const { request, defer, types } = payload;
  const { resolve, reject } = defer;
  const [SUCCESS, FAIL] = types;

  const [winner] = yield all([
    race({
      success: take(SUCCESS),
      fail: take(FAIL),
    }),
    put(request),
  ]);

  if (winner.success) {
    yield call(resolve, winner.success?.payload ?? winner.success);
  } else {
    yield call(reject, winner?.fail?.payload ?? winner.fail);
  }
}

export function* formActionSaga() {
  yield takeEvery(PROMISE, handlePromiseSaga);
}

export type CreateFormActions<T extends { request: (payload: any) => any }> = T extends {
  request: (payload: any) => infer RequestPayload;
  success: (payload: any) => infer SuccessPayload;
  failure: (payload: any) => infer FailurePayload;
}
  ? RequestPayload | SuccessPayload | FailurePayload
  : never;
