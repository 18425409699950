import { Action } from 'redux';

import { ReportDates, ReportType } from '@shared/api/statistics-gateway-api/report-api';
import { DownloadActionPayload, GenerateActionPayload } from '@shared/redux/sagas/report-saga';
import { ReportDateRange } from '@shared/types/ReportTypes';
import { createFormAction } from '@shared/util/createFormAction';

export const GENERATE_REPORT_POPUP_OPEN = 'GENERATE_REPORT_POPUP_OPEN';
export const GENERATE_REPORT_POPUP_CLOSE = 'GENERATE_REPORT_POPUP_CLOSE';

export const GENERATE_REPORT_POPUP_FROM_NIV_VIEW = 'GENERATE_REPORT_POPUP_FROM_NIV_VIEW';
export const GENERATE_REPORT_POPUP_FROM_PATIENT_DETAILS = 'GENERATE_REPORT_POPUP_FROM_PATIENT_DETAILS';
export const GENERATE_REPORT_POPUP_FROM_CONTEXT = 'GENERATE_REPORT_POPUP_FROM_CONTEXT';
export const GENERATE_REPORT_POPUP_FAILURE = 'GENERATE_REPORT_POPUP_FAILURE';
export const UPDATE_RANGE_SELECTION = 'UPDATE_RANGE_SELECTION';
export const UPDATE_REPORT_TYPE = 'UPDATE_REPORT_TYPE';

export const UPDATE_FOCUS = 'UPDATE_FOCUS';

export const GENERATE_REPORT = createFormAction('GENERATE_REPORT')<GenerateActionPayload, DownloadActionPayload>();
export const REPORT_VALID_DATES = createFormAction('REPORT_VALID_DATES')<
  {
    patientPrismaCloudId: string;
    fullName: string | null;
  },
  ReportDates | null
>();

export const DOWNLOAD_REPORT = createFormAction('DOWNLOAD_REPORT')<void, { id: string }>();

export const updateReportType = (reportType: ReportType) => ({
  type: UPDATE_REPORT_TYPE as typeof UPDATE_REPORT_TYPE,
  payload: {
    reportType,
  },
});

export const updateRangeSelection = (selectedRange: ReportDateRange) => ({
  type: UPDATE_RANGE_SELECTION as typeof UPDATE_RANGE_SELECTION,
  payload: {
    selectedRange,
  },
});

export const generateReportPopupOpen = () => ({
  type: GENERATE_REPORT_POPUP_OPEN as typeof GENERATE_REPORT_POPUP_OPEN,
});

export const generateReportPopupOpenFromContext = (patientPrismaCloudId: string, fullName: string) => ({
  type: GENERATE_REPORT_POPUP_FROM_CONTEXT as typeof GENERATE_REPORT_POPUP_FROM_CONTEXT,
  payload: {
    patientPrismaCloudId,
    fullName,
  },
});

export const generateReportPopupOpenFromPatientDetails = (
  patientPrismaCloudId: string,
  fullName: string,
  statisticsPeriod: ReportDates | null,
) =>
  ({
    type: GENERATE_REPORT_POPUP_FROM_PATIENT_DETAILS,
    payload: {
      patientPrismaCloudId,
      fullName,
      statisticsPeriod,
    },
  } as const);

export const generateReportPopupFailure = (e: any) => ({
  type: GENERATE_REPORT_POPUP_FAILURE,
  error: e,
});

export type ReportValidDateRequestAction = ReturnType<typeof REPORT_VALID_DATES.request>;
export type ReportValidDateSuccessAction = ReturnType<typeof REPORT_VALID_DATES.success>;
export type ReportValidDateFailureAction = ReturnType<typeof REPORT_VALID_DATES.failure>;

export type GenerateReportRequestAction = ReturnType<typeof GENERATE_REPORT.request>;

export type GenerateReportPopupOpenAction = ReturnType<typeof generateReportPopupOpen>;
export type GenerateReportPopupCloseAction = Action<typeof GENERATE_REPORT_POPUP_CLOSE>;
export type GenerateReportOpenContextAction = ReturnType<typeof generateReportPopupOpenFromContext>;
export type GenerateReportPopupOpenFromPatientDetails = ReturnType<typeof generateReportPopupOpenFromPatientDetails>;

export type UpdateRangeSelectionAction = ReturnType<typeof updateRangeSelection>;
export type UpdateReportTypeAction = ReturnType<typeof updateReportType>;

export type ReportActions =
  | ReportValidDateRequestAction
  | ReportValidDateSuccessAction
  | ReportValidDateFailureAction
  | GenerateReportRequestAction
  | GenerateReportPopupOpenAction
  | GenerateReportPopupCloseAction
  | GenerateReportOpenContextAction
  | GenerateReportPopupOpenFromPatientDetails
  | UpdateRangeSelectionAction
  | UpdateReportTypeAction;
