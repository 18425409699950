import axios, { AxiosRequestConfig } from 'axios';
import pkceChallenge from 'pkce-challenge';

import { PATH_TO_AUTH_API } from '@shared/constants';
import { getTenant } from '@shared/util/tenant';
import { setAuthenticationState } from '@shared/util/localStorageAccessor';

export interface AuthData {
  accessToken: string;
  refreshToken: string;
}

interface TokenResponse {
  access_token: string;
  refresh_token: string;
}

const defaultOpts: AxiosRequestConfig = {
  baseURL: PATH_TO_AUTH_API,
};

/**
 * Prepares data to send in application/x-www-form-urlencoded format.
 * See https://github.com/axios/axios#using-applicationx-www-form-urlencoded-format
 * @param payload the login data.
 */
const getAuthForm = (payload: object) => {
  const tenant = getTenant();
  if (!tenant) {
    throw Error('Cannot prepare data. Tenant was null or undefined.');
  }

  const obj = {
    ...payload,
    grant_type: 'password',
    client_id: 'client',
    tenant,
  };
  return new URLSearchParams(obj);
};

function getRedirectUri() {
  const baseUrl = window.location.origin;
  return new URL('', baseUrl).toString();
}

/**
 * Axios API to authenticate the user.
 */
export const TokenApi = {
  async externalLogin(provider: string) {
    const url = new URL(`${defaultOpts.baseURL}/connect/authorize`);
    const currentTenant = getTenant();
    if (currentTenant) {
      // Here we implement Authorization Code Flow with Proof Key for Code Exchange (PKCE)
      // See: https://auth0.com/docs/get-started/authentication-and-authorization-flow/authorization-code-flow-with-pkce
      const state = (await pkceChallenge(43)).code_verifier;
      const challenge = await pkceChallenge();
      setAuthenticationState(state, challenge.code_verifier);
      url.searchParams.append('client_id', 'client');
      url.searchParams.append('redirect_uri', getRedirectUri());
      url.searchParams.append('response_type', 'code');
      url.searchParams.append('scope', 'offline_access');
      url.searchParams.append('state', state);
      url.searchParams.append('code_challenge', challenge.code_challenge);
      url.searchParams.append('code_challenge_method', 'S256');
      url.searchParams.append('response_mode', 'query');
      url.searchParams.append('identity_provider', provider);
      url.searchParams.append('tenant', currentTenant);
      window.location.href = url.toString();
    }
  },

  async getAccessToken(code: string, codeVerifier: string, options?: AxiosRequestConfig) {
    const formData = {
      grant_type: 'authorization_code',
      redirect_uri: getRedirectUri(),
      code: code,
      code_verifier: codeVerifier ?? '',
      client_id: 'client',
    };
    const form = new URLSearchParams(formData);
    options = { withCredentials: true, ...defaultOpts, ...options };
    const response = await axios.post<TokenResponse>('/connect/token', form, options);
    return {
      accessToken: response.data.access_token,
      refreshToken: response.data.refresh_token,
    };
  },

  /**
   * Gets the JWT access token from the Auth service.
   * @param userName The user email.
   * @param password The password.
   * @param options Overrides HTTP request options.
   */
  async token(userName: string, password: string, options?: AxiosRequestConfig): Promise<AuthData> {
    const form = getAuthForm({ username: userName, password });
    options = { withCredentials: true, ...defaultOpts, ...options };
    const response = await axios.post<TokenResponse>('/connect/token', form, options);
    return {
      accessToken: response.data.access_token,
      refreshToken: response.data.refresh_token,
    };
  },

  /**
   * Gets the JWT access token from the Auth service in a two-factor scenario.
   * @param userName The user email.
   * @param password The password.
   * @param verificationCode The second factor verification code.
   * @param trustedBrowser A boolean value indicating whether the current browser is trusted
   *      and should request the second factor less often.
   * @param options Overrides HTTP request options.
   */
  async tokenWithVerificationCode(
    userName: string,
    password: string,
    verificationCode: string,
    trustedBrowser: boolean,
    options?: AxiosRequestConfig,
  ): Promise<AuthData> {
    // trustedBrowser comes from a redux-form and is sometimes undefined instead of false.
    trustedBrowser = !!trustedBrowser;
    const form = getAuthForm({ username: userName, password, verificationCode, trustedBrowser });
    options = { withCredentials: true, ...defaultOpts, ...options };
    const response = await axios.post<TokenResponse>('/connect/token', form, options);
    return {
      accessToken: response.data.access_token,
      refreshToken: response.data.refresh_token,
    };
  },

  /**
   * Gets from the Auth service a new JWT access token using a previously received refresh token.
   * @param refreshToken The refresh token received on login or previous refresh.
   * @param options Overrides HTTP request options.
   */
  async refreshAccessToken(refreshToken: string, options?: AxiosRequestConfig): Promise<AuthData> {
    const tenant = getTenant();
    if (!tenant) {
      throw Error('Cannot refresh access token. Tenant was null or undefined.');
    }

    const form = new URLSearchParams({
      grant_type: 'refresh_token',
      refresh_token: refreshToken,
      client_id: 'client',
      tenant,
    });
    options = { withCredentials: true, ...defaultOpts, ...options };
    const response = await axios.post<TokenResponse>('/connect/token', form, options);
    return {
      accessToken: response.data.access_token,
      refreshToken: response.data.refresh_token,
    };
  },
};
