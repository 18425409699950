import { HttpStatusCodes } from '@shared/constants/httpStatusCodes';
import { dropUser } from '@shared/util/user';
import { ProblemDetailsConverter } from '@shared/util/problemDetailsConverter';

export const axiosErrorHandler = (e: any): void => {
  const error = ProblemDetailsConverter.convertApiExceptionToError(e);
  if (e?.response?.status === HttpStatusCodes.Unauthorized) {
    // just drop user, we already got 401 from server. Then throw error, because in normal process it
    // should not happen and we will write it to console or/and sentry.
    dropUser();
  }

  // Do not create a new exception object, this breaks `Axios.isCancel(e)`.
  // Make the exception look like RequestFailedException, so
  // that in try/catch handlers we always have the `error` property.
  e.error = error;
  throw e;
};
