import { call, put } from 'redux-saga/effects';

import { SettingsApi } from '@shared/api/core-api';
import * as SettingActionTypes from '@shared/redux/actions/settings-actions';
import * as SftpActionTypes from '@shared/redux/actions/sftp-actions';
import { ActionWithPayload } from '@shared/redux/actions/typings';
import { ExternalApiTokenApi } from '@shared/api/auth-api';
import { TokenLifeTime, TokenLifetimeDto } from '@shared/api/auth-api/reference-token-api';
import { SftpApi, PasswordUpdatedDto, SftpInfoDto, PasswordExpirationDto } from '@shared/api/sftp';
import { EditableSettingsDto } from '@shared/api/core-api/settings-api';
import { handleSagaError } from '@shared/util/handleSagaError';

export function* fetchCreateToken(action: ActionWithPayload<any>) {
  try {
    const selectedLifetime: TokenLifeTime = action.payload.selectedLifetime;
    const token: string = yield call(ExternalApiTokenApi.createToken, selectedLifetime);

    yield put({
      type: SettingActionTypes.CREATE_TOKEN_SUCCESS,
      payload: { webApiToken: token, selectedLifetimeDays: selectedLifetime },
    });
  } catch (e) {
    yield handleSagaError({
      type: SettingActionTypes.CREATE_TOKEN_FAILURE,
      error: e,
    });
  }
}

export function* fetchRevokeToken() {
  try {
    yield call(ExternalApiTokenApi.revokeToken);
    yield put({ type: SettingActionTypes.REVOKE_TOKEN_SUCCESS });
  } catch (e) {
    yield handleSagaError({
      type: SettingActionTypes.REVOKE_TOKEN_FAILURE,
      error: e,
    });
  }
}

export function* fetchTokenExpirationState(action: ActionWithPayload<any>) {
  try {
    const tokenExpirationState: TokenLifetimeDto = yield call(ExternalApiTokenApi.getTokenInfo);
    yield put({
      type: SettingActionTypes.TOKENS_DAYS_LEFT_REQUEST_SUCCESS,
      payload: { tokenExpirationState },
    });
  } catch (e) {
    yield handleSagaError({
      type: SettingActionTypes.TOKENS_DAYS_LEFT_REQUEST_FAILED,
      error: e,
    });
  }
}

export function* fetchSettings() {
  try {
    const settings: EditableSettingsDto = yield call(SettingsApi.getSettings);
    const action: ActionWithPayload<EditableSettingsDto> = {
      type: SettingActionTypes.SETTINGS_REQUEST_SUCCESS,
      payload: settings,
    };
    yield put(action);
  } catch (e) {
    yield handleSagaError({
      type: SettingActionTypes.SETTINGS_REQUEST_FAILURE,
      error: e,
    });
  }
}

export function* fetchGenerateSftpPassword(action: ActionWithPayload<{ selectedLifetime: number }>) {
  try {
    const { selectedLifetime } = action.payload;
    const passwordDto: PasswordUpdatedDto = yield call(SftpApi.generateSftpPassword, selectedLifetime);
    const password = passwordDto.sftpPassword;
    yield put({
      type: SftpActionTypes.SFTP_GENERATION_PASSWORD_REQUEST_SUCCESS,
      payload: { sftpPassword: password, selectedLifetimeDays: selectedLifetime },
    });
  } catch (e) {
    yield handleSagaError({
      type: SftpActionTypes.SFTP_GENERATION_PASSWORD_REQUEST_FAILED,
      error: e,
    });
  }
}

export function* fetchDeactivateSftp() {
  try {
    yield call(SftpApi.revokeSftpPassword);
    yield put({ type: SftpActionTypes.SFTP_DEACTIVATION_REQUEST_SUCCESS });
  } catch (e) {
    yield handleSagaError({
      type: SftpActionTypes.SFTP_DEACTIVATION_REQUEST_FAILED,
      error: e,
    });
  }
}

export function* fetchSftpPasswordExpirationState() {
  try {
    const sftpPasswordExpirationState: PasswordExpirationDto = yield call(SftpApi.getSftpPasswordDaysLeft);
    yield put({
      type: SftpActionTypes.SFTP_PASS_DAYS_LEFT_REQUEST_SUCCESS,
      payload: { sftpPasswordExpirationState },
    });
  } catch (e) {
    yield handleSagaError({
      type: SftpActionTypes.SFTP_PASS_DAYS_LEFT_REQUEST_FAILED,
      error: e,
    });
  }
}

export function* fetchSftpConnectionSettings() {
  try {
    const sftpConnectionSettings: SftpInfoDto = yield call(SftpApi.getSftpInfo);
    yield put({
      type: SftpActionTypes.SFTP_CONNECTION_SETTINGS_REQUEST_SUCCESS,
      payload: { sftpConnectionSettings },
    });
  } catch (e) {
    yield handleSagaError({
      type: SftpActionTypes.SFTP_CONNECTION_SETTINGS_REQUEST_FAILED,
      error: e,
    });
  }
}

export function* fetchActivateTwoFactorAuthentication() {
  try {
    yield call(SettingsApi.activateTwoFactorAuthentication);
    yield put({ type: SettingActionTypes.TWO_FACTOR_AUTHENTICATION_ACTIVATE_SUCCESS });
  } catch (e) {
    yield handleSagaError({
      type: SettingActionTypes.TWO_FACTOR_AUTHENTICATION_ACTIVATE_FAILED,
      error: e,
    });
  }
}

export function* fetchDeactivateTwoFactorAuthentication() {
  try {
    yield call(SettingsApi.deactivateTwoFactorAuthentication);
    yield put({ type: SettingActionTypes.TWO_FACTOR_AUTHENTICATION_DEACTIVATE_SUCCESS });
  } catch (e) {
    yield handleSagaError({
      type: SettingActionTypes.TWO_FACTOR_AUTHENTICATION_DEACTIVATE_FAILED,
      error: e,
    });
  }
}

export function* fetchActivateConfirmSettingsRequired() {
  try {
    yield call(SettingsApi.activateConfirmSettingsRequired);
    yield put({ type: SettingActionTypes.ACTIVATE_CONFIRM_SETTINGS_REQUIRED_SUCCESS });
  } catch (e) {
    yield handleSagaError({
      type: SettingActionTypes.ACTIVATE_CONFIRM_SETTINGS_REQUIRED_FAILED,
      error: e,
    });
  }
}

export function* fetchDeactivateConfirmSettingsRequired() {
  try {
    yield call(SettingsApi.deactivateConfirmSettingsRequired);
    yield put({ type: SettingActionTypes.DEACTIVATE_CONFIRM_SETTINGS_REQUIRED_SUCCESS });
  } catch (e) {
    yield handleSagaError({
      type: SettingActionTypes.DEACTIVATE_CONFIRM_SETTINGS_REQUIRED_FAILED,
      error: e,
    });
  }
}
