import { SagaIterator } from '@redux-saga/types';
import { call, delay, put, race, select, take } from 'redux-saga/effects';

import { hideNotification, showNotification, showRevokeNotification } from '@shared/redux/actions/notification-actions';
import {
  HideNotificationPayload,
  ShowNotificationWithTimeoutPayload,
  ShowRevokeNotificationPayload,
} from '@shared/redux/actions/payloadTypes/notificationPayloads';
import { ActionWithPayload } from '@shared/redux/actions/typings';
import { NotificationItemState } from '@shared/redux/state/notification';

function* delayAndHide(
  hideDelay: number | undefined,
  hideAction: ActionWithPayload<HideNotificationPayload>,
): SagaIterator {
  yield delay(hideDelay ?? 5000);
  yield put(hideAction);
}

function* setDelay(hideNotificationPayload: HideNotificationPayload, hideDelay: number | undefined): SagaIterator {
  const hideAction = hideNotification(hideNotificationPayload);

  // Race serves to cancel the scheduled automatic hiding when the notification
  // is closed manually.
  // It's done not to interfere with future instances of the notification with this id
  // shown by manually closing the current notification and re-entering this saga.
  yield race({
    autoHide: call(delayAndHide, hideDelay, hideAction),
    hideManually: take((a: any): any => a.type === hideAction.type && a.payload === hideAction.payload),
  });
}

/**
 * Processes SHOW_NOTIFICATION_WITH_TIMEOUT actions and dispatches actions to show and automatically
 * hide notifications after a timeout. Already shown notifications with the same id are ignored.
 *
 * @export
 * @param {Object} action
 * @param {string} action.id - The notification id.
 * @param {string} action.resourceKey - The text resource key that is converted
 *     to the displayed message.
 * @returns The created saga.
 */
export function* processNotificationWithTimeout(
  action: ActionWithPayload<ShowNotificationWithTimeoutPayload>,
): SagaIterator {
  const { id, resourceKey, kind, hideDelay, localizationParams } = action.payload;
  const showNotificationPayload = {
    id,
    resourceKey,
    kind,
    localizationParams,
  };

  const hideNotificationPayload = {
    id,
  };

  if (!id) {
    console.error('Suppressing notification due to invalid id.');
    return;
  }
  // showNotification (called below) sets state.notification[id].
  const isDisplayed: NotificationItemState = yield select((state) => state.notification && state.notification[id]);
  if (isDisplayed) {
    console.debug(`Notification already shown, suppressing: '${id}'.`);
    return;
  }

  yield put(showNotification(showNotificationPayload));

  yield call(setDelay, hideNotificationPayload, hideDelay);
}

/**
 * Processes SHOW_REVOKE_NOTIFICATION_WITH_TIMEOUT actions and dispatches actions to show and automatically
 * hide notifications after a timeout. Already shown notifications with the same id are ignored.
 *
 * @export
 * @param {Object} action
 * @param {string} action.id - The notification id.
 * @param {string} action.resourceKey - The text resource key that is converted
 *     to the displayed message.
 * @returns The created saga.
 */
export function* processRevokeNotificationWithTimeout(
  action: ActionWithPayload<ShowRevokeNotificationPayload>,
): SagaIterator {
  const { id, resourceKey, kind, hideDelay, onRevokeButtonClick } = action.payload;
  const showRevokeNotificationPayload = {
    id,
    resourceKey,
    kind,
    onRevokeButtonClick,
  };

  const hideNotificationPayload = {
    id,
  };

  if (!id) {
    console.error('Suppressing notification due to invalid id.');
    return;
  }
  // showRevokeNotification (called below) sets state.notification[id].
  const isDisplayed: NotificationItemState = yield select((state) => state.notification && state.notification[id]);
  if (isDisplayed) {
    console.debug(`Notification already shown, suppressing: '${id}'.`);
    return;
  }

  yield put(showRevokeNotification(showRevokeNotificationPayload));

  yield call(setDelay, hideNotificationPayload, hideDelay);
}
