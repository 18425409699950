import { takeLatest, all } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';

import * as UserActionTypes from '@shared/redux/actions/users-actions';
import * as UsersSaga from '@shared/redux/sagas/users-saga';
import { ACCOUNT_DETAILS_REQUEST } from '@shared/redux/actions/users-actions';

function* watchFetchUserInCharge(): SagaIterator {
  yield takeLatest(UserActionTypes.FETCH_ALL_USER_IN_CHARGE_REQUEST, UsersSaga.fetchAllUsersInCharge);
}

function* watchFetchPageOfUsers(): SagaIterator {
  yield takeLatest(UserActionTypes.FETCH_PAGE_OF_USERS_REQUEST, UsersSaga.fetchPageOfUsers);
}

function* watchFetchUserById(): SagaIterator {
  yield takeLatest(UserActionTypes.FETCH_USER_BY_ID_REQUEST, UsersSaga.fetchUserById);
}

function* watchFetchPicRequest(): SagaIterator {
  yield takeLatest(UserActionTypes.USER_PIC_REQUEST, UsersSaga.fetchPic);
}

function* watchFetchCreatePicRequest(): SagaIterator {
  yield takeLatest(UserActionTypes.USER_CREATE_PIC_REQUEST, UsersSaga.fetchCreatePic);
}

function* watchDownloadPicConfirmed(): SagaIterator {
  yield takeLatest(UserActionTypes.USER_DOWNLOAD_PIC_CONFIRM, UsersSaga.downloadPic);
}

function* watchDeleteUserConfirmed(): SagaIterator {
  yield takeLatest(UserActionTypes.USER_DELETE_CONFIRM, UsersSaga.fetchDeleteUser);
}

function* watchDeletePicConfirmed(): SagaIterator {
  yield takeLatest(UserActionTypes.USER_DELETE_PIC_REQUEST_CONFIRMED, UsersSaga.fetchDeletePic);
}

function* watchCreateUserRequest() {
  yield UserActionTypes.createUser.watch(UsersSaga.fetchCreateUser);
}

function* watchEditMyAccountRequest() {
  yield UserActionTypes.editMyAccount.watch(UsersSaga.fetchEditMyAccount);
}

function* watchEditUserRequest() {
  yield UserActionTypes.editUser.watch(UsersSaga.fetchEditUser);
}

function* watchAccountInfo() {
  yield takeLatest(ACCOUNT_DETAILS_REQUEST, UsersSaga.fetchAccountInfo);
}

export default function* watchUserActions() {
  yield all([
    watchFetchUserInCharge(),
    watchFetchPageOfUsers(),
    watchFetchUserById(),
    watchFetchPicRequest(),
    watchFetchCreatePicRequest(),
    watchDownloadPicConfirmed(),
    watchDeleteUserConfirmed(),
    watchDeletePicConfirmed(),
    watchCreateUserRequest(),
    watchEditMyAccountRequest(),
    watchEditUserRequest(),
    watchAccountInfo(),
  ]);
}
