import { IdentityApiFactory } from './identity-api';
import { AccountApiFactory } from './account-api';
import { ReferenceTokenApiFactory } from './reference-token-api';
import { PhoneVerificationApiFactory } from './phone-verification-api';
import { TrustedBrowsersApiFactory } from './trusted-browser-api';
import { PATH_TO_AUTH_API } from '@shared/constants';
import { BaseAPI } from '@shared/api/base-api';

const api = new BaseAPI(PATH_TO_AUTH_API);

export { TokenApi } from './token-api';
export type { AuthData } from './token-api';
export const IdentityApi = IdentityApiFactory();
export const AccountApi = AccountApiFactory();
export const ExternalApiTokenApi = ReferenceTokenApiFactory(api);
export const PhoneVerificationApi = PhoneVerificationApiFactory();
export const TrustedBrowsersApi = TrustedBrowsersApiFactory();
