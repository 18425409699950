import MatomoTracker from '@datapunt/matomo-tracker-js';
import { TrackEventParams, TrackPageViewParams } from '@datapunt/matomo-tracker-js/lib/types';

let tracker: MatomoTracker | null;

export function initMatomo(url: string, siteId: number) {
  tracker = new MatomoTracker({
    urlBase: url,
    siteId,
    heartBeat: {
      // optional, enabled by default
      active: false, // optional, default value: true
    },
    linkTracking: false, // optional, default value: true
    configurations: {
      // any valid matomo configuration
    },
  });

  const location = window.location;
  trackPageView({
    // Pages matching regex could contain sensitive information in URL and/or query parameters.
    // So log only origin (e.g. 'https://barcelona.prismacloud.today') for them
    href: location.pathname.match(/(\/forgotPassword|activate)/)
      ? location.origin
      : undefined /* if path is not sensitive, we pass `undefined`, so Matomo determines the href by itself */,
  });
}

export const trackEvent = (params: {
  action: 'Viewed' | 'Postponed';
  category: 'ExternalNotifications';
  name: string;
}) => {
  tracker?.trackEvent(params);
};

export function trackPageView(params?: TrackPageViewParams) {
  tracker?.trackPageView(params);
}
