import { AnyAction, combineReducers, Reducer } from 'redux';
import { match } from 'ts-pattern';

import * as OrganisationActionTypes from '@shared/redux/actions/organisation-actions';
import * as SettingsActions from '@shared/redux/actions/settings-actions';
import { RequestState } from '@shared/constants/request';
import { CurrentOrganisationState, FiltersState, OrganisationsRootState } from '@shared/redux/state/organisations';
import { initOrganisationsRootState } from './initState/initOrganisationRootState';

export function current(
  state: CurrentOrganisationState = initOrganisationsRootState.current,
  action: OrganisationActionTypes.OrganisationActions | SettingsActions.SettingsActions,
): CurrentOrganisationState {
  switch (action.type) {
    case OrganisationActionTypes.getCurrentOrganisation.REQUEST: {
      return {
        ...state,
        requestState: RequestState.PENDING,
      };
    }
    case OrganisationActionTypes.getCurrentOrganisation.FAILURE: {
      return {
        ...state,
        requestState: RequestState.FAILED,
      };
    }
    case OrganisationActionTypes.getCurrentOrganisation.SUCCESS: {
      return {
        ...state,
        ...action.payload,
        requestState: RequestState.SUCCESS,
      };
    }

    case SettingsActions.TWO_FACTOR_AUTHENTICATION_ACTIVATE_SUCCESS: {
      return {
        ...state,
        twoFactorEnabled: true,
      };
    }

    case SettingsActions.TWO_FACTOR_AUTHENTICATION_DEACTIVATE_SUCCESS: {
      return {
        ...state,
        twoFactorEnabled: false,
      };
    }

    default:
      return state;
  }
}

export const filters: Reducer<FiltersState, OrganisationActionTypes.OrganisationActions> = (
  state = initOrganisationsRootState.filters,
  action,
) =>
  match(action)
    .with({ type: OrganisationActionTypes.ORGANISATION_SET_FILTER }, (x) => ({
      ...state,
      [x.payload.filterKey]: x.payload.filterValue,
    }))
    .with({ type: OrganisationActionTypes.ORGANISATION_CHANGE_COUNTRY }, (x) => ({
      ...state,
      countries: state.countries.some((y) => y === x.payload.filterValue)
        ? state.countries.filter((y) => y !== x.payload.filterValue)
        : [...state.countries, x.payload.filterValue],
    }))
    .with({ type: OrganisationActionTypes.ORGANISATION_CLEAR_FILTERS }, () => ({
      ...initOrganisationsRootState.filters,
    }))
    .with({ type: OrganisationActionTypes.ORGANISATION_SELECT_ALL }, (x) => ({
      ...state,
      [x.payload.filterKey]: x.payload.filterValue,
    }))
    .with({ type: OrganisationActionTypes.ORGANISATION_SELECT_NONE }, (x) => ({
      ...state,
      [x.payload.filterKey]: [],
    }))
    .with({ type: OrganisationActionTypes.ORGANISATION_REMOVE_FILTER }, (x) =>
      getDefaultValueForKey(x.payload.filterKey, state),
    )
    .otherwise(() => state);

export const organisations: Reducer<OrganisationsRootState, AnyAction> = combineReducers({
  current,
  filters,
});

const getDefaultValueForKey = (filterKey: keyof FiltersState, state: FiltersState): FiltersState =>
  match(filterKey)
    .with('loginType', () => ({
      ...state,
      loginType: initOrganisationsRootState.filters.loginType,
    }))
    .with('countries', () => ({
      ...state,
      countries: initOrganisationsRootState.filters.countries,
    }))
    .exhaustive();
