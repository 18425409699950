import { takeLatest, all } from 'redux-saga/effects';

import * as TenantActionTypes from '@shared/redux/actions/tenant-actions';
import * as TenantSaga from '@shared/redux/sagas/tenant-saga';

function* watchTenantRequest() {
  yield takeLatest(TenantActionTypes.TENANT_VERIFY_REQUEST, TenantSaga.verifyTenant);
}

export default function* watchTenantActions() {
  yield all([watchTenantRequest()]);
}
