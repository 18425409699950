import React from 'react';
import { SvgIcon } from '@mui/material';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';

export const radioButtonIconClasses = {
  border: 'radioBorder',
  dot: 'radioDot',
  checked: 'radioChecked',
} as const;

type OwnProps = {
  className?: string;
  checked?: boolean;
};

const RadioButtonIconDot = styled((props: { className?: string; checked?: boolean }) => (
  <rect className={clsx(props.className, radioButtonIconClasses.dot)} x='7' y='7' width='10' height='10' rx='6' />
))(({ theme, checked }) => ({
  transform: 'scale(0)',
  transformOrigin: 'center  ',
  transition: theme.transitions.create(['transform', 'fill'], {
    easing: theme.transitions.easing.easeIn,
    duration: theme.transitions.duration.shortest,
  }),
  ...(checked && {
    transform: 'scale(1)',
    transition: theme.transitions.create(['transform', 'fill'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.shortest,
    }),
  }),
}));

export const RadioButtonIcon: React.FC<OwnProps> = (props: OwnProps) => (
  <SvgIcon className={clsx(props.className, props.checked && radioButtonIconClasses.checked)}>
    <rect className={radioButtonIconClasses.border} x='4' y='4' width='16' height='16' rx='8' />
    <RadioButtonIconDot checked={props.checked} />
  </SvgIcon>
);
