import dayjs from 'dayjs';

import { ReportDateRange } from '@shared/types/ReportTypes';

export const getReportNotificationId = (
  patientPrismaCloudId: string,
  dateRange: ReportDateRange,
  language: string,
): string => {
  const startDate = dayjs(dateRange.startDate);
  const endDate = dayjs(dateRange.endDate);
  return (
    `${patientPrismaCloudId}_` +
    `${startDate.isValid() ? startDate.toISOString() : ''}-` +
    `${endDate.isValid() ? endDate.toISOString() : ''}_` +
    `${language}`
  );
};
