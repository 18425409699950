export const LOCALIZATION_DICTIONARY_RECEIVE_REQUEST = 'LOCALIZATION_DICTIONARY_RECEIVE_REQUEST';
export const LOCALIZATION_DICTIONARY_RECEIVE_SUCCESS = 'LOCALIZATION_DICTIONARY_RECEIVE_SUCCESS';
export const LOCALIZATION_DICTIONARY_RECEIVE_FAILURE = 'LOCALIZATION_DICTIONARY_RECEIVE_FAILURE';
export const LOCALIZATION_LANGUAGE_CHANGED = 'LOCALIZATION_LANGUAGE_CHANGED';

export interface ISetLocalisationAction {
  type: string;
  culture: string;
  dayJSLocale: string;
  isCultureUserDefined: boolean;
}

export interface ILocalisationDictionaryReceivedSuccesfull {
  type: string;
}

export interface ILanguageChangedAction {
  type: string;
  payload: {
    culture: string;
  };
}

export function receivedSuccesfull(): ILocalisationDictionaryReceivedSuccesfull {
  return {
    type: LOCALIZATION_DICTIONARY_RECEIVE_SUCCESS,
  };
}

export function localizationLanguageChanged(culture: string): ILanguageChangedAction {
  return {
    type: LOCALIZATION_LANGUAGE_CHANGED,
    payload: { culture },
  };
}

export const receivedFailure = () => ({
  type: LOCALIZATION_DICTIONARY_RECEIVE_FAILURE,
});

export function requestLocalisation(
  culture: string,
  dayJSLocale: string,
  isCultureUserDefined: boolean,
): ISetLocalisationAction {
  return {
    type: LOCALIZATION_DICTIONARY_RECEIVE_REQUEST,
    culture,
    dayJSLocale,
    isCultureUserDefined,
  };
}
