import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

import { PATH_TO_SD_UPLOADER_API } from '@shared/constants';
import { postProcessAxiosError, prepareRequestAndFillFormData } from '@shared/api/base-api';

const defaultOpts: AxiosRequestConfig = {
  baseURL: PATH_TO_SD_UPLOADER_API,
};

export interface UploadAcceptedDto {
  deviceSerialNumber: string;
  removedDaysBelowLimit: boolean;
  removedDaysAboveLimit: boolean;
}

export const SdCardApi = {
  async getSupportedFileExtensions(options?: AxiosRequestConfig): Promise<string[]> {
    const response: AxiosResponse<string[]> = await axios.get('/api/SdCard/supportedFileExtensions', {
      ...defaultOpts,
      ...options,
    });
    return response.data;
  },
  async upload(file: any, options?: AxiosRequestConfig): Promise<UploadAcceptedDto> {
    const localVarAxiosArgs = prepareRequestAndFillFormData(file, options, '/api/SdCard');
    const baseURL = defaultOpts.baseURL;
    const axiosRequestArgs = {
      ...localVarAxiosArgs.options,
      url: baseURL + localVarAxiosArgs.url,
    };
    try {
      const result: AxiosResponse<UploadAcceptedDto> = await axios.request(axiosRequestArgs);
      return result.data;
    } catch (error) {
      return postProcessAxiosError(error);
    }
  },
};
