import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import React, { Suspense } from 'react';
import { Store } from 'redux';

import { theme } from '@app/theme';
import initConfiguration from '@app/store';
import { RootState } from '@shared/redux/state/root';
import { lazyRetry } from '@shared/util/retryHelper';
import { getTenant } from '@shared/util/tenant';
import { useStoredOrDefaultCulture } from '@shared/util/localisation-adapter';
import { BusyContainer, BusyIndicator } from '@controls/BusyIndicator/BusyIndicator';

const LandingPage = lazyRetry(() => import(/* webpackChunkName: "landing-page" */ '@pages/LandingPage'));
const RootProvider = lazyRetry(() => import(/* webpackChunkName: "root-provider" */ './RootProvider'));

const App: React.FC<{
  store: Store<RootState>;
  configuration: ReturnType<typeof initConfiguration>;
}> = (props) => {
  const tenant = getTenant();
  const { dayJSLocale } = useStoredOrDefaultCulture();
  return (
    <BusyContainer position='center' className={'app__busy-container'}>
      <Suspense fallback={<BusyIndicator className={'app__busy'} />}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={dayJSLocale}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            {!tenant ? <LandingPage /> : <RootProvider store={props.store} history={props.configuration.history} />}
          </ThemeProvider>
        </LocalizationProvider>
      </Suspense>
    </BusyContainer>
  );
};

export default App;
