export interface NotificationRootState {
  [index: string]: NotificationItemState;
}

export interface NotificationItemState {
  resourceKey: string;
  kind: NotificationKinds;
  localizationParams?: object;
  onRevokeButtonClick?: () => void;
}

export enum NotificationKinds {
  Success = 'success',
  Information = 'info',
  Warning = 'warning',
  Danger = 'danger',
  Loading = 'loading',
  Revoke = 'revoke',
}
