import { SubmissionError } from 'redux-form';
import { call, put, select } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';

import * as AuthActionTypes from '@shared/redux/actions/auth-actions';
import { ActionWithPayload } from '@shared/redux/actions/typings';
import { SetPasswordFormProps } from '@shared/types/setPasswordTypes';
import { RootState } from '@shared/redux/state/root';
import { handleErrorResponse } from '@shared/util/server-error-response-helper';
import { AccountApi, IdentityApi } from '@shared/api/auth-api';
import { handleReactFormSagaError, handleSagaError } from '@shared/util/handleSagaError';
import { ForgottenPasswordFormData } from '@shared/types/ForgottenPasswordFormData';

export interface ActivateUserPayload {
  userId: string;
  code: string;
  password: string;
  areTermsOfUseSigned: boolean;
}

export interface ResetPasswordPayload {
  email: string;
  token: string;
  password: string;
  areTermsOfUseSigned: boolean;
}

export function* fetchActivateUser(action: ActionWithPayload<ActivateUserPayload>) {
  try {
    const { userId, code, password, areTermsOfUseSigned } = action.payload;
    const response: AxiosResponse = yield call(AccountApi.confirm, { userId, code, password, areTermsOfUseSigned });
    yield put(AuthActionTypes.activateUser.success(response.data));
  } catch (e) {
    const error = handleErrorResponse(e);
    const formError = new SubmissionError<SetPasswordFormProps>({
      _error: error && error.password,
    });
    const failedAction = AuthActionTypes.activateUser.failure(formError);
    yield handleReactFormSagaError({
      error: e,
      formError,
      failedAction,
    });
  }
}

export function* fetchSendForgottenPasswordEmail(action: ActionWithPayload<ForgottenPasswordFormData>) {
  try {
    const { email } = action.payload;
    const organisationLanguage: string = yield select((state: RootState) => state.tenant.organisationLanguage);
    yield call(IdentityApi.forgotPassword, { email, languageAbbreviation: organisationLanguage });
    const successAction = AuthActionTypes.sendForgottenPasswordEmail.success();
    yield put(successAction);
  } catch (e) {
    const formError = new SubmissionError<ForgottenPasswordFormData>(handleErrorResponse(e));
    const failedAction = AuthActionTypes.sendForgottenPasswordEmail.failure(formError);

    if (formError.errors && (formError.errors.email != null || formError.errors._error != null)) {
      yield put(failedAction);
    } else {
      yield handleSagaError({
        ...failedAction,
        error: e,
      });
    }
  }
}

export function* fetchResetPasswordAfterPasswordForgotten(action: ActionWithPayload<ResetPasswordPayload>) {
  try {
    const { email, token, password, areTermsOfUseSigned } = action.payload;
    const organisationLanguage: string = yield select((state: RootState) => state.tenant.organisationLanguage);
    yield call(IdentityApi.resetPassword, {
      email,
      token,
      password,
      areTermsOfUseSigned,
      languageAbbreviation: organisationLanguage,
    });
    const successAction = AuthActionTypes.resetPassword.success();
    yield put(successAction);
  } catch (e) {
    const error = handleErrorResponse(e);
    const formError = new SubmissionError<SetPasswordFormProps>({
      _error: error && error.token,
    });
    const failedAction = AuthActionTypes.resetPassword.failure(formError);
    yield handleReactFormSagaError({
      error: e,
      formError,
      failedAction,
    });
  }
}
